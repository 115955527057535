import { graphql } from '@apollo/client/react/hoc';
import * as _ from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { branch, compose, setDisplayName, withHandlers, withProps } from 'recompose';
import { QUERY_PERMIT, UPDATE_VENUE_PERMIT } from '../PERMIT_QUERIES_AND_MUTATIONS';
import EditPermitPresenter from './EditPermitPresenter';
import { PageLoader } from '../../../components/Loading';
import withActionStates from '../../../hocs/withActionStates';
import withCurrentUser from '../../../hocs/withCurrentUser';

export default compose(
  setDisplayName('EditPermitContainerContainer'),
  withActionStates({ withAlerts: true }),
  withCurrentUser(),
  branch(
    ({ permitId }) => !permitId,
    () => () => <div>No permit id found!</div>
  ),
  graphql(QUERY_PERMIT, {
    skip: ({ permitId }) => !permitId,
    options: ({ permitId }) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        permitId,
      },
    }),
    props: ({ data: { permit, loading, error } }) => ({
      loading,
      error,
      permit,
    }),
  }),
  withProps(({ permit }) => ({
    timezone: _.get(permit, 'timeframe.timezone'),
    sublocationOptions: _.map(_.get(permit, 'sublocationOptions'), value => ({
      label: value,
      value,
    })),
    venueId: _.get(permit, 'approvedVenueId'),
  })),
  branch(
    ({ loading }) => loading,
    () => PageLoader
  ),
  branch(
    ({ error }) => error,
    () => () => <div>Error!</div>
  ),
  graphql(UPDATE_VENUE_PERMIT, { name: 'updateVenuePermitMutation' }),
  withHandlers({
    updateVenuePermit: props => async permit => {
      const {
        venueId,
        organizationId,
        setError,
        setSuccess,
        setUpdating,
        updateVenuePermitMutation,
      } = props;

      setUpdating(true);
      try {
        if (!permit || !permit._id) throw new Error('Missing permit id to remove');

        const {
          _id,
          note,
          referenceId,
          sublocations,
          centsDaily,
          timeframe: { startTime, endTime, date },
        } = permit;

        const diffOfMinutes = moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'minutes');
        if (diffOfMinutes <= 59) {
          throw new Error(
            'Start time must not be earlier than or equal to the end time, minimum 1 hour'
          );
        }

        const permitHours = +(diffOfMinutes / 60).toFixed(0);

        await updateVenuePermitMutation({
          variables: {
            input: {
              organizationId,
              approvedVenueId: venueId,
              permit: {
                _id,
                note,
                referenceId,
                centsDaily,
                centsHourly: +(centsDaily / permitHours).toFixed(0),
                sublocations: (sublocations || []).sort(),
                timeframe: {
                  startTime,
                  endTime,
                  date,
                },
              },
            },
          },
        });

        setSuccess('Permit updated');
      } catch (e) {
        setError(e);
      } finally {
        setUpdating(false);
      }
    },
  })
)(EditPermitPresenter);
