import * as _ from 'lodash-es';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import smile from '../../../images/icons/smile.svg';
import styles from './styles.module.scss';
import featureEnum from '../../../shared/venue-features-enum';
import getPerkIcon from '../../../utils/get-perk-icons';

const cx = classNames.bind(styles);

const LeaguePerks = ({ league, style, minify }) => {
  const perks = _.get(league, 'profile.perks') || _.get(league, 'details.perks');
  const features = _.orderBy(
    _.get(league, 'profile.features') || _.get(league, 'details.features')
  );

  if (!perks && _.isEmpty(features)) return <span />;

  return (
    <div className={cx('info-container')} style={style}>
      <img className={cx('icon')} src={smile} alt="smile" />
      <p className={cx('title')}>Perks</p>
      <div className="row mx-0">
        {_.map([...features, ...(perks ? [perks] : [])], (feature, i) => (
          <div className={cx('features', `col-12 col-md-${minify ? '2' : '4'}`)} key={i}>
            <img src={getPerkIcon(feature)} alt="feature" />
            <span>
              {!minify && ((_.find(featureEnum, { key: feature }) || {}).featureName || feature)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

LeaguePerks.propTypes = {
  league: PropTypes.shape({
    profile: PropTypes.shape({
      perks: PropTypes.string,
    }),
    details: PropTypes.shape({
      perks: PropTypes.string,
    }),
  }).isRequired,
  style: PropTypes.shape({}),
  minify: PropTypes.bool,
};

LeaguePerks.defaultProps = {
  style: {},
  minify: false,
};

export default LeaguePerks;
