import { gql } from '@apollo/client';

const PermitFragment = gql`
  fragment permitFragment on Permit {
    _id
    note
    sublocations
    referenceId
    centsHourly
    centsDaily
    pdf {
      name
      url
    }
    timeframe {
      date
      startTime
      endTime
      timezone
    }
    sublocationOptions
    approvedVenueId
    leagues {
      _id
      name
    }
  }
`;

export const QUERY_PERMITS_BY_RANGE = gql`
  query permitsByDateRange($input: PermitQueryInput!) {
    permitsByDateRange(input: $input) {
      permits {
        ...permitFragment
      }
      count
      endCursor
    }
  }
  ${PermitFragment}
`;

export const QUERY_VENUE = gql`
  query venue($venueId: ID!) {
    venue(_id: $venueId) {
      _id
      timezone
      sublocations
    }
  }
`;

export const QUERY_PERMIT = gql`
  query permit($permitId: ID!) {
    permit(_id: $permitId) {
      ...permitFragment
    }
  }
  ${PermitFragment}
`;

export const UPDATE_VENUE_PERMIT = gql`
  mutation updatePermit($input: UpdatePermitInput!) {
    updatePermit(input: $input) {
      permitList {
        _id
      }
    }
  }
`;

export default QUERY_PERMITS_BY_RANGE;
