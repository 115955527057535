import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from '@apollo/client';
import { DISCOVER_PROGRAM_TYPES } from './FILTER_QUERIES';
import { getParamsFromUrl, withPropsFromUrl } from './utils';
import { FilterSelect, Error } from '../../components';
import { startCase } from '../../shared/string-manipulations';

const ProgramTypeFilter = props => {
  const {
    programTypes,
    disabled,
    onSelect,
    isMulti,
    className,
    updateSearchUrls,
    cityName,
    skip,
    isHost,
  } = props;

  const {
    data: programTypesData = {},
    loading: programTypesLoading,
    error: programTypesError,
  } = useQuery(DISCOVER_PROGRAM_TYPES, {
    skip: skip || !cityName,
    fetchPolicy: 'cache-and-network',
    variables: getParamsFromUrl(props, isHost),
  });

  const { discoverProgramTypes = {} } = programTypesData;

  const { programTypes: discoveredTypes = [] } = discoverProgramTypes;

  const programTypesOptions = discoveredTypes.map(value => ({
    label: startCase(value.replace('_', ' - ')),
    value,
  }));

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : (v = []) => {
          updateSearchUrls({ programTypes: v.map(({ value }) => value) });
        };

  if (programTypesError) return <Error error={programTypesError} />;

  return (
    <FilterSelect
      short
      shadow
      labelSingular="Program Type"
      labelPlural="Program Types"
      options={programTypesOptions}
      value={programTypesOptions.filter(({ value }) => programTypes.includes(value)) || null}
      onChange={onChange}
      disabled={disabled || programTypesLoading}
      multi={isMulti}
      className={className}
    />
  );
};

ProgramTypeFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  programTypes: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  cityName: PropTypes.string,
  isHost: PropTypes.bool,
  skip: PropTypes.bool,
};

ProgramTypeFilter.defaultProps = {
  onSelect: false,
  disabled: false,
  isMulti: false,
  programTypes: [],
  className: '',
  cityName: '',
  isHost: false,
  skip: false,
};

export default withPropsFromUrl()(ProgramTypeFilter);
