import React from 'react';
import moment from 'moment-timezone';
import { NetworkStatus, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import { Button, Error, ListContainer, Loading } from '../../../components';
import {
  IncidentFlagLabels,
  IncidentTypeLabels,
  ResolutionStatusLabels,
} from '../../../shared/incident-report-enums';
import {
  PLAYER_INCIDENT_REPORT_QUERY,
  type UserIncidentReportsResult,
  type UserIncidentReportsVariables,
} from './PLAYER_INCIDENT_REPORT_QUERY';
import styles from '../../../apps/RFO/HostForms/IncidentReports/IncidentReport/styles.module.scss';

const INITIAL_PAGE_SIZE = 10;
const SHOW_ALL_PAGE_SIZE = 500;

const cx = classNames.bind(styles);

const PlayerIncidentReports: React.FC<{ userId: string }> = ({ userId }) => {
  const { data, loading, error, refetch, networkStatus } = useQuery<
    UserIncidentReportsResult,
    UserIncidentReportsVariables
  >(PLAYER_INCIDENT_REPORT_QUERY, {
    skip: !userId,
    variables: {
      input: {
        partiesInvolved: [userId],
        pagination: {
          numPerPage: INITIAL_PAGE_SIZE,
        },
      },
    },
  });

  const { incidentReports = [] } = data || {};

  const handleShowAll = async () => {
    await refetch({
      input: {
        partiesInvolved: [userId],
        pagination: {
          numPerPage: SHOW_ALL_PAGE_SIZE,
        },
      },
    });
  };

  const showAllVisible = incidentReports.length === INITIAL_PAGE_SIZE;

  if (loading) return <Loading />;

  if (error) return <Error error={error} />;

  return (
    <div className="mt-4">
      <ListContainer
        numOfCol={3}
        emptyText="User has no reported incidents"
        columnTitles={['Incident Date', 'Incident', 'Resolution Status']}
        columnData={incidentReports.map(incident => [
          moment(incident.incident_date).format('M/DD/YYYY'),
          <div className="row justify-content-center">
            <Link to={`/rfo/incident-reports/${incident._id}`}>Incident {incident._id}</Link>
            <p className={cx('ml-2 bold', `incident-report-${incident.incident_flag}`)}>
              {IncidentFlagLabels[incident.incident_flag]}{' '}
              {IncidentTypeLabels[incident.incident_type]}
            </p>
          </div>,
          ResolutionStatusLabels[incident.resolution_status],
        ])}
      />
      {showAllVisible && (
        <Button
          className="mt-4"
          onClick={handleShowAll}
          // @ts-ignore
          disabled={networkStatus === NetworkStatus.refetch}
        >
          Show all
        </Button>
      )}
    </div>
  );
};

export default PlayerIncidentReports;
