import { gql, type TypedDocumentNode } from '@apollo/client';
import type { PaginationInput } from '../../../apps/RFO/HostForms/ShiftLogs/types';

/**
 * This type isn't implemented in the backend yet.
 */
export type PlayerNoteType =
  | 'Mobile Comment Archive'
  | 'call'
  | 'email'
  | 'note'
  | 'DEACTIVATE'
  | 'ACTIVATE';

type UserDetailListInput = {
  input: {
    userId: string;
    pagination?: PaginationInput | null;
  };
};

export type UserListResponse = {
  userNoteList: {
    notes: {
      _id: string;
      _updated: string;
      createdBy: string;
      type: string;
      note: string;
    }[];
  };
};

export const GET_USER_NOTES: TypedDocumentNode<UserListResponse, UserDetailListInput> = gql`
  query userNoteList($input: UserDetailListInput!) {
    userNoteList(input: $input) {
      notes {
        _id
        _updated
        createdBy
        type
        note
      }
    }
  }
`;

type UserNoteInput = {
  input: {
    userId?: string | null;
    type?: PlayerNoteType | null;
    note?: string | null;
    _id?: string | null;
  };
};

/**
 * Helper type as multiple mutations in this file return the same thing. Pass the name of the
 * mutation as a type argument to get the correct response type.
 */
type UserNoteResponse<MutationName extends string> = {
  [type in MutationName]: {
    currentUser: {
      _id: string;
    };
  };
};

export const ADD_USER_NOTE: TypedDocumentNode<UserNoteResponse<'userNoteAdd'>, UserNoteInput> = gql`
  mutation userNoteAdd($input: UserNoteInput!) {
    userNoteAdd(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const EDIT_USER_NOTE: TypedDocumentNode<
  UserNoteResponse<'userNoteEdit'>,
  UserNoteInput
> = gql`
  mutation userNoteEdit($input: UserNoteInput!) {
    userNoteEdit(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const DELETE_USER_NOTE: TypedDocumentNode<
  UserNoteResponse<'userNoteDelete'>,
  UserNoteInput
> = gql`
  mutation userNoteDelete($input: UserNoteInput!) {
    userNoteDelete(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;
