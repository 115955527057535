import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styles from './player-details.module.scss';
import PlayerDetails from './PlayerDetails';
import { Popup } from '../../components';
import profile from '../../images/icons/user.svg';

const cx = classNames.bind(styles);

const PlayerDetailsPopup = props => {
  const { userId, imgStyles, refetch } = props;

  const [playerDetails, showPlayerDetails] = useState(null);

  return (
    <span className={cx('profile-img-container')}>
      <img
        style={imgStyles}
        className={cx('profile-img')}
        src={profile}
        alt="profile"
        onClick={() => showPlayerDetails(userId)}
      />
      {playerDetails && (
        <Popup
          className="full"
          hidden={!playerDetails}
          close={async () => {
            if (refetch) await refetch();
            showPlayerDetails(null);
          }}
        >
          <PlayerDetails userId={playerDetails} />
        </Popup>
      )}
    </span>
  );
};

PlayerDetailsPopup.propTypes = {
  userId: PropTypes.string.isRequired,
  imgStyles: PropTypes.shape({}),
  refetch: PropTypes.func,
};

PlayerDetailsPopup.defaultProps = {
  imgStyles: {},
  refetch: undefined,
};

export default PlayerDetailsPopup;
