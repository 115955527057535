export const upperFirst = (string: string | null | undefined) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';

export const startCase = (str: string | null | undefined = '') =>
  str
    ?.toLowerCase()
    ?.split(' ')
    ?.map(word => (word.length ? word[0]!.toUpperCase() + word.slice(1) : ''))
    ?.join(' ');

export const camelCase = (str: string | null | undefined = '') => {
  const splitStringArray = str?.split(/[^A-Za-z0-9]/g) || [];
  const splitStringsArrayMapped = splitStringArray?.map((word, index) =>
    index === 0 ? word?.toLowerCase() : upperFirst(word)
  );

  return splitStringsArrayMapped?.join('');
};
