import classNames from 'classnames/bind';
import * as _ from 'lodash-es';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import styles from '../permit-styles.module.scss';
import PermitCalendar from '../PermitDetails/PermitCalendar';
import { FilterSelect } from '../../../components';
import { FancyField } from '../../../components/fancyFieldsV2';

const cx = classNames.bind(styles);

const EditPermitPresenter = props => {
  const { sublocationOptions, updateVenuePermit, timezone, permit } = props;

  const [stagedPermit, updateStagedPermit] = useState(permit);

  const {
    note,
    referenceId,
    sublocations,
    centsDaily,
    timeframe,
    timeframe: { startTime, endTime, date },
  } = stagedPermit;

  return (
    <>
      <h1>Edit Permit</h1>
      <br />
      <div className={cx('notebook')}>
        <div className={cx('calendar')}>
          <label>Date</label>
          <PermitCalendar
            onDayClick={val =>
              updateStagedPermit({
                ...stagedPermit,
                timeframe: { ...timeframe, date: val },
              })
            }
            selectedDays={[new Date(moment(date).utc().format('MM/DD/YY'))]}
            month={new Date(date)}
          />
        </div>
        <div className={cx('time')}>
          <label>
            Timeframe <br />
            <small>Venue Timezone: {timezone}</small>
          </label>
          <FancyField
            blockStyle
            type="time"
            className="w-100"
            label="Start"
            value={startTime || '00:00'}
            onChange={val =>
              updateStagedPermit({
                ...stagedPermit,
                timeframe: { ...timeframe, startTime: val },
              })
            }
            inputStyle={{ borderRadius: '0' }}
          />
          <FancyField
            blockStyle
            type="time"
            className="w-100"
            label="End"
            value={endTime || '00:00'}
            onChange={val =>
              updateStagedPermit({
                ...stagedPermit,
                timeframe: { ...timeframe, endTime: val },
              })
            }
            inputStyle={{ borderRadius: '0' }}
          />
        </div>
        <div className={cx('cost')}>
          <label>Price Per Date</label>
          <CurrencyInput
            prefix="$"
            decimalScale={2}
            allowNegativeValue={false}
            defaultValue="0.00"
            value={centsDaily / 100}
            onValueChange={val =>
              updateStagedPermit({
                ...stagedPermit,
                centsDaily: (val || 0) * 100,
              })
            }
            className={cx('currencyInput')}
          />
        </div>
        <div className={cx('reference')}>
          <label>Reference ID</label>
          <FancyField
            blockStyle
            type="text"
            className="w-100"
            noLabel
            placeholder="Reference number"
            value={referenceId || ''}
            onChange={val => updateStagedPermit({ ...stagedPermit, referenceId: val })}
            inputStyle={{ borderRadius: '0' }}
          />
        </div>
        <div className={cx('sublocation')}>
          <label>Sublocation</label>
          <FilterSelect
            menuPlacement="top"
            className="p-2"
            noLabel
            options={sublocationOptions}
            multi
            placeholder="Sublocation(s)"
            shadow
            skinny
            value={_.filter(sublocationOptions, ({ value }) => _.includes(sublocations, value))}
            onChange={s => updateStagedPermit({ ...stagedPermit, sublocations: _.map(s, 'value') })}
          />
        </div>
        <div className={cx('extra')}>
          <label>Additional Info</label>
          <FancyField
            className="p-2"
            blockStyle
            type="text"
            noLabel
            maxLength="50"
            placeholder="Optional note..."
            inputStyle={{
              marginBottom: '0',
              borderRadius: '0px',
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
            }}
            value={note}
            onChange={val => updateStagedPermit({ ...stagedPermit, note: val })}
          />
        </div>
        <div
          onClick={() => updateVenuePermit(stagedPermit)}
          role="button"
          tabIndex={0}
          className={cx('plus')}
        >
          Update Permit
        </div>
      </div>
    </>
  );
};

EditPermitPresenter.propTypes = {
  permit: PropTypes.shape({
    note: PropTypes.string,
    referenceId: PropTypes.string,
    sublocations: PropTypes.arrayOf(PropTypes.string),
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    centsDaily: PropTypes.number,
  }).isRequired,
  updateVenuePermit: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  sublocationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};

export default EditPermitPresenter;
