import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { compose, withProps } from 'recompose';
import { DISCOVER_DAYS_OF_WEEK } from './FILTER_QUERIES';
import { getParamsFromUrl, withPropsFromUrl } from './utils';
import { ToggleButtons, FilterSelect } from '../../components';
import { getDowKey, DaysOfWeek } from '../../shared/days-of-week-enum';
import sortDaysOfWeek from '../../utils/sort-days-of-week';

const DayFilter = props => {
  const {
    daysOfWeek,
    allDaysOfWeekOptions,
    disabled,
    onSelect,
    isMulti,
    loading,
    className,
    updateSearchUrls,
    shortText,
    miscStyles,
    hideIcon,
    hideIndicator,
    toggleStyle,
  } = props;

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : (v = []) => updateSearchUrls({ daysOfWeek: v.map(({ value }) => value) });

  if (toggleStyle) {
    return (
      <ToggleButtons
        disabled={loading}
        options={allDaysOfWeekOptions}
        optionsSelected={daysOfWeek}
        onChange={value => {
          const copy = daysOfWeek.includes(value)
            ? daysOfWeek.filter(v => v !== value)
            : [...daysOfWeek, value];
          onChange(
            copy.map(v => ({ value: v })),
            'daysOfWeek'
          );
        }}
      />
    );
  }

  return (
    <FilterSelect
      short
      shadow
      labelSingular={shortText ? 'Day' : 'Day of the Week'}
      labelPlural={shortText ? 'Days' : 'Days of the Week'}
      options={allDaysOfWeekOptions}
      value={allDaysOfWeekOptions.filter(({ value }) => daysOfWeek.includes(value)) || null}
      onChange={onChange}
      disabled={disabled || loading}
      multi={isMulti}
      className={className}
      miscStyles={miscStyles}
      hideIcon={hideIcon}
      hideIndicator={hideIndicator}
    />
  );
};

DayFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  daysOfWeek: PropTypes.arrayOf(PropTypes.string),
  allDaysOfWeekOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  shortText: PropTypes.bool,
  miscStyles: PropTypes.shape({}),
  hideIcon: PropTypes.bool,
  hideIndicator: PropTypes.bool,
  toggleStyle: PropTypes.bool,
};

DayFilter.defaultProps = {
  onSelect: false,
  loading: false,
  disabled: false,
  isMulti: false,
  allDaysOfWeekOptions: [],
  daysOfWeek: [],
  className: '',
  shortText: false,
  miscStyles: {},
  hideIcon: false,
  hideIndicator: false,
  toggleStyle: false,
};

export default compose(
  withPropsFromUrl(),
  graphql(DISCOVER_DAYS_OF_WEEK, {
    skip: ({ skip }) => skip,
    options: props => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        ...getParamsFromUrl(props, props.isHost),
      },
    }),
    props: ({ data: { loading = true, discoverDaysOfWeek } }) => ({
      loading,
      allDaysOfWeek: sortDaysOfWeek(discoverDaysOfWeek?.daysOfWeek || []),
    }),
  }),
  withProps(({ allDaysOfWeek = [], showAllOptions }) => ({
    allDaysOfWeekOptions: !showAllOptions
      ? allDaysOfWeek.map(value => {
          const { name = '' } = getDowKey({ shortName: value }) || {};
          return {
            value,
            label: name,
          };
        })
      : Object.values(DaysOfWeek).map(({ shortName, abbrevName }) => ({
          value: shortName,
          label: abbrevName,
        })),
  }))
)(DayFilter);
