import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from '@apollo/client';
import { FilterSelect, Error } from '../../components';
import { DISCOVER_SKILL_LEVELS } from './FILTER_QUERIES';
import { getParamsFromUrl, withPropsFromUrl } from './utils';
import { upperFirst } from '../../shared/string-manipulations';

const SkillLevelFilter = props => {
  const { skillLevels, disabled, onSelect, isHost, isMulti, className, updateSearchUrls, skip } =
    props;

  const {
    data: skillsData = {},
    loading: skillsLoading,
    error: skillsError,
  } = useQuery(DISCOVER_SKILL_LEVELS, {
    fetchPolicy: 'cache-and-network',
    skip,
    variables: getParamsFromUrl(props, isHost),
  });

  const { discoverSkillLevels = {} } = skillsData;
  const { skillLevels: discoverLevels = [] } = discoverSkillLevels;

  const skillLevelsOptions = discoverLevels.map((value = '') => ({
    label: upperFirst((value || '').replace(/_/g, ' ')),
    value,
  }));

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : (v = []) => {
          updateSearchUrls({ skillLevels: v.map(({ value }) => value) });
        };

  if (skillsError) return <Error error={skillsError} />;

  return (
    <FilterSelect
      short
      shadow
      labelSingular="Skill Level"
      labelPlural="Skill Levels"
      options={skillLevelsOptions}
      value={skillLevelsOptions.filter(({ value }) => skillLevels.includes(value))}
      onChange={onChange}
      disabled={disabled || skillsLoading}
      multi={isMulti}
      className={className}
    />
  );
};

SkillLevelFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  skillLevels: PropTypes.arrayOf(PropTypes.string),
  updateSearchUrls: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  isHost: PropTypes.bool,
  skip: PropTypes.bool,
};

SkillLevelFilter.defaultProps = {
  skillLevels: [],
  onSelect: false,
  disabled: false,
  isMulti: false,
  className: '',
  isHost: false,
  skip: false,
};

export default withPropsFromUrl()(SkillLevelFilter);
