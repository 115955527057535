import { isValidUrl } from '../../../utils';

export const addSlideImage = async options => {
  const { setError, updateSlides, event, index } = options;

  try {
    const file = event.target.files[0];
    if (!file) throw new Error('No image found.');
    if (file.size > 1000000) throw new Error('Unable to upload - image size is too large');

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      const slideImage = reader.result;
      updateSlides({ slideImage }, index);
    };
  } catch (err) {
    setError(err);
  }
};

export const checkValidAndUpdate = async options => {
  const { setError, slides = [], updateHeroes } = options;

  // make sure all necessary fields are present
  try {
    if (!updateHeroes) throw new Error('updateHeroes required in ~/HomepageForms/HeroSlides');
    slides?.forEach(
      (
        {
          slideImage,
          slideText,
          slideButtonText,
          slideLink,
          secondarySlideButtonText,
          secondarySlideLink,
        },
        idx
      ) => {
        if (!slideText || !slideButtonText || !slideLink) {
          throw new Error(`Missing text for slide ${idx + 1}`);
        }
        if (!slideImage) {
          throw new Error(`Missing image for slide ${idx + 1}`);
        }
        if (!isValidUrl(slideLink)) {
          throw new Error(`Invalid URL for slide ${idx + 1}`);
        }
        if (secondarySlideButtonText && !isValidUrl(secondarySlideLink)) {
          throw new Error(`Invalid second URL for slide ${idx + 1}`);
        }
      }
    );
    await updateHeroes(slides);
  } catch (err) {
    setError(err);
  }
};

export const updateHeroSlides = async options => {
  const { setError, setSuccess, mutation, setUpdating, organizationId, heroSlides = [] } = options;

  try {
    if (!mutation) {
      throw new Error('mutation is not a function in ~/HeroSlides/helpers.jsx');
    }
    await mutation({
      variables: {
        input: {
          organizationId,
          heroSlides,
        },
      },
    });
    setSuccess('Updated Slides');
  } catch (err) {
    setError(err);
  } finally {
    setUpdating(false);
  }
};

export const updateHeroSlidesDuration = async options => {
  const {
    setError,
    setSuccess,
    mutation,
    setUpdating,
    organizationId,
    slideDuration = 5000,
  } = options;
  try {
    if (!mutation) {
      throw new Error('mutation is not a function in ~/HeroSlides/helpers.jsx');
    }
    await mutation({
      variables: {
        input: {
          organizationId,
          slideDuration,
        },
      },
    });
    setSuccess("Updated slides' Duration");
  } catch (err) {
    setError(err);
  } finally {
    setUpdating(false);
  }
};
