import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { PlayerStrikes } from '@rivallapp/volosports-components';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import styles from '../player-details.module.scss';
import {
  ADD_USER_NOTE,
  DELETE_USER_NOTE,
  EDIT_USER_NOTE,
  GET_USER_NOTES,
  type PlayerNoteType,
  type UserListResponse,
} from './PLAYER_RECORDS_QUERIES_AND_MUTATIONS';
import {
  AreYouSure,
  Button,
  FilterSelect,
  ListContainer,
  PageLoader,
  Popup,
} from '../../../components';
import { FancyField } from '../../../components/fancyFieldsV2';
import OverflowMenu from '../../../components/OverflowMenu';
import { useCurrentUser, useAlertMessage } from '../../../hooks';
import { capitalize } from '../../../utils';
import PlayerIncidentReports from '../PlayerIncidentReports/PlayerIncidentReports';

const cx = classNames.bind(styles);

type PlayerRecordsProps = {
  userId: string;
};

type Note = UserListResponse['userNoteList']['notes'][number];

const PlayerRecords: React.FC<PlayerRecordsProps> = props => {
  const { userId } = props;

  const { showSuccess, showError } = useAlertMessage();

  const [noteAction, setNoteAction] = useState('');
  const [currentNote, setCurrentNote] = useState<Partial<Note>>({
    note: '',
    type: 'note',
  });

  const [addUserNoteMutation] = useMutation(ADD_USER_NOTE);
  const [editUserNoteMutation] = useMutation(EDIT_USER_NOTE);
  const [deleteUserNoteMutation] = useMutation(DELETE_USER_NOTE);

  const { loading, error, currentUser } = useCurrentUser();
  const {
    data,
    refetch,
    loading: loadingNotes,
    error: errorNotes,
  } = useQuery(GET_USER_NOTES, {
    skip: !userId,
    fetchPolicy: 'network-only',
    variables: { input: { userId } },
  });
  if (loading || loadingNotes) return <PageLoader />;
  if (error || errorNotes || !userId || !currentUser?._id) {
    return <div>Error! {JSON.stringify(error || errorNotes || 'Missing userId!')}</div>;
  }

  const { notes } = data!.userNoteList;
  const { _id, note, type, createdBy, _updated } = currentNote ?? {};

  const setNoteAndAction = (updatedNote: Partial<Note>, action: string) => {
    setCurrentNote(updatedNote);
    setNoteAction(action);
  };

  const refetchState = async (message: string) => {
    await refetch();
    setNoteAndAction({}, '');
    showSuccess(message);
  };

  const checkNote = () => !currentNote || !note || !type;

  const addNote = async () => {
    try {
      if (checkNote()) throw new Error('Missing required note fields');
      await addUserNoteMutation({
        variables: { input: { userId, type: type as PlayerNoteType, note } },
      });
      await refetchState('Added new note.');
    } catch (e) {
      showError(e);
    }
  };

  const editNote = async () => {
    try {
      if (checkNote() || !_id) throw new Error('Missing required note fields');
      await editUserNoteMutation({
        variables: {
          input: {
            userId,
            type: type as PlayerNoteType,
            note,
            _id,
          },
        },
      });
      await refetchState('Saved edited note.');
    } catch (e) {
      showError(e);
    }
  };

  const deleteNote = async () => {
    try {
      if (!_id) throw new Error('Missing required note fields');
      await deleteUserNoteMutation({ variables: { input: { _id } } });
      await refetchState('Deleted note.');
    } catch (e) {
      showError(e);
    }
  };

  const selectStyle = {
    border: true,
    borderColor: '#f0f1f7',
    placeholderColor: '#4b506d',
    backgroundColor: '#fcfdfe',
    borderRadius: '8px',
  };

  const typeOptions = [
    { value: 'note', label: 'Note' },
    { value: 'email', label: 'Email' },
    { value: 'call', label: 'Call' },
  ] as const;

  return (
    <div className="container">
      {noteAction !== 'edit' && noteAction !== 'new' ? ( // no note selected for edit/new, show list
        <div className={cx('noteList', 'mt-5', 'mb-5')}>
          <ListContainer
            columnTitles={['Date', 'Type', 'Taken By', 'Notes', '']}
            columnData={notes.map(n => [
              { key: n._id },
              moment(+n._updated).format('MM/DD/YYYY h:mm a'),
              capitalize(n.type),
              n.createdBy,
              <div className={cx('cutOff')}>{n.note}</div>,
              <OverflowMenu
                options={[
                  { label: 'Edit', onClick: () => setNoteAndAction(n, 'edit') },
                  { label: 'Delete', onClick: () => setNoteAndAction(n, 'delete') },
                ]}
                position="bottomLeft"
                buttonType="verticalDots"
                largeDots
              />,
            ])}
            numOfCol={5}
            emptyText="No notes have been saved."
            onClick={columnData =>
              setNoteAndAction(notes.find(n => n._id === columnData[0]?.key)!, 'view')
            }
          />
          {/* @ts-expect-error - Button doesn't have correct typings */}
          <Button primary onClick={() => setNoteAndAction({ type: 'note' }, 'new')}>
            + Add Note
          </Button>
        </div>
      ) : (
        // show currentNote
        <div className={cx('newNote', 'container')}>
          <h2 className={cx('customHeader')}>{noteAction === 'new' ? 'Add' : 'Edit'} Note</h2>
          <div className="row mt-4">
            <div className="col-12 col-md-4">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>Type</label>
              <FilterSelect
                border
                placeholder="Select"
                options={typeOptions}
                value={typeOptions.find(({ value }) => value === type) || null}
                onChange={selectedNote =>
                  setCurrentNote({ ...currentNote, type: selectedNote?.value })
                }
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <FancyField
                textarea
                style={selectStyle}
                grey
                placeholder=" "
                noLabel
                value={note}
                onChange={(value: string) => setCurrentNote({ ...currentNote, note: value })}
              />
            </div>
          </div>
          {/* @ts-expect-error - Button doesn't have correct typings */}
          <Button primary onClick={noteAction === 'new' ? addNote : editNote}>
            Save
          </Button>
          {/* @ts-expect-error - Button doesn't have correct typings */}
          <Button link onClick={() => setNoteAndAction({}, '')}>
            Cancel
          </Button>
        </div>
      )}

      {/* This is a little bit better than flex=1 because each column is guaranteed to be equal width even if one of the items inside has smaller width than the column */}
      <div className={cx('reports-strikes-and-incidents')}>
        <div className={cx('player-strikes')}>
          <PlayerStrikes userId={userId} variant="admin" />
        </div>

        <div>
          <h4>Incidents</h4>
          <PlayerIncidentReports userId={userId} />
        </div>
      </div>

      <AreYouSure
        body="Are you sure you want to delete this note? Once deleted, it is irreversible."
        hidden={noteAction !== 'delete'}
        yesAction={deleteNote}
        close={() => setNoteAndAction({}, '')}
      />
      <Popup className="wide" hidden={noteAction !== 'view'} close={() => setNoteAndAction({}, '')}>
        <div className={cx('notePopup')}>
          <div className={cx('noteInfo')}>{note}</div>
          <div className={cx('noteAuthor')}>
            <b>{capitalize(type)} </b>
            taken by <b> {createdBy} </b>
            at <b> {moment(+(_updated || 0)).format('MM/DD/YYYY h:mm a')}</b>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default PlayerRecords;
