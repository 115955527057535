import classNames from 'classnames/bind';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';
import flag from '../../../images/icons/flag.svg';
import { CompetitionLevelsEnum } from '../../../shared/sports-page-details-enum';

const cx = classNames.bind(styles);

const LeagueDivisions = ({ league, style, minify }) => {
  const { hasDivisions, divisionDescriptions, sportFormatDetails } = league || {};
  if (!hasDivisions || !_.size(divisionDescriptions)) return <span />;

  const { competitionLevels } = sportFormatDetails || {};
  const customDivision = _.find(
    divisionDescriptions,
    level => !_.includes(CompetitionLevelsEnum, level)
  );

  return (
    <div>
      <div className={cx('info-container')} style={style}>
        <img className={cx('icon')} src={flag} alt="flag" />
        <p className={cx('title')}>Divisions</p>
        {_.map(competitionLevels, ({ level, description }) => (
          <p key={level}>
            {level} {!minify && <i>{description ? `-  ${description}` : ''}</i>}
          </p>
        ))}
        {customDivision && <p>{customDivision}</p>}
      </div>
    </div>
  );
};

LeagueDivisions.propTypes = {
  league: PropTypes.shape({
    profile: PropTypes.shape({
      description: PropTypes.string,
    }),
    details: PropTypes.shape({
      description: PropTypes.string,
    }),
  }).isRequired,
  style: PropTypes.shape({}),
  minify: PropTypes.bool,
};

LeagueDivisions.defaultProps = {
  style: {},
  minify: false,
};

export default LeagueDivisions;
