import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styles from './edit-game.module.scss';
import {
  CREATE_GAME_MUTATION,
  GET_GAME_QUERY,
  LEAGUE_DETAILS_QUERY,
  UPDATE_GAME_MUTATION,
} from './EDIT_GAME_QUERY_AND_MUTATION';
import AddWeekDay from '../../../../apps/RFO/Manage/Schedule/EditSchedule/EditWeekItem/AddWeekDay';
import { Button, Error, FilterSelect, Loading, Popup } from '../../../../components';
import { FancyField } from '../../../../components/fancyFieldsV2';
import withActionStates from '../../../../hocs/withActionStates';
import minus from '../../../../images/icons/blue/less.svg';
import { UseActionStatesProps } from '../../../../types';
import { ScheduleType } from '../../../../shared/schedule-type-enum';

const cx = classNames.bind(styles);

const EditGame = props => {
  const {
    gameId,
    leagueId,
    setError,
    afterSave,
    dayId: initialDayId,
    weekId: initialWeekId,
  } = props;

  const [updateGameMutation] = useMutation(UPDATE_GAME_MUTATION);
  const [createGameMutation] = useMutation(CREATE_GAME_MUTATION);

  const leagueRef = useQuery(LEAGUE_DETAILS_QUERY, {
    skip: !leagueId,
    fetchPolicy: 'network-only',
    variables: {
      leagueId,
      input: {
        scheduleTypes: [ScheduleType.League],
        includeUnapprovedSubSchedules: true,
      },
    },
  });

  const gameRef = useQuery(GET_GAME_QUERY, {
    skip: !gameId, // new game so no id
    fetchPolicy: 'network-only',
    variables: { gameId },
  });

  const [gameState, setGameState] = useState({});
  const [showSubLocation, setShowSubLocation] = useState(false);
  const [showNewDay, setShowNewDay] = useState(false);

  const updateGameState = update => setGameState({ ...gameState, ...update });

  const { league } = leagueRef?.data || {};
  const { game } = gameRef?.data || {};

  const {
    teams = [],
    venue = {},
    days = [],
    weeks = [],
    organization = {},
    schedules = [],
  } = league || {};
  const { approvedVenues = [] } = organization;

  const defaultScheduleId = schedules[0]?._id; // until multi-schedules are used

  useEffect(() => {
    // only update if initial set
    if (league && Object.keys(gameState).length === 0) {
      const initialTeams = gameId ? game?.teams : teams.slice(0, 2);
      setGameState({
        team1: initialTeams?.[0]?._id || 'TBD',
        team2: initialTeams?.[1]?._id || 'TBD',
        startTime: (gameId ? game?.startTimeStr : league.start_time_estimate) || '18:00',
        endTime: (gameId ? game?.endTimeStr : league.end_time_estimate) || '21:00',
        field_name: game?.field_name || '',
        location: gameId ? game?.location : venue,
        dayId: gameId ? game?.dayId : initialDayId,
        weekId: gameId ? game?.weekId : initialWeekId,
      });
      setShowSubLocation(!!game?.field_name);
    }
  }, [game, league]);

  if (leagueRef.loading || gameRef.loading) return <Loading />;
  if (leagueRef.error || gameRef.error || !leagueId) {
    return <Error error={leagueRef.error || gameRef.error || 'No league found for game!'} />;
  }

  const { team1, team2, startTime, endTime, field_name, location, dayId, weekId } = gameState;

  const onSave = async () => {
    try {
      const input = {
        teams: [team1, team2],
        startTime,
        endTime,
        subvenue: field_name,
        venueId: location._id,
        dayId,
        weekId,
        ...(gameId ? { gameId } : { subScheduleId: defaultScheduleId }),
      };
      const whichFunc = gameId ? updateGameMutation : createGameMutation;
      // filter out TBD games if they don't know which yet
      if (gameId && game?.is_tournament) input.teams = input.teams.filter(t => t !== 'TBD');
      await whichFunc({ variables: { input } });
      afterSave();
    } catch (err) {
      setError(err);
    }
  };

  const changeWeek = ({ value: newWeekId }) => {
    const weekDay = days.find(d => d.weekId === newWeekId);
    updateGameState({ weekId: newWeekId, dayId: weekDay?._id });
  };

  const addDay = async newDayId => {
    try {
      await leagueRef.refetch();
      updateGameState({ dayId: newDayId });
      setShowNewDay(false);
    } catch (err) {
      setError(err);
    }
  };

  const teamOptions = teams.map(t => ({ label: t.name, value: t._id }));
  const venueOptions = approvedVenues.map(v => ({ label: v.name, value: v._id }));
  const weekOptions = [...weeks]
    .sort((a, b) => new Date(a.week_num) - new Date(b.week_num))
    .map(w => ({ label: `Week #${w.week_num}`, value: w._id }));
  // only show days within the selected week
  const dayOptions = [...days]
    .filter(d => d.weekId === weekId)
    .map(d => ({ label: moment(d.date).format('MMMM Do, YYYY'), value: d._id }));

  return (
    <div className={cx('edit-game-popup')}>
      <Popup hidden={!showNewDay} close={() => setShowNewDay(false)}>
        <AddWeekDay weekId={weekId} leagueId={leagueId} afterSave={addDay} />
      </Popup>
      <h2>{game ? 'Edit Game' : 'Add New Game'}</h2>
      <div className="row mt-3">
        <div className="col-12 col-md-6">
          <label>Team 1</label>
          <FilterSelect
            short
            shadow
            value={teamOptions.find(({ value }) => value === team1) || null}
            options={teamOptions}
            onChange={({ value }) => updateGameState({ team1: value })}
            placeholder="Select Team"
            disabled={game?.is_tournament}
          />
        </div>
        <div className="col-12 col-md-6">
          <label>Team 2</label>
          <FilterSelect
            short
            shadow
            value={teamOptions.find(({ value }) => value === team2) || null}
            options={teamOptions}
            onChange={({ value }) => updateGameState({ team2: value })}
            placeholder="Select Team"
            disabled={game?.is_tournament}
          />
        </div>
        {game?.is_tournament && (
          <div className="mt-3">
            Editing tournament team matchups is disabled. To change the teams, please remake the
            tournament with a different seed order.
          </div>
        )}
      </div>
      <div className="row my-4">
        <div className="col-12">
          <label>Venue</label>
          <FilterSelect
            skinny
            shadow
            value={venueOptions.find(({ value }) => value === location?._id) || null}
            options={venueOptions}
            onChange={({ value }) =>
              updateGameState({
                location: approvedVenues.find(({ _id }) => _id === value),
              })
            }
            placeholder="Select Venue"
          />
          <p style={{ padding: '5px 0 0 2px', fontSize: '0.65em', textAlign: 'left' }}>
            Timezone: <b>{location?.timezone || venue.timezone}</b>
          </p>
        </div>
      </div>
      {!!gameId &&
        !game?.is_tournament && ( // only allow already created games to change date
          <div className="row my-4">
            <div className="col-12 col-md-6">
              <label>Week</label>
              <FilterSelect
                skinny
                shadow
                value={weekOptions.find(({ value }) => value === weekId)}
                options={weekOptions}
                onChange={changeWeek}
                placeholder="Select Week"
              />
            </div>
            <div className="col-12 col-md-6">
              <label>Day</label>
              <FilterSelect
                skinny
                shadow
                value={dayOptions.find(({ value }) => value === dayId)}
                options={dayOptions}
                onChange={({ value }) => updateGameState({ dayId: value })}
                placeholder="Select Day"
              />
              <div className={cx('add-location-link', 'my-2')}>
                <a onClick={() => setShowNewDay(true)} role="button" tabIndex={0}>
                  + Add a day
                </a>
              </div>
            </div>
          </div>
        )}
      <div className="row">
        <div className="col-12 col-md-6">
          <label>Start</label>
          <FancyField
            blockStyle
            noLabel
            value={startTime}
            onChange={value => updateGameState({ startTime: value })}
            type="time"
          />
        </div>
        <div className="col-12 col-md-6">
          <label>End</label>
          <FancyField
            blockStyle
            noLabel
            value={endTime}
            onChange={value => updateGameState({ endTime: value })}
            type="time"
          />
        </div>
      </div>
      {showSubLocation ? (
        <div className="row my-4">
          <div className="col-10">
            <label>Location</label>
            <FancyField
              grey
              noLabel
              value={field_name}
              onChange={value => updateGameState({ field_name: value })}
            />
          </div>
          <div className="col-2 my-auto p-0">
            <a
              onClick={() => {
                updateGameState({ field_name: '' });
                setShowSubLocation(false);
              }}
              role="button"
              tabIndex={0}
            >
              <img className={cx('icon-img')} src={minus} alt="minus" />
            </a>
          </div>
        </div>
      ) : (
        <div className={cx('add-location-link', 'my-2')}>
          <a onClick={() => setShowSubLocation(true)} role="button" tabIndex={0}>
            + Add a location
          </a>
        </div>
      )}
      <Button
        primary
        wide
        onClick={onSave}
        disabled={
          !(
            (game?.is_tournament || (team1 && team2 && team1 !== team2)) &&
            moment(endTime, 'HH:mm').isAfter(moment(startTime, 'HH:mm'))
          )
        }
      >
        Save
      </Button>
      <div className="row mt-3">
        <div className="col-12 col-md-auto">
          {(game?._creator || game?._updated) && (
            <small className={cx('creator')}>
              <b>Last updated: </b> {game._updated ? moment(+game._updated).format('lll') : ''}{' '}
              {game._creator ? `by ${game._creator}` : ''}
            </small>
          )}
        </div>
        <div className="col-12 col-md">
          {gameId && (
            <div className="text-right">
              <small>
                <b>GameId:</b> {gameId}
              </small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

EditGame.propTypes = {
  ...UseActionStatesProps,
  afterSave: PropTypes.func.isRequired,
  leagueId: PropTypes.string.isRequired,
  // only gameId OR dayId should be passed back
  gameId: PropTypes.string,
  dayId: PropTypes.string,
};

EditGame.defaultProps = {
  gameId: null,
  dayId: null,
};

export default withActionStates({ withAlerts: true })(EditGame);
