import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { Pressable, FontAwesomeIcon } from '@rivallapp/volosports-components';

import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../player-details.module.scss';
import { formatCents } from '../../../utils';

const cx = classNames.bind(styles);

const deleteCreditItem = ({ setDeleteCreditState, paymentId, organizationId }) => (
  <Pressable
    alignItems="center"
    justifyContent="center"
    onPress={() =>
      setDeleteCreditState({
        showCreditPopup: true,
        creditId: paymentId,
        organizationId,
      })
    }
  >
    <FontAwesomeIcon icon={faTrashCan} size={5} />
  </Pressable>
);

const ProgramPayments = ({ payments, availableCredits, setDeleteCreditState }) => (
  <div>
    <div className={cx('title', 'row')}>
      <div className="col-1">Date</div>
      <div className="col-5">Program Name</div>
      <div className="col-2">Promo</div>
      <div className="col-1">Credit</div>
      <div className="col-1">Donation</div>
      <div className="col-1">Paid</div>
      <div className="col-1">Revenue</div>
    </div>
    {payments.map(payment => (
      <div
        className={cx('invoiceRow', styles[payment.status.toLowerCase()], 'row')}
        role="button"
        tabIndex={0}
        key={payment.date}
      >
        <div className="col-1 my-auto text-left p-0">
          {moment(+payment.date).format('MM/DD/YY')} <br />
          {moment(+payment.date).format('h:mm a')}
        </div>
        <div className="col-5 my-auto text-left">
          <span>({payment.type})</span>
          {payment.rentalId ? (
            <Link to={`/rfo/rental/${payment.rentalId}`}> {payment.reason} </Link>
          ) : null}
          {payment.leagueId ? (
            <Link
              to={`/rfo/league/${payment.leagueId}/view?organization=${payment.organizationId}`}
            >
              {' '}
              {payment.reason}{' '}
            </Link>
          ) : null}
          {!payment.leagueId && !payment.rentalId ? ` ${payment.reason} ` || ' --- ' : null}
          <span>{payment?.isDropin && ' - DROPIN'}</span>
        </div>
        {payment.manuallyAddedBy ? (
          <>
            <div className="col-3 my-auto">
              <b>Added by: </b> {payment.manuallyAddedBy}
            </div>
            <div className="col-2 my-auto">
              {payment.status === 'Credit' && (
                <span className={cx('greenColor')}>+{formatCents(payment.credits || 0)}</span>
              )}
            </div>
            <div className="col-1 my-auto p-0">
              {payment.status === 'Credit' &&
                availableCredits >= payment.net &&
                deleteCreditItem({ setDeleteCreditState, ...payment })}
            </div>
          </>
        ) : (
          <>
            <div className="col-2 my-auto">
              {payment.status === 'Paid' && (payment.promoCode || 'N/A')}
            </div>
            <div className="col-1 my-auto">
              {payment.status === 'Paid' &&
                (payment.credits ? formatCents(payment.credits) : '---')}
            </div>
            <div className="col-1 my-auto">
              {payment.status === 'Paid' &&
                (payment.donation ? formatCents(payment.donation) : '---')}
            </div>
            <div className="col-1 my-auto">
              {payment.credit > 0 && (
                <span className={cx('greenColor')}>+{formatCents(payment.net || 0)}</span>
              )}
              {payment.refund > 0 && (
                <span className={cx('redColor')}>-{formatCents(payment.refund || 0)}</span>
              )}
              {payment.status === 'Paid' && !payment.refund && formatCents(payment.net || 0)}
            </div>
            <div className="col-1 my-auto p-0">
              {payment.status === 'Paid' && !payment.refund && formatCents(payment.revenue || 0)}
              {payment.status === 'Credit' &&
                availableCredits >= payment.net &&
                deleteCreditItem({ setDeleteCreditState, ...payment })}
            </div>
          </>
        )}
      </div>
    ))}
    <div className={cx('legend')}>
      <div className="row">
        <div className="col">
          <b>Type Legend</b>
        </div>
        <div className="col">
          <b>P</b> = Paid Registration
        </div>
        <div className="col">
          <b>R</b> = Refund
        </div>
        <div className="col">
          <b>LC</b> = League Credit
        </div>
        <div className="col">
          <b>GC</b> = Gift Card
        </div>
      </div>
    </div>
  </div>
);

ProgramPayments.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      paymentId: PropTypes.string,
      leagueId: PropTypes.string,
      date: PropTypes.string,
      net: PropTypes.number,
      credits: PropTypes.number,
      revenue: PropTypes.number,
      maxPartialRefund: PropTypes.number,
      disableRefunds: PropTypes.bool,
      disablePartialRefunds: PropTypes.bool,
      fullRefund: PropTypes.number,
      chargeId: PropTypes.string,
      status: PropTypes.string,
      type: PropTypes.string,
      reason: PropTypes.string,
      manuallyAddedBy: PropTypes.string,
      platform: PropTypes.string,
    })
  ).isRequired,
  setDeleteCreditState: PropTypes.func.isRequired,
  availableCredits: PropTypes.number.isRequired,
};

export default ProgramPayments;
