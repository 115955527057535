import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState, branch, mapProps, setDisplayName } from 'recompose';
import * as _ from 'lodash-es';
import classNames from 'classnames/bind';
import { Popup, Loading } from '../../components';
import Image from '../../components/Image';
import { prettyUrl } from '../../utils';
import weatherIcoBlk from '../../images/icons/umbrella.svg';
import jerseysIcoBlk from '../../images/icons/shirt.svg';
import refundsIcoBlk from '../../images/icons/piggy.svg';
import forfeitsIcoBlk from '../../images/icons/less.svg';
import faqIcoBlk from '../../images/icons/help2.svg';
import charityIcoBlk from '../../images/icons/heart.svg';
import aboutIcoBlk from '../../images/icons/about.svg';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const iconsBlk = {
  weather: weatherIcoBlk,
  jerseys: jerseysIcoBlk,
  refunds: refundsIcoBlk,
  forfeits: forfeitsIcoBlk,
  faq: faqIcoBlk,
  about: aboutIcoBlk,
  charity: charityIcoBlk,
};
const titles = {
  weather: 'Weather Policy',
  jerseys: 'Jersey Policy',
  refunds: 'Refund Policy',
  forfeits: 'Forfeits Policy',
  faq: 'FAQ/Support',
  about: 'About',
  charity: 'Giving Back',
};

const OrgPoliciesResources = props => {
  const {
    popup,
    setPopup,
    organization: { name, website_url, icon, logo, city, more_information },
  } = props;

  const pUrl = prettyUrl(website_url);
  const orgImg = logo || icon;

  const { about, weather, jerseys, refunds, forfeits, faq, charity } = more_information || {};

  const hasAtLeastOnePolicy = _.reduce(
    more_information,
    (acc, cur) => (cur && (cur.html || cur.website_url)) || acc,

    false
  );

  const policies = [
    { label: 'faq', item: faq },
    { label: 'charity', item: charity },
    { label: 'weather', item: weather },
    { label: 'jerseys', item: jerseys },
    { label: 'refunds', item: refunds },
    { label: 'forfeits', item: forfeits },
  ];

  return (
    <div className={cx('org-policies-resources')}>
      <div className={cx('text-center', 'mb-4')}>
        {orgImg && (
          <div className={cx('mb-4', styles.orgImg)}>
            <Image src={orgImg} />
          </div>
        )}
        <div className={cx('mb-4')}>
          <h3 className="page-title">{name}</h3>
          <div className={cx('org-basics')}>
            {city}
            {city && website_url ? ' · ' : ''}
            <a
              className={cx('external-site')}
              href={website_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              {pUrl}
            </a>
          </div>
        </div>
        {_.get(about, 'prefer') === 'html' && (
          <div
            className={cx('html', 'about-text')}
            dangerouslySetInnerHTML={{ __html: about.html }}
          />
        )}
      </div>
      {hasAtLeastOnePolicy && (
        <div className={cx('org-policies')}>
          <div className={cx('policy-items')}>
            {_.map(policies, policy => (
              <PolicyItem key={policy.label} policy={policy} setPopup={setPopup} />
            ))}
          </div>
        </div>
      )}
      <Popup className="fixed" close={() => setPopup(null)} hidden={!popup}>
        <div className={cx('policy-html')}>
          <Image src={_.get(iconsBlk, popup)} />
          <h4>
            {name} {_.get(titles, popup)}
          </h4>
          {_.get(more_information, `${popup}.html`) && (
            <div
              className={cx('html')}
              dangerouslySetInnerHTML={{ __html: _.get(more_information, `${popup}.html`) }}
            />
          )}
        </div>
      </Popup>
    </div>
  );
};

const moreInfoProp = PropTypes.shape({
  website_url: PropTypes.string,
  html: PropTypes.string,
  prefer: PropTypes.string,
});

OrgPoliciesResources.propTypes = {
  popup: PropTypes.string,
  setPopup: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    name: PropTypes.string,
    website_url: PropTypes.string,
    icon: PropTypes.string,
    logo: PropTypes.string,
    city: PropTypes.string,
    more_information: PropTypes.shape({
      about: moreInfoProp,
      weather: moreInfoProp,
      jerseys: moreInfoProp,
      refunds: moreInfoProp,
      forfeits: moreInfoProp,
      faq: moreInfoProp,
      charity: moreInfoProp,
    }),
  }).isRequired,
};

OrgPoliciesResources.defaultProps = {
  popup: null,
};

const PolicyItem = ({ policy, setPopup }) => {
  const { item, label } = policy;
  if (!item || (!item.html && !item.website_url)) return <span />;
  const action =
    item.prefer === 'html'
      ? {
          onClick: () => setPopup(label),
        }
      : {
          href: item.website_url,
          target: '_blank',
        };
  return (
    <a className={cx('policy-item')} {...action}>
      <Image src={_.get(iconsBlk, label)} />
      <h4>{_.get(titles, label)}</h4>
    </a>
  );
};

PolicyItem.propTypes = {
  policy: PropTypes.shape({
    label: PropTypes.string.isRequired,
    item: moreInfoProp,
  }).isRequired,
  setPopup: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('OrganizationPoliciesContainer'),
  mapProps(({ organization }) => ({
    organization: organization?.data || organization,
  })),
  withState('popup', 'setPopup', null),
  branch(
    ({ organization }) => !organization,
    () => Loading
  )
)(OrgPoliciesResources);
