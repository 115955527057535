import { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { isEmail } from 'validator';
import {
  PlayerRecordsTab,
  PlayerParticipationTab,
  PlayerPaymentTab,
  PlayerWaiversTab,
} from './index';
import styles from './player-details.module.scss';
import {
  EMAIL_IS_VALID,
  PHONE_NUMBER_IS_VALID,
  UPDATE_USER_PROFILE,
} from './PLAYER_DETAILS_MUTATIONS';
import { USER_AVERAGE_RATING_QUERY, USER_BY_INFO_QUERY } from './PLAYER_DETAILS_QUERIES';
import { Loading, TabNav } from '../../components';
import useActionStates from '../../hooks/useActionStates';
import PlayerInfo from './PlayerInfo';
import { GenderNameEnum } from '../../shared/gender-enum';
import ClientEnum from '../../shared/client-name-context-enum';
import UserShiftLogsTab from './UserShiftLogsTab/UserShiftLogsTab';

const cx = classNames.bind(styles);

const PlayerDetails = props => {
  const { userId } = props;
  const { setUpdating, setSuccess, setError } = useActionStates({ withAlerts: true });

  const [updatingInfo, setUpdatingInfo] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const updateUserDetails = update => setUserDetails({ ...userDetails, ...update });

  const [emailIsValid] = useMutation(EMAIL_IS_VALID);
  const [phoneNumberIsValid] = useMutation(PHONE_NUMBER_IS_VALID);
  const [updateUserMutation] = useMutation(UPDATE_USER_PROFILE);

  const { data, loading, error, refetch } = useQuery(USER_BY_INFO_QUERY, {
    skip: !userId,
    fetchPolicy: 'network-only',
    variables: { input: { search: userId, which: '_id' } },
    onCompleted: ({ getUserByInfo }) => {
      setUserDetails({
        ...getUserByInfo,
        // format for PhoneInput
        phone_number: `+1${getUserByInfo?.phone_number}`,
      });
    },
  });

  const {
    data: ratingData,
    loading: ratingLoading,
    error: ratingError,
  } = useQuery(USER_AVERAGE_RATING_QUERY, {
    context: { clientName: ClientEnum.MEMBERSHIP },
    skip: true,
    variables: { userId },
  });

  const MemoPayment = useCallback(
    () => (
      <PlayerPaymentTab
        userId={userId}
        setUpdating={setUpdating}
        setSuccess={setSuccess}
        setError={setError}
      />
    ),
    [setError, setSuccess, setUpdating, userId]
  );
  const MemoRecords = useCallback(() => <PlayerRecordsTab userId={userId} />, [userId]);
  const MemoParticipation = useCallback(
    () => <PlayerParticipationTab userId={userId} userDetails={userDetails} />,
    [userId, userDetails]
  );
  const MemoWaivers = useCallback(
    () => <PlayerWaiversTab userId={userId} userDetails={userDetails} />,
    [userId, userDetails]
  );

  const MemoShiftLogs = useCallback(() => <UserShiftLogsTab userId={userId} />, [userId]);

  if (loading || ratingLoading) return <Loading />;
  if (error || ratingError || !userId)
    return <div>Error! {JSON.stringify(error || ratingError || 'Missing userId')}</div>;

  const { firstName, lastName, email, countryCode, phone_number, gender, birthday } = userDetails;

  const { ability = 0, friendliness = 0, punctuality = 0 } = ratingData?.user?.averageRatings ?? {};
  const rating = ((ability + friendliness + punctuality) / 3).toFixed(2);

  const updateUserInfo = async () => {
    try {
      if (!firstName || !lastName || !email || !phone_number || !gender || !birthday) {
        throw new Error('Missing information to save profile.');
      }
      setUpdating(true);
      const user = data.getUserByInfo;

      // check email
      if (email !== user.email) {
        if (!isEmail(email)) throw new Error('Email is not valid.');
        const {
          data: {
            emailIsValid: { isValid, message },
          },
        } = await emailIsValid({
          variables: { input: { email } },
        });
        if (!isValid) throw new Error(message);
      }

      // check phone
      if (phone_number?.replace(/\D/g, '') !== `1${user.phone_number.replace(/\D/g, '')}`) {
        const {
          data: {
            phoneNumberIsValid: { isValid, message },
          },
        } = await phoneNumberIsValid({
          variables: { input: { phoneNumber: phone_number, countryCode } },
        });
        if (!isValid) throw new Error(message);
      }

      // update input
      await updateUserMutation({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            phoneNumber: phone_number,
            countryCode,
            gender,
            birthday,
            userId,
          },
        },
      });
      refetch();
      setUpdatingInfo(false);
      setSuccess('Updated user info.');
    } catch (e) {
      setError(e);
    } finally {
      setUpdating(false);
    }
  };

  const genderOptions = Object.keys(GenderNameEnum).map(value => ({
    label: GenderNameEnum[value],
    value,
  }));

  return (
    <div className={cx('playerDetails')}>
      <div className="row">
        <PlayerInfo
          {...{
            genderOptions,
            rating,
            setUpdatingInfo,
            updateUserDetails,
            updateUserInfo,
            updatingInfo,
            userDetails,
            ...props,
          }}
        />
        <div className="col-12 col-md-10">
          <TabNav
            initialTab={1}
            allTabs={[
              { label: 'Payment', component: MemoPayment },
              { label: 'Participation', component: MemoParticipation },
              { label: 'Records', component: MemoRecords },
              { label: 'Waivers', component: MemoWaivers },
              ...(userDetails.is_host ? [{ label: 'Shift Logs', component: MemoShiftLogs }] : []),
            ]}
          />
        </div>
      </div>
      <small className="float-right">userId: {userId}</small>
    </div>
  );
};

PlayerDetails.propTypes = {
  userId: PropTypes.string,
};

PlayerDetails.defaultProps = {
  userId: null,
};

export default PlayerDetails;
