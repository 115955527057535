import { type MultiValue } from 'react-select';
import { type IShiftLogsFiltersStore } from '../initialShiftLogsFiltersStore';
import useShiftLogsFiltersStore from '../ShiftLogsFiltersStore';

type FilterValue = MultiValue<{
  label: string;
  value: string | number;
}>;

const isMultiValue = (
  val: IShiftLogsFiltersStore[keyof IShiftLogsFiltersStore] | FilterValue
): val is FilterValue =>
  Array.isArray(val) && val.every(v => typeof v === 'object' && 'label' in v && 'value' in v);

/**
 * Updates the specified field in the ShiftLogsFilters store
 *
 * Date filter sends back a string, and we can simply update the store with the string
 *
 * All other filters use the multi FilterSelect, which sends back
 * the MultiValue type, a readonly array of objects with label and value.
 * We only need to update the store with an array of the values.
 *
 * Technically we could just update the store with the MultiValue type, but since we're using
 * zustand-querystring, it's going to clog up the URL quite a bit
 */
const updateShiftLogFilters = <T extends keyof IShiftLogsFiltersStore>(
  key: T,
  val: IShiftLogsFiltersStore[T] | FilterValue
) => {
  useShiftLogsFiltersStore.setState(state => {
    if (isMultiValue(val)) {
      const newValue = val.map(v => v.value);

      return { ...state, ...{ [key]: newValue } };
    }
    return { ...state, ...{ [key]: val } };
  });
};

export default updateShiftLogFilters;
