/* eslint-disable react/jsx-props-no-spreading */
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe, type StripeCardElementChangeEvent } from '@stripe/stripe-js';
import React, { memo, useCallback, useState } from 'react';
import { Button, Column, View } from '@rivallapp/volosports-components';
import { stripePublishableKey } from '../../../environment';
import { useAlertMessage } from '../../../hooks';
import { CardElementOptions, ElementsOptions } from './stripe-element-options';

const stripePromise = loadStripe(stripePublishableKey!);

type Props = {
  onTokenSuccess: (tokenId: string) => Promise<unknown>;
};

type FormStatus = 'complete' | 'incomplete' | 'error' | 'loading';

const StripeInputConsumer: React.FC<Props> = ({ onTokenSuccess }) => {
  const { showError } = useAlertMessage();
  const [status, setStatus] = useState<FormStatus>('incomplete');

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = useCallback((event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setStatus('error');
    } else if (event.complete) {
      setStatus('complete');
    } else {
      setStatus('incomplete');
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    // These are null until the stripe promise resolves
    if (!stripe || !elements) return;

    try {
      setStatus('loading');
      const card = elements.getElement('card')!;
      const { token, error } = await stripe.createToken(card, { currency: 'usd' });
      if (error) throw new Error(error.message);
      await onTokenSuccess(token.id);
      setStatus('incomplete');
    } catch (err) {
      setStatus('error');
      showError(err);
    }
  }, [elements, stripe, onTokenSuccess, showError]);

  return (
    <Column>
      <View py={3}>
        <CardElement options={CardElementOptions} onChange={handleChange} />
      </View>
      <Button
        mt={2}
        height={9}
        rounded="full"
        variant="outline"
        onPress={handleSubmit}
        isDisabled={status !== 'complete'}
        isLoading={status === 'loading'}
      >
        Save
      </Button>
    </Column>
  );
};

const withStripeElements =
  <T extends {}>(Component: React.ComponentType<T>) =>
  (props: T) => {
    return (
      <Elements options={ElementsOptions} stripe={stripePromise}>
        <Component {...props} />
      </Elements>
    );
  };

const StripeInput = withStripeElements(StripeInputConsumer);

export default memo(StripeInput);
