import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, withProps } from 'recompose';
import { withPropsFromUrl } from './utils';
import { FilterSelect } from '../../components';
import { LeagueStageEnum } from '../../shared/league-status-enum';

const ProgramStatusFilter = props => {
  const {
    programStatus,
    programStatusOptions,
    disabled,
    onSelect,
    isMulti,
    loading,
    className,
    updateSearchUrls,
  } = props;

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : ps => updateSearchUrls({ programStatus: _.get(ps, 'value') || 'CLEAR' });

  return (
    <FilterSelect
      short
      shadow
      labelSingular="Program Status"
      labelPlural="Program Status"
      options={programStatusOptions}
      value={
        _.filter(programStatusOptions, ({ value }) => _.includes(programStatus, value)) || null
      }
      onChange={onChange}
      disabled={disabled || loading}
      multi={isMulti}
      clearable
      className={className}
    />
  );
};

ProgramStatusFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  programStatus: PropTypes.string,
  programStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

ProgramStatusFilter.defaultProps = {
  onSelect: false,
  loading: false,
  disabled: false,
  isMulti: false,
  programStatusOptions: [],
  className: '',
  programStatus: '',
};

export default compose(
  withPropsFromUrl(),
  withProps(() => ({
    programStatusOptions: _.map(Object.values(LeagueStageEnum), value => ({
      label: _.startCase(value),
      value,
    })),
  }))
)(ProgramStatusFilter);
