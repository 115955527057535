export enum ProgramTypeEnum {
  LEAGUE = 'LEAGUE', // recurring, teams compete
  TOURNAMENT = 'TOURNAMENT', // not recurring, teams compete
  EVENT = 'EVENT', // not recurring, no competition
  PRACTICE = 'PRACTICE',
  PICKUP = 'PICKUP',
  DROPIN = 'DROPIN',
  VOLUNTEER_EVENT = 'VOLUNTEER_EVENT',
  VOLUNTEER_LEAGUE = 'VOLUNTEER_LEAGUE',
  CLASS = 'CLASS',
  CLINIC = 'CLINIC',
  SERIES = 'SERIES',
  RENTAL = 'RENTAL',
}

export const ProgramType = Object.freeze({
  [ProgramTypeEnum.LEAGUE]: {
    value: ProgramTypeEnum.LEAGUE,
    name: 'League',
    isDaily: false,
  },
  [ProgramTypeEnum.SERIES]: {
    value: ProgramTypeEnum.SERIES,
    name: 'Series',
    isDaily: false,
  },
  [ProgramTypeEnum.TOURNAMENT]: {
    value: ProgramTypeEnum.TOURNAMENT,
    name: 'Tournament',
    isDaily: false,
  },
  [ProgramTypeEnum.VOLUNTEER_LEAGUE]: {
    value: ProgramTypeEnum.VOLUNTEER_LEAGUE,
    name: 'Volunteer - League',
    isDaily: false,
  },
  [ProgramTypeEnum.EVENT]: {
    value: ProgramTypeEnum.EVENT,
    name: 'Event',
    isDaily: true,
  },
  [ProgramTypeEnum.PRACTICE]: {
    value: ProgramTypeEnum.PRACTICE,
    name: 'Practice',
    isDaily: true,
  },
  [ProgramTypeEnum.PICKUP]: {
    value: ProgramTypeEnum.PICKUP,
    name: 'Pickup',
    isDaily: true,
  },
  [ProgramTypeEnum.VOLUNTEER_EVENT]: {
    value: ProgramTypeEnum.VOLUNTEER_EVENT,
    name: 'Volunteer - Event',
    isDaily: true,
  },
  // DAILY:
  [ProgramTypeEnum.CLASS]: {
    value: ProgramTypeEnum.CLASS,
    name: 'Class',
    isDaily: true,
  },
  [ProgramTypeEnum.CLINIC]: {
    value: ProgramTypeEnum.CLINIC,
    name: 'Clinic',
    isDaily: true,
  },
  [ProgramTypeEnum.DROPIN]: {
    value: ProgramTypeEnum.DROPIN,
    name: 'Drop-In',
    isDaily: true,
  },
  [ProgramTypeEnum.RENTAL]: {
    value: ProgramTypeEnum.RENTAL,
    name: 'Rental',
    isDaily: false,
  },
});

export const LeagueProgramTypes = Object.freeze([
  'LEAGUE',
  'TOURNAMENT',
  'SERIES',
  'VOLUNTEER_LEAGUE',
]);
export const DailyProgramTypes = Object.freeze([
  'EVENT',
  'PRACTICE',
  'PICKUP',
  'VOLUNTEER_EVENT',
  'CLASS',
  'CLINIC',
  'DROPIN',
]);

export const DailyProgramsList = Object.freeze([
  ProgramTypeEnum.CLASS,
  ProgramTypeEnum.CLINIC,
  ProgramTypeEnum.DROPIN,
  ProgramTypeEnum.EVENT,
  ProgramTypeEnum.PICKUP,
  ProgramTypeEnum.PRACTICE,
]);

export const SportsList = Object.freeze([
  ProgramTypeEnum.VOLUNTEER_EVENT,
  ProgramTypeEnum.VOLUNTEER_LEAGUE,
  ProgramTypeEnum.LEAGUE,
  ProgramTypeEnum.TOURNAMENT,
  ProgramTypeEnum.SERIES,
]);

export const HomeDailyProgramsList = Object.freeze([
  ProgramTypeEnum.CLASS,
  ProgramTypeEnum.CLINIC,
  ProgramTypeEnum.DROPIN,
  ProgramTypeEnum.PICKUP,
  ProgramTypeEnum.PRACTICE,
]);

export const VoloFitnessProgramsList = Object.freeze([
  ProgramTypeEnum.CLASS,
  ProgramTypeEnum.SERIES,
]);

export const EventsList = Object.freeze([ProgramTypeEnum.EVENT]);

export const findDailyProgramTypeKeyByName = (programTypeName: ProgramTypeEnum) => {
  const cleanProgramType = (programTypeName || '').trim().toLowerCase();
  const programTypeFound = Object.entries(ProgramType).find(
    ([key, item]) =>
      key !== ProgramTypeEnum.LEAGUE &&
      key !== ProgramTypeEnum.VOLUNTEER_LEAGUE &&
      item.name.toLowerCase() === cleanProgramType
  );
  return programTypeFound?.[0];
};

export enum ConnectionTypeEnum {
  LEAGUE = 'League',

  /**
   * @deprecated April 12 2024. We do not use this anymore.
   */
  TRIP = 'Trip',

  /**
   * @deprecated April 12 2024. We do not use this anymore.
   */
  EVENT = 'Event',
}
