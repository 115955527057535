/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row } from '@rivallapp/volosports-components';
import withActionStates from '../../../../../../hocs/withActionStates';
import { PaymentForm } from '../../../../../../features';
import { Error, Loading } from '../../../../../../components';
import { useVoloPassDetails } from '../../../../../../hooks';
import ChoosePlan from './ChoosePlan';
import PromoCode from './PromoCode';
import {
  StyledButton,
  ParagraphText,
  colors,
  fonts,
  fontWeights,
} from '../../../../../../components/styledComponents';
import { RegistrationContext } from '../../../../../../contexts';

import { StripeSubscriptionStatus } from '../../../../../../shared/stripe-enums';
import { VPTermsAndConditionsCheckbox } from '../../../../../../components/VPTermsAndConditionsPopup';

const UPGRADE_NOW = 'Upgrade Now';
const THANKS_FOR_BEING_A_MEMBER =
  'Thanks for being a Volo Pass member! Explore programs to start using your membership!';
const FIND_A_PROGRAM = 'Find a program';

const PaymentDiv = styled.div`
  && {
    font-size: 16px;
  }
`;

const VoloPassCheckout = ({ updating, selectedCity, initAnnual, refetch, ...props }) => {
  const {
    loading,
    error,
    planStatus,
    acceptedTerms,
    setAcceptedTerms,
    planDetails,
    updatePlanDetails,
    validatePromo,
    getPromoText,
    startPlan,
    reinstatePlan,
  } = useVoloPassDetails(props);

  const [registerState] = useContext(RegistrationContext);

  const { paymentSources } = registerState;

  useEffect(() => {
    const initPlanId = initAnnual
      ? selectedCity?.voloPassTiers?.annualPlanId
      : selectedCity?.voloPassTiers?.monthlyPlanId;
    updatePlanDetails({ planId: initPlanId, selectedOrganizationId: selectedCity?._id });
  }, [selectedCity, initAnnual]);

  const onUpgradeClick = async () => {
    if (!planStatus) {
      await startPlan();
    } else {
      await reinstatePlan();
    }
    if (refetch) await refetch();
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const isMember = [StripeSubscriptionStatus.ACTIVE, StripeSubscriptionStatus.TRIALING].includes(
    planStatus
  );

  return (
    <>
      {!isMember && (
        <div>
          <ChoosePlan
            selectedCity={selectedCity}
            updatePlanDetails={updatePlanDetails}
            planDetails={planDetails}
          />
          <PromoCode
            updatePlanDetails={updatePlanDetails}
            planDetails={planDetails}
            validatePromo={validatePromo}
            getPromoText={getPromoText}
          />
          <PaymentDiv>
            <PaymentForm />
          </PaymentDiv>
          <Row padding={1} margin={1} marginBottom={3} alignItems="center" textAlign="left">
            <VPTermsAndConditionsCheckbox
              value={acceptedTerms}
              onChange={setAcceptedTerms}
              preText="Accept "
              postText=" to continue"
              _text={{ fontSize: 'xs' }}
            />
          </Row>
          <StyledButton
            backgroundColor={colors.mediumBlue}
            textColor={colors.white}
            onClick={onUpgradeClick}
            disabled={updating || !acceptedTerms || !paymentSources?.length}
          >
            {UPGRADE_NOW}
          </StyledButton>
        </div>
      )}
      {isMember && (
        <>
          <ParagraphText
            fontFamily={fonts.OPEN_SANS}
            fontWeight={fontWeights.regular}
            fontColor={colors.black}
            fontSize="20px"
            lineHeight="25px"
            marginBottom="20px"
            marginTop="15px"
          >
            {THANKS_FOR_BEING_A_MEMBER}
          </ParagraphText>
          <Link to={{ pathname: '/discover', search: `?cityName=${selectedCity.name}` }}>
            <StyledButton
              backgroundColor={colors.mediumBlue}
              textColor={colors.white}
              onClick={onUpgradeClick}
            >
              {FIND_A_PROGRAM}
            </StyledButton>
          </Link>
        </>
      )}
    </>
  );
};

VoloPassCheckout.propTypes = {
  setShowSignUpModal: PropTypes.func.isRequired,
  initAnnual: PropTypes.bool.isRequired,
  selectedCity: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    voloPassTiers: PropTypes.shape({
      monthlyPlanId: PropTypes.string.isRequired,
      annualPlanId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  updating: PropTypes.bool.isRequired,
  refetch: PropTypes.func,
};

VoloPassCheckout.defaultProps = {
  refetch: null,
};

export default withActionStates({ withAlerts: true })(VoloPassCheckout);
