import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { DISCOVER_PROGRAM_TAGS } from './FILTER_QUERIES';
import { getParamsFromUrl, withPropsFromUrl } from './utils';
import { Error, FilterSelect } from '../../components';

const ProgramTagFilter = props => {
  const {
    programTagIds,
    disabled,
    onSelect,
    isHost,
    isMulti,
    loading,
    className,
    updateSearchUrls,
    skip,
  } = props;

  const discoverProgramTagRef = useQuery(DISCOVER_PROGRAM_TAGS, {
    fetchPolicy: 'cache-and-network',
    skip,
    variables: getParamsFromUrl(props, isHost),
  });

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : (v = []) => {
          updateSearchUrls({ programTagIds: v.map(({ value }) => value) });
        };

  if (discoverProgramTagRef.error) return <Error error={discoverProgramTagRef.error} />;

  const { programTags = [] } = discoverProgramTagRef.data?.discoverProgramTags ?? {};
  const programTagOptions = programTags.map(({ _id, name }) => ({ label: name, value: _id }));

  return (
    <FilterSelect
      short
      shadow
      labelSingular="Tag"
      labelPlural="Tags"
      options={programTagOptions}
      value={programTagOptions.filter(({ value }) => programTagIds.includes(value)) || null}
      onChange={onChange}
      disabled={disabled || loading}
      multi={isMulti}
      className={className}
    />
  );
};

ProgramTagFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  programTagIds: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  isHost: PropTypes.bool,
  skip: PropTypes.bool,
};

ProgramTagFilter.defaultProps = {
  onSelect: false,
  loading: false,
  disabled: false,
  isMulti: false,
  programTagIds: [],
  className: '',
  isHost: false,
  skip: false,
};

export default withPropsFromUrl()(ProgramTagFilter);
