import React, { type ComponentProps } from 'react';
import { Text, type ITextProps } from '@rivallapp/volosports-components';
import { Checkbox as FancyCheckbox } from '../fancyFieldsV2';
import { VPTermsAndConditionsPopup } from '.';

type Props = {
  /**
   * Checkbox value - on or off (true of false)
   */
  value: boolean;
  /**
   * onChange function to handle checkbox value change
   */
  onChange: () => void;
  /**
   * Text to display before the link (include space if needed)
   */
  preText?: string;
  /**
   * Clickable text that opens the Volo Pass terms and conditions popup
   * @default 'Terms and Conditions'
   */
  linkText?: string;
  /**
   * Text to display after the link (include space if needed)
   */
  postText?: string;
  /**
   * `fancyFieldsV2` checkbox props. Pass in directly.
   * @example <VPTermsAndConditionsCheckbox nolabel style={{ margin: 3 }} />
   */
  checkboxProps: Omit<ComponentProps<typeof FancyCheckbox>, 'value' | 'onChange'>;
  /**
   * Custom text props. Pass in via object.
   * @example <VPTermsAndConditionsCheckbox _text={{ fontSize: 'xl', bold: true }} />
   */
  _text: ITextProps;
};

const VPTermsAndConditionsCheckbox: React.FC<Props> = ({
  value,
  onChange,
  preText = '',
  linkText,
  postText = '',
  _text = {},
  ...checkboxProps
}) => {
  // We want to remove the color prop for the link so that it remains a light blue if a color is passed in
  const { color: _color, ..._textPropsWithoutColor } = _text;

  return (
    <>
      <FancyCheckbox noLabel value={value} onChange={onChange} {...checkboxProps} />

      {/* We can't nest the popup within the first text element because it messes up the formatting of the ToC */}
      {/* Using `span` gives us better wrapping behavior than native base components */}
      <span>
        {preText ? (
          <Text fontSize="14px" lineHeight="sm" {..._text}>
            {preText}
          </Text>
        ) : null}

        <VPTermsAndConditionsPopup linkText={linkText} {..._textPropsWithoutColor} />

        {postText ? (
          <Text fontSize="14px" lineHeight="sm" {..._text}>
            {postText}
          </Text>
        ) : null}
      </span>
    </>
  );
};

export default VPTermsAndConditionsCheckbox;
