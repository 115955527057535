import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Password from './Password';
import Privacy from './Privacy';
import Profile from './Profile';
import styles from './styles.module.scss';
import password from '../../images/icons/door-lock.svg';
import lock from '../../images/icons/lock.svg';
import profile from '../../images/icons/user.svg';

const cx = classNames.bind(styles);

const GeneralAccountSettings = props => {
  const { close } = props;

  const [whichForm, setWhichForm] = useState(null);

  return (
    <div className="bg-white p-4 text-center">
      {(() => {
        switch (whichForm) {
          case 'profile':
            return (
              <span>
                <Profile {...props} cancel={() => setWhichForm(null)} />
              </span>
            );
          case 'password':
            return (
              <span>
                <div className={cx('form-header')}>
                  <img src={password} alt="change-password" />
                </div>
                <Password {...props} cancel={() => setWhichForm(null)} />
              </span>
            );
          case 'privacy':
            return (
              <span>
                <div className={cx('form-header')}>
                  <img src={lock} alt="file" />
                </div>
                <Privacy {...props} cancel={() => setWhichForm(null)} />
              </span>
            );
          default:
            return (
              <span>
                <div className={cx('other-forms')}>
                  <a role="link" tabIndex={0} onClick={() => setWhichForm('profile')}>
                    <span>
                      <img src={profile} alt="profile" />
                    </span>
                    <p>Edit Profile</p>
                  </a>
                  <a role="link" tabIndex={0} onClick={() => setWhichForm('password')}>
                    <span>
                      <img src={password} alt="password" />
                    </span>
                    <p>Change Password</p>
                  </a>
                  <a role="link" tabIndex={0} onClick={() => setWhichForm('privacy')}>
                    <span>
                      <img src={lock} alt="lock" />
                    </span>
                    <p>Privacy Settings</p>
                  </a>
                </div>
                {close ? (
                  <a
                    role="link"
                    tabIndex={0}
                    className={cx('close-button')}
                    style={{ marginTop: '25px' }}
                    onClick={close}
                  >
                    Close
                  </a>
                ) : null}
              </span>
            );
        }
      })()}
    </div>
  );
};

GeneralAccountSettings.propTypes = {
  close: PropTypes.func,
};

GeneralAccountSettings.defaultProps = {
  close: null,
};

export default GeneralAccountSettings;
