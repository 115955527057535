import { ProgramGenderEnum } from './gender-enum';

export const emptyHero = Object.freeze({
  heroText: '',
  heroSubText: '',
  heroImage: {
    image: {
      url: '',
      name: '',
    },
  },
  heroCta: {
    text: '',
    url: '',
    newTab: false,
  },
});

const emptyImage = {
  url: '',
  name: '',
};

const totals = {
  total: 0,
  male: 0,
  female: 0,
};

export const emptyFormat = {
  gender: ProgramGenderEnum.COED,
  format: 'N/A',
  minTeamSize: totals,
  minPlayersPerGame: totals,
  maxPlayersOnField: totals,
  description: '',
  formatImage: emptyImage,
};

export const emptyCityHero = Object.freeze({
  slideImage: '',
  slideText: '',
  slideSubText: '',
  slideButtonText: '',
  slideLink: '',
  newTab: false,
  secondarySlideButtonText: '',
  secondarySlideLink: '',
  secondaryNewTab: false,
});

export const emptySponsorLogo = Object.freeze({
  sponsorLogo: '',
  sponsorName: '',
  sponsorLink: '',
});
