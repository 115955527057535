import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { devtools } from 'zustand/middleware';
import { querystring } from 'zustand-querystring';
import initialShiftLogsFiltersStore, {
  type IShiftLogsFiltersStore,
} from './initialShiftLogsFiltersStore';
import { isProduction } from '../../environment';

/* 
  Note: url query string generated with the middleware
  has different url formatting intentionally.
  
  This is helpful because the middleware won't affect
  existing search params outside of this store (in this case: organization)

  https://github.com/nitedani/zustand-querystring/issues/1
*/

const useShiftLogsFiltersStore = createWithEqualityFn<IShiftLogsFiltersStore>()(
  querystring(
    devtools(() => initialShiftLogsFiltersStore, { enabled: !isProduction }),
    {
      // this makes it so that the query string is added only at the given pathname
      select(pathname) {
        // create an object with the same keys as the store
        // and the values are all `pathname === '/rfo/shift-logs/`
        const storePathnameConfig = Object.fromEntries(
          Object.keys(initialShiftLogsFiltersStore).map(key => [
            key,
            pathname === '/rfo/shift-logs',
          ])
        );

        return storePathnameConfig;
      },
    }
  ),
  shallow
);

export default useShiftLogsFiltersStore;
