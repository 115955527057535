import type React from 'react';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {
  Colors,
  FontAwesomeIcon,
  IconButton,
  Row,
  Text,
  type ITextProps,
} from '@rivallapp/volosports-components';
import useShiftLogsFiltersStore from '../../../../../../../zustand-stores/ShiftLogsFiltersStore';
import { updateShiftLogFilters } from '../../../../../../../zustand-stores/ShiftLogsFiltersStore/actions';
import ShiftLogListColumn from './ShiftLogListColumn';

const HeaderText: React.FC<ITextProps> = props => (
  <Text color={Colors.white} fontWeight="bold" fontSize="sm" numberOfLines={1} {...props} />
);

const ShiftLogsListHeader = () => {
  const sortOrder = useShiftLogsFiltersStore(store => store.sort_order);

  const handleSortOrderPress = () => {
    if (sortOrder === 'ASCENDING') {
      updateShiftLogFilters('sort_order', 'DESCENDING');
    } else if (sortOrder === 'DESCENDING') {
      updateShiftLogFilters('sort_order', 'ASCENDING');
    }
  };

  return (
    <Row space={2} bgColor={Colors.greys.dark} px={7} py={5} borderTopRadius="2xl">
      <ShiftLogListColumn type="submitter">
        <HeaderText>Submitter</HeaderText>
      </ShiftLogListColumn>

      <ShiftLogListColumn type="submission">
        <HeaderText>
          Submission{' '}
          <IconButton
            colorScheme="white"
            borderRadius="full"
            size="xs"
            onPress={handleSortOrderPress}
            icon={
              <FontAwesomeIcon icon={sortOrder === 'DESCENDING' ? faChevronDown : faChevronUp} />
            }
          />
        </HeaderText>
      </ShiftLogListColumn>

      <ShiftLogListColumn type="day">
        <HeaderText>Day</HeaderText>
      </ShiftLogListColumn>

      <ShiftLogListColumn type="sport">
        <HeaderText>Sport(s)</HeaderText>
      </ShiftLogListColumn>

      <ShiftLogListColumn type="venue">
        <HeaderText>Venue</HeaderText>
      </ShiftLogListColumn>

      <ShiftLogListColumn type="rating">
        <HeaderText>Rating</HeaderText>
      </ShiftLogListColumn>

      {/* empty views to keep flex sizing consistent */}
      <ShiftLogListColumn type="icons" />
      <ShiftLogListColumn type="chevron" />
    </Row>
  );
};
export default ShiftLogsListHeader;
