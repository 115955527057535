import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { IconButton, FontAwesomeIcon, Modal, Text, View } from '@rivallapp/volosports-components';
import { faEnvelopeDot } from '@fortawesome/pro-solid-svg-icons/faEnvelopeDot';
import { Button, Error, Loading, PrintWaiverIconButton } from '../../../components';
import {
  SEND_RENTAL_WAIVER_REMINDER_EMAIL,
  SEND_WAIVER_REMINDER_EMAIL,
} from '../PLAYER_DETAILS_MUTATIONS';
import { WAIVER_QUERY } from '../PLAYER_DETAILS_QUERIES';
import { useAlertMessage } from '../../../hooks';

const RemindIcon = <FontAwesomeIcon icon={faEnvelopeDot} />;

const WaiverAction = props => {
  const {
    date_signed,
    refetch,
    registrant_id,
    reminders_sent,
    waiver_id,
    isRental,
    userDetails: { firstName, lastName },
  } = props;
  const { showError, showSuccess } = useAlertMessage();
  const [hidePopup, setHidePopup] = useState(true);
  const [sendReminderMutation] = useMutation(SEND_WAIVER_REMINDER_EMAIL);
  const [sendReminderRentalMutation] = useMutation(SEND_RENTAL_WAIVER_REMINDER_EMAIL);
  const {
    data = {},
    loading,
    error,
  } = useQuery(WAIVER_QUERY, {
    skip: !waiver_id,
    fetchPolicy: 'network-only',
    variables: { _id: waiver_id },
  });
  const sendReminder = useCallback(async () => {
    try {
      if (isRental) {
        await sendReminderRentalMutation({
          variables: { input: { _id: registrant_id } },
        });
      } else {
        await sendReminderMutation({
          variables: { input: { _id: registrant_id } },
        });
      }
      showSuccess('Reminder email sent!');
      if (refetch) await refetch();
      setHidePopup(true);
    } catch (e) {
      showError(e);
    }
  }, [
    isRental,
    showSuccess,
    refetch,
    sendReminderRentalMutation,
    registrant_id,
    sendReminderMutation,
    showError,
  ]);

  if (loading) return <Loading type="inline" />;
  if (error) return <Error error={error} />;

  const { waiver = {} } = data;
  const { name } = waiver;
  return (
    <>
      <Modal useRNModal size="lg" isOpen={!hidePopup} onClose={() => setHidePopup(true)}>
        <Modal.Content>
          <Modal.Header>
            Please confirm action
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Text fontSize="sm">
              Are you sure you want to send a reminder email to{' '}
              <Text fontSize="sm" bold>
                {firstName} {lastName}
              </Text>{' '}
              for signing{' '}
              <Text fontSize="sm" bold>
                {name}
              </Text>
              ?
            </Text>
            <View borderRadius={1} py={4} my={6} bgColor="white.800" textAlign="center">
              <Text bold fontSize="sm">
                Reminders sent previously ({reminders_sent?.length})
              </Text>
              {reminders_sent?.map(reminder => {
                const { date_sent, sent_by } = reminder;
                return (
                  <Text key={`${new Date().toISOString()}-${sent_by}-${date_sent}`} fontSize="sm">
                    {new Date(date_sent).toLocaleDateString()} by {sent_by}
                  </Text>
                );
              })}
            </View>
          </Modal.Body>

          <Modal.Footer>
            <Button className="mx-2" small grey onClick={() => setHidePopup(true)}>
              Cancel
            </Button>
            <Button className="mx-2" small action onClick={sendReminder}>
              Send
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      {date_signed ? (
        <PrintWaiverIconButton
          size="sm"
          backgroundColor="transparent"
          waiverId={waiver_id}
          dateSigned={date_signed}
          fullName={`${firstName} ${lastName}`}
        />
      ) : (
        <IconButton
          size="sm"
          variant="ghost"
          icon={RemindIcon}
          backgroundColor="transparent"
          onPress={() => setHidePopup(false)}
        />
      )}
    </>
  );
};
WaiverAction.propTypes = {
  refetch: PropTypes.func,
  registrant_id: PropTypes.string.isRequired,
  date_signed: PropTypes.instanceOf(Date),
  waiver_id: PropTypes.string,
  reminders_sent: PropTypes.arrayOf(
    PropTypes.shape({
      date_sent: PropTypes.instanceOf(Date),
      sent_by: PropTypes.string,
    })
  ),
  userDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  isRental: PropTypes.bool,
};
WaiverAction.defaultProps = {
  refetch: null,
  date_signed: null,
  reminders_sent: [],
  waiver_id: undefined,
  userDetails: {
    firstName: '',
    lastName: '',
  },
  isRental: false,
};
export default WaiverAction;
