import { gql } from '@apollo/client';

export default gql`
  query leagueContact($leagueId: ID!) {
    league(_id: $leagueId) {
      _id
      contact {
        name
        email
        phoneNumber
        textable
      }
    }
  }
`;
