import * as _ from 'lodash-es';
import React from 'react';
import PropTypes from 'prop-types';
import beer from '../../../images/icons/beer.svg';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const LeagueSponsorBar = ({ league, style }) => {
  const sponsor_bar = _.get(league, 'details.sponsor_bar') || _.get(league, 'sponsor_bar');

  if (!sponsor_bar || !_.get(sponsor_bar, 'name')) return <span />;

  return (
    <div className={cx('info-container')} style={style}>
      <img className={cx('icon')} src={beer} alt="beer" />
      <p className={cx('title')}>Sponsor Bar</p>
      <p>{_.get(sponsor_bar, 'name')}</p>
      <p>{_.get(sponsor_bar, 'formatted_address')}</p>
    </div>
  );
};

LeagueSponsorBar.propTypes = {
  league: PropTypes.shape({
    sponsor_bar: PropTypes.shape({
      name: PropTypes.string,
      formatted_address: PropTypes.string,
    }),
    details: PropTypes.shape({
      sponsor_bar: PropTypes.shape({
        name: PropTypes.string,
        formatted_address: PropTypes.string,
      }),
    }),
  }).isRequired,
  style: PropTypes.shape({}),
};

LeagueSponsorBar.defaultProps = {
  style: {},
};

export default LeagueSponsorBar;
