import { gql } from '@apollo/client';

export const GET_SCHEDULE_DETAILS = gql`
  query getScheduleDetails($leagueId: ID!) {
    getScheduleDetails(leagueId: $leagueId) {
      league {
        _id
        teams_approved
        schedule_approved
        organizationId
        start_date
        days_of_week_estimate
        has_tournament
        num_weeks_estimate
        num_playoff_weeks_estimate
        start_time_estimate
        end_time_estimate
        timezone
        tournament {
          _id
        }
        venue {
          _id
          neighborhood
          name
          formatted_address
          city
          zip
          timezone
        }
        details {
          week_tags
        }
      }
      schedules {
        _id
        start_date
        days_of_week
        has_tournament
        num_games_per_team_per_week
        num_tournament_weeks
        num_weeks
        game_length_in_minutes
        startTimeStr
        endTimeStr
        timezone
      }
      weeks {
        _id
        week_num
        is_tournament
        notes
        weekTag
        byes
      }
      days {
        _id
        date
        weekId
        is_tournament
      }
      games {
        _id
        dayId
        field_name
        start_time
        teamIds
        location {
          name
        }
        scores {
          team_id
          forfeit
          score
        }
      }
      teams {
        _id
        name
        isVoloPassTeam
        captainId
        color {
          hex
          name
        }
      }
    }
  }
`;

export default gql`
  query league($leagueId: ID!, $input: ScheduleFilterInput) {
    league(_id: $leagueId) {
      _id
      name
      teams_approved
      schedule_approved
      has_tournament
      num_weeks_estimate
      num_playoff_weeks_estimate
      days_of_week_estimate
      start_time_estimate
      end_time_estimate
      start_date
      startDateStr
      timezone
      weeks {
        _id
        notes
        weekTag
        week_num
        byes
      }
      days {
        _id
        weekId
        date
        is_tournament
        games {
          _id
          start_time
          is_tournament
          scores {
            team_id
            forfeit
            score
            _id
          }
          location {
            _id
            name
            timezone
          }
          field_name
          weekId
          winner
          forfeitter
          dayId
          teamIds
        }
      }
      teams {
        _id
        name
        isVoloPassTeam
        color {
          hex
          name
        }
        captainId
        freeAgentIds
        groupIds
        hostNotes
      }
      details {
        week_descriptions
        week_tags
      }
      tournament {
        _id
      }
      schedules {
        _id
        start_date
        num_weeks
        num_tournament_weeks
        has_tournament
        days_of_week
        startTimeStr
        endTimeStr
        timezone
        game_length_in_minutes
        num_simultaneous_games
        num_games_per_team_per_week
        weeks {
          _id
          notes
          weekTag
          week_num
          byes
        }
        games {
          _id
          start_time
          is_tournament
          scores {
            team_id
            forfeit
            score
            _id
          }
          location {
            _id
            name
            timezone
          }
          field_name
          weekId
          winner
          forfeitter
          dayId
          teamIds
        }
        days {
          _id
          weekId
          date
          is_tournament
        }
      }
    }
  }
`;

export const EDIT_GAME_SCORES = gql`
  mutation gameEditScore($input: GameEditScoreInput!) {
    gameEditScore(input: $input) {
      league {
        _id
      }
    }
  }
`;
