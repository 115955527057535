import * as _ from 'lodash-es';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useQuery } from '@apollo/client';
import { Popup, Loading } from '../../../../../components';
import { VP_ORG_TIERS_QUERY } from '../../../../../hooks/useVoloPassDetails/QUERIES';
import {
  StyledButton,
  ParagraphText,
  SubTitleText,
  fonts,
  fontWeights,
  fontSizes,
  colors,
} from '../../../../../components/styledComponents';
import VoloPassPerksChecklist from './VoloPassPerksChecklist';
import CitySelection from './CitySelection';
import VoloPassCheckout from './active_city/VoloPassCheckout';

const COMING_SOON_TO_YOUR_CITY = 'COMING SOON TO YOUR CITY!';
const unavailableDescription = cityName =>
  `Membership is not yet available in ${cityName}, but join our waitlist to get realtime updates on when we launch in your city!`;
const JOIN_WAITLIST = 'Join Waitlist';

const BECOME_A_MEMBER = 'BECOME A MEMBER';
const SELECT_YOUR_CITY = 'Select your city';

const selectStyles = {
  control: baseStyles => ({
    ...baseStyles,
    textAlign: 'left',
  }),
};

const CitySelectDiv = styled.div`
  && {
    margin-bottom: 10px;
  }
`;

const SignUpModal = ({ showSignUpModal, setShowSignUpModal, initAnnual, refetch }) => {
  const { data, loading, error } = useQuery(VP_ORG_TIERS_QUERY);
  const { organizationList = [] } = data || {};
  const [cities, setCities] = useState([]);

  const [cityIsActive, setCityIsActive] = useState('');
  const [selectedCity, setSelectedCity] = useState({});

  useEffect(() => {
    if (data?.organizationList) {
      setCities(data?.organizationList);
    }
  }, [data]);

  useEffect(() => {
    setCityIsActive(selectedCity?.voloPassTiers?.isActive);
  }, [selectedCity]);

  const cityOptions = organizationList.map(org => ({ value: org._id, label: org.name }));

  if (loading) return <Loading />;

  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  const onChange = selectedOption => {
    setSelectedCity(organizationList.find(org => selectedOption.value === org._id));
  };

  const defaultValue = { value: selectedCity._id, label: selectedCity.name };

  return (
    <Popup
      className="widest"
      hidden={!showSignUpModal}
      close={() => {
        setShowSignUpModal(false);
        setSelectedCity({});
      }}
    >
      {!_.isEmpty(selectedCity) && (
        <div className="row">
          <div className="col-12 col-md-6">
            <VoloPassPerksChecklist />
          </div>
          <div className="col-12 col-md-6">
            <SubTitleText
              fontColor={colors.mediumBlue}
              fontFamily={fonts.MONTSERRAT}
              fontWeight={fontWeights.bold}
              fontSize={fontSizes.large}
              letterSpacing="-2px"
            >
              {cityIsActive ? BECOME_A_MEMBER : COMING_SOON_TO_YOUR_CITY}
            </SubTitleText>
            <CitySelectDiv>
              <ParagraphText
                fontColor={colors.darkBlue}
                fontWeight={fontWeights.bold}
                fontFamily={fonts.OPEN_SANS}
                lineHeight={fontSizes.small}
                fontSize="16px"
                textAlign="left"
                marginBottom="2px"
              >
                {SELECT_YOUR_CITY}
              </ParagraphText>
              <Select
                options={cityOptions}
                styles={selectStyles}
                defaultValue={defaultValue}
                onChange={onChange}
              />
            </CitySelectDiv>
            {cityIsActive ? (
              <VoloPassCheckout
                refetch={refetch}
                selectedCity={selectedCity}
                setShowSignUpModal={setShowSignUpModal}
                initAnnual={initAnnual}
              />
            ) : (
              <>
                <ParagraphText
                  fontFamily={fonts.OPEN_SANS}
                  fontWeight={fontWeights.regular}
                  fontColor={colors.black}
                  fontSize="20px"
                  lineHeight="25px"
                  marginBottom="20px"
                  marginTop="15px"
                >
                  {unavailableDescription(selectedCity?.name)}
                </ParagraphText>
                <a
                  href="https://forms.gle/gzWSgiSQdpReQbJRA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledButton backgroundColor={colors.mediumBlue} textColor={colors.white}>
                    {JOIN_WAITLIST}
                  </StyledButton>
                </a>
              </>
            )}
          </div>
        </div>
      )}
      {_.isEmpty(selectedCity) && (
        <CitySelection cities={cities} setSelectedCity={setSelectedCity} />
      )}
    </Popup>
  );
};

SignUpModal.propTypes = {
  setShowSignUpModal: PropTypes.func.isRequired,
  showSignUpModal: PropTypes.bool.isRequired,
  initAnnual: PropTypes.bool.isRequired,
  refetch: PropTypes.func,
};

SignUpModal.defaultProps = {
  refetch: null,
};

export default SignUpModal;
