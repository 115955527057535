import { graphql } from '@apollo/client/react/hoc';
import * as _ from 'lodash-es';
import moment from 'moment-timezone';
import { branch, compose, setDisplayName, withHandlers, withProps, withState } from 'recompose';
import LEAGUE_TOURNAMENT_QUERY, { UPDATE_TOURNAMENT_DATE } from './LEAGUE_TOURNAMENT_QUERY';
import ManageTournamentPresenter from './ManageTournamentPresenter';
import { Error, Loading } from '../../../../components';
import withActionStates from '../../../../hocs/withActionStates';

export default compose(
  setDisplayName('ManageTournamentContainer'),
  withActionStates({ withAlerts: true }),
  graphql(LEAGUE_TOURNAMENT_QUERY, {
    skip: ({ league }) => !league?._id,
    options: ({ league }) => ({
      fetchPolicy: 'network-only',
      variables: {
        leagueId: league?._id,
      },
    }),
    props: ({ data: { loading, league, refetch, error } }) => {
      // Use index 0 as we can *currently* only have 1 tournament
      const hasTournament = league?.tournament?.length;
      return {
        loading,
        tournament: hasTournament ? league.tournament[0] : {},
        games: hasTournament ? league.tournament[0].games : [],
        teams: league?.teams || [],
        error,
        refetch,
      };
    },
  }),
  graphql(UPDATE_TOURNAMENT_DATE, { name: 'updateTournamentMutation' }),
  branch(
    ({ error }) => error,
    // `error` is actually in the props as seen above, but the linter doesn't recognize it for some reason 🤷🏻‍♂️
    // eslint-disable-next-line react/prop-types
    () => props => <Error error={`Error fetching tournament info: ${props?.error?.message}`} />
  ),
  branch(
    ({ loading }) => loading,
    () => () => <Loading className="page-loader" message="Fetching tournament..." />
  ),
  withProps(({ games }) => {
    const gameRounds = _.toArray(_.groupBy(games, 'tournament_round'));
    const numOfRounds = gameRounds?.length;
    const finalRounds = gameRounds.map((roundGames, roundIdx) => {
      let name = `Round ${roundIdx}`;
      if (roundIdx === numOfRounds - 1) {
        name = 'Finals';
      } else if (roundIdx === numOfRounds - 2) {
        name = 'Semifinals';
      }
      return { games: roundGames, name };
    });

    return { rounds: finalRounds };
  }),
  withState('editingTournamentScore', 'setEditingTournamentScore', null),
  withState('editingTournamentGame', 'setEditingTournamentGame', null),
  withHandlers({
    editTournamentGame: props => game => {
      const { teams, setEditingTournamentGame } = props;
      let editingTournamentGame;
      if (game?._id) {
        const teamIds = game?.teamIds;
        editingTournamentGame = {
          game,
          teams: teams.filter(({ _id }) => teamIds.includes(_id)),
        };
      }
      setEditingTournamentGame(editingTournamentGame);
    },
    editTournamentScore: props => game => {
      const { teams, setEditingTournamentScore } = props;
      let editingTournamentScore;
      if (game?._id) {
        const teamIds = game?.teamIds ?? [];
        let scores = game?.scores;
        if (!scores?.length) {
          scores = teamIds.map(teamId => ({
            team_id: teamId,
            score: 0,
            forfeit: false,
          }));
        }
        editingTournamentScore = {
          game: { ...game, scores },
          teams: teams.filter(({ _id }) => teamIds.includes(_id)),
        };
      }
      setEditingTournamentScore(editingTournamentScore);
    },
    updateDate:
      props =>
      async ({ date, tournament_round }) => {
        const { updateTournamentMutation, tournament, setError, setSuccess, refetch } = props;
        try {
          if (!date || !tournament_round) {
            throw new Error('Missing date or round # to update.');
          }
          await updateTournamentMutation({
            variables: {
              input: {
                leagueId: tournament.league_id,
                tournamentId: tournament._id,
                date: moment(date).toISOString(),
                tournament_round,
              },
            },
          });
          await refetch();
          setSuccess('Updated tournament.');
        } catch (e) {
          setError(e);
        }
      },
  })
)(ManageTournamentPresenter);
