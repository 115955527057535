export const orderBy = ({ collection, key = '' }) => {
  const entries = Object.values(collection);
  return entries.sort((a, b) => {
    if (a[key] > b[key]) {
      return 1;
    }
    if (b[key] > a[key]) {
      return -1;
    }
    return 0;
  });
};

export const pickBy = ({ collection, keys = [] }) => {
  const obj = {};
  const entries = Object.entries(collection);
  for (let i = 0, len = entries.length; i < len; i += 1) {
    const [currKey, currValue] = entries[i];
    if (keys.includes(currKey)) {
      obj[currKey] = currValue;
    }
  }
  return obj;
};
