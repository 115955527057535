/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { sanitize } from 'isomorphic-dompurify';
import cup from '../../../images/icons/cup.svg';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const LeagueDetails = ({ league, style, minify }) => {
  let description = league?.profile?.description || league?.details?.description || '';

  if (minify) {
    description = description.length > 150 ? `${description.slice(0, 150)}...` : description;
  }

  description = sanitize(description);

  return (
    <div className={cx('info-container')} style={style}>
      <img className={cx('icon')} src={cup} alt="cup" />
      <p className={cx('title')}>The Gist</p>
      <div className={cx('gist')} dangerouslySetInnerHTML={{ __html: description ?? '' }} />
      <p className={cx('safety')}>
        COVID safety protocols may be implemented at any point during the season as required by
        venue, city or state mandate. Credits will be issued for any COVID-19 related league
        cancellations.
      </p>
    </div>
  );
};

LeagueDetails.propTypes = {
  league: PropTypes.shape({
    profile: PropTypes.shape({
      description: PropTypes.string,
    }),
    details: PropTypes.shape({
      description: PropTypes.string,
    }),
  }).isRequired,
  style: PropTypes.shape({}),
  minify: PropTypes.bool,
};

LeagueDetails.defaultProps = {
  style: {},
  minify: false,
};

export default LeagueDetails;
