import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import star from '../../../images/icons/gold-star-2.svg';
import styles from '../player-details.module.scss';
import { capitalize } from '../../../utils';
import { PlayerInfoEdit, PlayerInfoDisplay } from './components';

import Picture from '../../../components/Picture/Picture';
import { Tooltip } from '../../../components';

const cx = classNames.bind(styles);

const PlayerInfo = props => {
  const { rating, userDetails, updatingInfo } = props;

  const {
    _id,
    firstName,
    lastName,
    picture,
    status,
    deactivated,
    isVoloPassMember,
    membershipExpires,
  } = userDetails;
  return (
    <div className="col-12 col-md-2" style={{ borderRight: '1px solid lightgray' }}>
      <div className="container">
        {picture ? (
          <Picture className={cx('profilePic')} src={picture} alt="profile pic" />
        ) : (
          <span className={cx('defaultImg')}>
            {firstName?.charAt(0)} {lastName?.charAt(0)}
          </span>
        )}
        <div className="mb-3">
          <div
            className={cx(
              'editName',
              deactivated ? 'text-volo-danger font-italic' : 'text-volo-primary'
            )}
          >
            {firstName} {lastName}
          </div>
          {deactivated && <small className="d-block">Player deactivated</small>}
          <span className={cx(status, 'mr-3')}>{capitalize(status)}</span>
          {rating} <img src={star} className={cx('star')} alt="star" />
          <span className={cx('isMember')}>
            {isVoloPassMember && (
              <span
                data-for={_id}
                data-tip={`Expires ${moment(+membershipExpires).format('MM/DD/YY')}`}
                className={cx('isMember')}
              >
                <Picture
                  src="https://rivall-public.s3-us-west-1.amazonaws.com/home-page/VoloPass.png"
                  alt="volo pass"
                />
                <Tooltip id={_id} />
              </span>
            )}
          </span>
        </div>
        <div className={cx('editInfo')}>
          {updatingInfo ? (
            <PlayerInfoEdit {...{ ...userDetails, ...props }} />
          ) : (
            <PlayerInfoDisplay {...{ ...userDetails, ...props }} />
          )}
        </div>
      </div>
    </div>
  );
};

PlayerInfo.propTypes = {
  rating: PropTypes.string.isRequired,
  updatingInfo: PropTypes.bool.isRequired,
  userDetails: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    picture: PropTypes.string,
    status: PropTypes.string,
    deactivated: PropTypes.bool,
    isVoloPassMember: PropTypes.bool,
    membershipExpires: PropTypes.string,
  }),
};

PlayerInfo.defaultProps = {
  userDetails: {},
};

export default PlayerInfo;
