import React from 'react';
import styled from 'styled-components';

const SelectACity = styled.div`
  && {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 3%;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    @media (min-width: 575.98px) {
      .content {
        .card {
          width: 22rem;
          height: 3rem;
        }
      }
    }
  }
`;

const Header = styled.h2`
  && {
    font-size: 1rem;
    color: $volo-primary;
  }
`;

const Content = styled.div`
  && {
    overflow-y: auto;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Card = styled.button`
  && {
    align-items: center;
    background-color: #e9f4fc;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px #808080;
    color: #0a3355;
    display: flex;
    font-size: 20px;
    height: 2.5rem;
    justify-content: center;
    margin: 0.5rem;
    padding: 5px;
    width: 15rem;
    border: 0;
    transition: all 0.5s;
    text-transform: none;
    .cityName {
      text-align: center;
    }
    &:hover,
    &:focus {
      background: #0a3355;
      color: #e9f4fc;
      cursor: pointer;
      height: 4rem;
      opacity: 1;

      .city-name-button {
        color: #ffffff;
      }
    }
  }
`;

const CityName = styled.div`
  text-align: center;
`;

const CitySelection = ({ cities, setSelectedCity }) => (
  <SelectACity>
    <Header>Select Location</Header>
    <Content>
      {cities.map((city, i) => {
        const key = `city-${i}`;
        return (
          <Card type="button" tabIndex={0} key={key} onClick={() => setSelectedCity(city)}>
            <CityName>
              <h4 className="city-name-button">{city.name}</h4>
            </CityName>
          </Card>
        );
      })}
    </Content>
  </SelectACity>
);

export default CitySelection;
