export enum RentalRegistrantTypes {
  BOOKING_CAPTAIN = 'booking_captain',
  GUEST = 'guest',
}

export enum RentalCreationSteps {
  BASICS = 'basics',
  DETAILS = 'details',
  /** This value is used to give a better step count before sports have been selected */
  PLACEHOLDER = 'placeholder',
  REVIEW = 'review',
}

export enum PricingKeys {
  'standard_pricing' = 'Standard Pricing',
  'prime_pricing' = 'Prime Time Pricing',
  'vp_standard_pricing' = 'VP Standard Pricing',
  'vp_prime_pricing' = 'VP Prime Time Pricing',
}

type RentalRegistrantTypeOption = {
  label: string;
  value: RentalRegistrantTypes;
};

export const RentalRegistrantTypeOptions = Object.freeze({
  [RentalRegistrantTypes.BOOKING_CAPTAIN]: {
    label: 'Rental Booking Captain',
    value: RentalRegistrantTypes.BOOKING_CAPTAIN,
  },
  [RentalRegistrantTypes.GUEST]: {
    label: 'Rental Group Member',
    value: RentalRegistrantTypes.GUEST,
  },
} satisfies Record<RentalRegistrantTypes, RentalRegistrantTypeOption>);
