/* eslint-disable quote-props */
import * as _ from 'lodash-es';

export const ProgramTypesEnum = Object.freeze({
  LEAGUE: 'league',
  DROPIN: 'dropin',
  PRACTICE: 'practice',
  PICKUP: 'pickup',
  TOURNAMENTS: 'tournaments',
  CLINIC: 'Clinic',
  CLASS: 'Class',
  SERIES: 'Series',
});

export const CompetitionLevelOptions = Object.freeze([
  'Super Social',
  'Recreational',
  'Intermediate',
  'Upper Intermediate',
  'Competitive',
]);

export const AllCompetitionLevelsEnum = Object.freeze([
  // NON_DAILY
  'Super Social',
  'Recreational',
  'Intermediate',
  'Upper Intermediate',
  'Competitive',
  // DAILY
  'Beginner/Social',
  'Intermediate',
  'Advanced/Competitive',
]);

export const CompetitionLevelsEnum = Object.freeze({
  SUPER_SOCIAL: 'Super Social',
  RECREATIONAL: 'Recreational',
  INTERMEDIATE: 'Intermediate',
  UPPER_INTERMEDIATE: 'Upper Intermediate',
  COMPETITIVE: 'Competitive',
});

export const DailyCompetitionLevels = Object.freeze({
  'Super Social': 'Beginner/Social',
  Intermediate: 'Intermediate',
  Competitive: 'Advanced/Competitive',
});

export const DailySkillLevels = Object.freeze({
  [DailyCompetitionLevels['Super Social']]: {
    value: ['Super Social', 'Recreational'],
    label: DailyCompetitionLevels['Super Social'],
  },
  [DailyCompetitionLevels.Intermediate]: {
    value: ['Intermediate'],
    label: DailyCompetitionLevels.Intermediate,
  },
  [DailyCompetitionLevels.Competitive]: {
    value: ['Upper Intermediate', 'Competitive'],
    label: DailyCompetitionLevels.Competitive,
  },
});

export const getSkillLevelValues = (labels = []) => {
  const values = labels?.map(label => DailySkillLevels[label]?.value || '');
  const flattened = _.flattenDeep(values);
  return flattened;
};

export const getDefaultCompetitionLevels = isDaily =>
  _.map(isDaily ? DailyCompetitionLevels : CompetitionLevelsEnum, level => ({
    level,
    hidden: false,
    description: '',
  }));
