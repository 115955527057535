import { type FC, type ComponentProps } from 'react';
import { FontAwesomeIcon } from '@rivallapp/volosports-components';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';

type IconProps = ComponentProps<typeof FontAwesomeIcon>;

type WaiverStatusProps = Omit<IconProps, 'icon'> & {
  signed?: boolean | null | undefined;
};

const WaiverStatus: FC<WaiverStatusProps> = ({ signed = false, ...props }) =>
  signed ? (
    <FontAwesomeIcon
      icon={faCheck}
      size={5}
      color="success.500"
      _web={{ 'aria-label': 'signed' }}
      {...props}
    />
  ) : (
    <FontAwesomeIcon
      icon={faXmark}
      size={5}
      color="error.500"
      _web={{ 'aria-label': 'unsigned' }}
      {...props}
    />
  );

export default WaiverStatus;
