import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import PlayerDeactivate from './PlayerDeactivate';
import { Button, ConfirmButton, FilterSelect } from '../../../../components';
import { FancyField } from '../../../../components/fancyFieldsV2';
import { formatBday } from '../../../../utils';
import styles from '../../player-details.module.scss';

const cx = classNames.bind(styles);

const PlayerInfoEdit = props => {
  const {
    birthday,
    deactivated,
    email,
    firstName,
    gender,
    genderOptions,
    lastName,
    countryCode,
    phone_number,
    setUpdatingInfo,
    updateUserDetails,
    updateUserInfo,
    updating,
  } = props;

  const [showDeactivate, setShowDeactivate] = useState(false);

  return showDeactivate ? (
    <PlayerDeactivate
      {...{
        ...props,
        goBackAction: () => setShowDeactivate(false),
      }}
    />
  ) : (
    <React.Fragment>
      <FancyField
        grey
        noLabel
        persistPlaceholder
        value={firstName}
        type="text"
        onChange={e => updateUserDetails({ firstName: e })}
        placeholder="First Name"
        className="mb-3"
      />
      <FancyField
        grey
        noLabel
        persistPlaceholder
        value={lastName}
        type="text"
        onChange={e => updateUserDetails({ lastName: e })}
        placeholder="Last Name"
        className="mb-3"
      />
      <FancyField
        grey
        noLabel
        persistPlaceholder
        value={email}
        type="text"
        onChange={e => updateUserDetails({ email: e })}
        placeholder="Email"
        className="mb-3"
      />
      <PhoneInput
        placeholder="Phone"
        country={countryCode}
        value={phone_number}
        onChange={p => updateUserDetails({ phone_number: p || '' })}
        onCountryChange={c => updateUserDetails({ country: c || '' })}
        className={cx('phoneInput')}
      />
      <FancyField
        grey
        noLabel
        persistPlaceholder
        value={birthday}
        onChange={e => updateUserDetails({ birthday: e })}
        transform={formatBday}
        className="mb-3"
      />
      <FilterSelect
        border
        rounded
        maxMenuHeight="90px"
        placeholder="Select"
        options={genderOptions}
        value={genderOptions.find(({ value }) => value === gender) || null}
        onChange={({ value }) => updateUserDetails({ gender: value })}
        grey
        className="mb-3"
      />
      <div className={cx('d-flex justify-content-center mb-2 col')}>
        <a
          className="text-volo-danger font-italic"
          role="button"
          tabIndex={0}
          onClick={() => setShowDeactivate(true)}
        >
          {deactivated ? 'Reactivate User' : 'Deactivate User'}
        </a>
      </div>
      <div className={cx('d-flex justify-content-between')}>
        <Button link square className="col" onClick={() => setUpdatingInfo(false)}>
          Cancel
        </Button>
        <ConfirmButton
          onClick={updateUserInfo}
          disabled={updating}
          className="wide col"
          primary
          square
          large
        >
          {updating ? 'Saving...' : 'Save'}
        </ConfirmButton>
      </div>
      <br />
    </React.Fragment>
  );
};

PlayerInfoEdit.propTypes = {
  birthday: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  phone_number: PropTypes.string.isRequired,
  setUpdatingInfo: PropTypes.func.isRequired,
  updateUserDetails: PropTypes.func.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  updating: PropTypes.bool,
  deactivated: PropTypes.bool.isRequired,
  genderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      find: PropTypes.func,
    })
  ).isRequired,
};

PlayerInfoEdit.defaultProps = {
  updating: false,
};

export default PlayerInfoEdit;
