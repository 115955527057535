/* eslint-disable global-require */
export const accessClinics = require('./VPIcons/access-clinics-practices.svg');
export const accessDiscounts = require('./VPIcons/access-discounts.svg');
export const accessDropin = require('./VPIcons/access-dropin.svg');
export const accessFitness = require('./VPIcons/access-fitness.svg');
export const accessPickups = require('./VPIcons/access-pickups.svg');
export const accessPopups = require('./VPIcons/access-popups.svg');

export const perkDiscounts = require('./VPIcons/perk-discounts.svg');
export const perkMeet = require('./VPIcons/perk-meet-people.svg');
export const perkHassle = require('./VPIcons/perk-no-hassle.svg');
export const perkSchedule = require('./VPIcons/perk-schedule.svg');
export const perkTravel = require('./VPIcons/perk-travel.svg');

export const lightBlueCheckmark = require('./VPIcons/light-blue-checkmark.png');

export const hero = require('./VPImages/image-hero.png');
export const join = require('./VPImages/image-join-the-fun.png');
export const perks = require('./VPImages/image-perks-and-advantages.png');

export const voloPassLogo = require('./VPImages/VoloPass-assets.png');
export const whiteVoloPassLogo = require('./VPImages/White-VoloPassLogo.png');
export const pureWhiteVoloPassLogo = require('./VPImages/pure-white-VoloPassLogo.png');
export const smallVPLogo = require('./VPImages/vp_logo_small.png');

export const volleyballGirl = require('./VPImages/vball_girl.png');
export const vpBackground = require('./VPImages/vp_background.png');
export const vpMarketingImage1 = require('./VPImages/vp_marketing_image_1.png');
export const vpMarketingImage2 = require('./VPImages/vp_marketing_image_2.png');
export const vpMarketingImage3 = require('./VPImages/vp_marketing_image_3.png');
export const vpMarketingImage4 = require('./VPImages/vp_marketing_image_4.png');
