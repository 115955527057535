import React, { useState } from 'react';
import { Modal, Text, Button, type ITextProps } from '@rivallapp/volosports-components';
import termsAndConditions from './terms-and-conditions';

type Props = {
  linkText?: string;
} & ITextProps;

const VPTermsAndConditionsPopup: React.FC<Props> = ({
  linkText = 'Terms and Conditions',
  ...textProps
}) => {
  const [showTerms, setShowTerms] = useState(false);

  const handleClose = () => setShowTerms(false);

  return (
    <>
      <Button
        onPress={() => setShowTerms(true)}
        variant="link"
        _text={textProps}
        padding={0}
        height={0}
        display="inline-flex"
      >
        {linkText}
      </Button>
      <Modal isOpen={showTerms} onClose={handleClose} useRNModal>
        <Modal.Content maxW={950} width="95%">
          <Modal.CloseButton />
          <Modal.Header>Volo Pass Terms and Conditions</Modal.Header>
          <Modal.Body>
            <Text textAlign="left">{termsAndConditions}</Text>
          </Modal.Body>
          <Modal.Footer>
            <Button onPress={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default VPTermsAndConditionsPopup;
