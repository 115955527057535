import { useMutation } from '@apollo/client';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { CREATE_DAY_MUTATION } from '../../SCHEDULER_QUERIES_AND_MUTATIONS';
import styles from '../edit-schedule.module.scss';
import { Button, CalendarPicker } from '../../../../../../components';
import withActionStates from '../../../../../../hocs/withActionStates';
import { UseActionStatesProps } from '../../../../../../types';

const cx = classNames.bind(styles);

const AddWeekDay = props => {
  const { weekId, is_tournament, afterSave, hideText, setSuccess, setError } = props;

  const [selectedDate, setSelectedDate] = useState(moment());
  const [createDayMutation] = useMutation(CREATE_DAY_MUTATION);

  const onSave = async () => {
    try {
      const date = selectedDate.format('YYYY/MM/DD');
      const { data } = await createDayMutation({
        variables: { input: { weekId, date, isTournament: is_tournament } },
      });
      setSuccess('Created new day');
      afterSave(data.createDay._id);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <div className={cx('select-new-date')}>
      {!hideText && (
        <>
          <h2>Add Day</h2>
          <p>
            Select a new day in this week.
            <br />
            To add a day on a future date, click Add Week to extend the schedule.
          </p>
        </>
      )}
      <CalendarPicker
        moment={selectedDate}
        onChange={setSelectedDate}
        calendarOnly
        hideSave
        className={cx('game-date-picker')}
        style={{ margin: '0 auto' }}
      />
      <Button onClick={onSave}>Add Day</Button>
    </div>
  );
};

AddWeekDay.propTypes = {
  afterSave: PropTypes.func.isRequired,
  weekId: PropTypes.string.isRequired,
  is_tournament: PropTypes.bool.isRequired,
  hideText: PropTypes.bool,
  ...UseActionStatesProps,
};

AddWeekDay.defaultProps = {
  hideText: false,
};

export default withActionStates({ withAlerts: true })(AddWeekDay);
