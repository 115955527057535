import React from 'react';
import PropTypes from 'prop-types';

import {
  ParagraphText,
  StyledButton,
  colors,
  fontSizes,
  fontWeights,
  fonts,
} from '../../../../../../components/styledComponents';
import { formatPrice } from '../../../../../../utils';
import { MEMBERSHIP_MINIMUM_MONTHS } from '../../../../../../shared/membership-enums';

const CHOOSE_A_PLAN = 'Choose a plan';
const MINIMUM_NOTICE = `***${MEMBERSHIP_MINIMUM_MONTHS} month minimum required for monthly`;

const getMonthlyPlanText = monthlyPrice => `Monthly ${formatPrice(monthlyPrice)}/month`;

const getAnnualPlanText = (annualPrice, monthlyPrice) =>
  `Annual ${formatPrice(annualPrice)}/year (Save ${formatPrice(monthlyPrice * 12 - annualPrice)})`;

const ChoosePlan = ({ selectedCity, updatePlanDetails, planDetails }) => {
  const { monthlyDollarPrice, annualDollarPrice, monthlyPlanId, annualPlanId } =
    selectedCity.voloPassTiers;

  const { planId } = planDetails;

  const onClick = buttonPlanId => () => {
    updatePlanDetails({ planId: buttonPlanId });
  };

  return (
    <>
      <ParagraphText
        fontColor={colors.darkBlue}
        fontWeight={fontWeights.bold}
        fontFamily={fonts.OPEN_SANS}
        fontSize="16px"
        textAlign="left"
        marginBottom="2px"
      >
        {CHOOSE_A_PLAN}
      </ParagraphText>
      <StyledButton
        onClick={onClick(monthlyPlanId)}
        backgroundColor={planId === monthlyPlanId ? colors.darkBlue : colors.white}
        textColor={planId === monthlyPlanId ? colors.white : colors.darkBlue}
        width="100%"
        marginVertical="5px"
      >
        {getMonthlyPlanText(monthlyDollarPrice)}
      </StyledButton>
      <StyledButton
        onClick={onClick(annualPlanId)}
        backgroundColor={planId === annualPlanId ? colors.darkBlue : colors.white}
        textColor={planId === annualPlanId ? colors.white : colors.darkBlue}
        width="100%"
        marginVertical="5px"
      >
        {getAnnualPlanText(annualDollarPrice, monthlyDollarPrice)}
      </StyledButton>
      <ParagraphText
        fontColor={colors.darkBlue}
        fontWeight={fontWeights.bold}
        fontFamily={fonts.OPEN_SANS}
        lineHeight={fontSizes.xxsmall}
        fontSize={fontSizes.xxsmall}
        textAlign="left"
        marginBottom="10px"
      >
        {MINIMUM_NOTICE}
      </ParagraphText>
    </>
  );
};

ChoosePlan.propTypes = {
  updatePlanDetails: PropTypes.func.isRequired,
  selectedCity: PropTypes.shape({
    name: PropTypes.string.isRequired,
    voloPassTiers: PropTypes.shape({
      monthlyDollarPrice: PropTypes.number.isRequired,
      annualDollarPrice: PropTypes.number.isRequired,
      monthlyPlanId: PropTypes.string.isRequired,
      annualPlanId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  planDetails: PropTypes.shape({
    planId: PropTypes.string,
  }).isRequired,
};

export default ChoosePlan;
