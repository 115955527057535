/* eslint-disable global-require */
import PropTypes from 'prop-types';
import React from 'react';
import { getToday } from '../../../shared/date-helpers';
import { brand } from '../../../shared/colors-objects';

let DayPicker = () => <div />;
if (typeof window !== 'undefined') {
  DayPicker = require('react-day-picker');
  require('react-day-picker/lib/style.css');
}

const PermitCalendar = ({
  selectedDays,
  onDayClick,
  tempPermits = [],
  existingPermits = [],
  ...props
}) => {
  const modifiers = {
    loaded: tempPermits.map(({ start }) => new Date(start)),
    current: existingPermits.filter(d => new Date(d) >= getToday()),
    expired: existingPermits.filter(d => new Date(d) < getToday()),
    selected: selectedDays,
  };

  const modifiersStyles = {
    loaded: {
      color: 'black',
      backgroundColor: brand.grey200,
      fontWeight: '600',
    },
    current: {
      backgroundColor: '#f1fdf1',
    },
    expired: {
      backgroundColor: '#fdf1fd',
      fontWeight: '200',
    },
    selected: {
      color: brand.grey100,
      backgroundColor: brand.rivallblue,
      fontWeight: '600',
    },
  };

  return (
    <DayPicker
      {...{
        showOutsideDays: true,
        selectedDays,
        modifiers,
        modifiersStyles,
        onDayClick,
      }}
      {...props}
    />
  );
};

PermitCalendar.propTypes = {
  existingPermits: PropTypes.arrayOf(PropTypes.shape({})),
  tempPermits: PropTypes.arrayOf(PropTypes.shape({})),
  onDayClick: PropTypes.func.isRequired,
  selectedDays: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
};

PermitCalendar.defaultProps = {
  existingPermits: [],
  tempPermits: [],
  selectedDays: [],
};

export default PermitCalendar;
