import { useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styles from './league-forms.module.scss';
import LEAGUE_SHIRT_QUERY from './LEAGUE_SHIRT_QUERY';
import { Error, Loading } from '../../components';
import { Checkbox } from '../../components/fancyFieldsV2';
import { TeamProps } from '../../types';

const cx = classNames.bind(styles);

const TeamJerseys = ({ shirts, team, allowXxl }) => {
  const shirtAmounts = {
    s: 0,
    m: 0,
    l: 0,
    xl: 0,
    xxl: 0,
  };
  let totals = 0;

  shirts.map((shirt_size = 'l') => {
    if (!allowXxl && shirt_size === 'xxl') {
      shirtAmounts.xl += 1;
    } else {
      shirtAmounts[shirt_size] += 1;
    }
    totals += 1;
    return totals;
  });

  return (
    <tr>
      <td>{team.name}</td>
      <td>
        {team.color?.name || 'WARNING: No color'}
        {team.color?.hex && (
          <span className={cx('color-indicator')} style={{ backgroundColor: team.color.hex }} />
        )}
      </td>
      {Object.keys(shirtAmounts).map(amt => (
        <td key={amt}>{shirtAmounts[amt]}</td>
      ))}
      <td>{totals}</td>
    </tr>
  );
};

const ViewJerseyCount = ({ leagueId }) => {
  const [allowXxl, setAllowXxl] = useState(true);

  const { data, loading, error } = useQuery(LEAGUE_SHIRT_QUERY, {
    skip: !leagueId,
    fetchPolicy: 'network-only',
    variables: { leagueId },
  });
  if (loading) return <Loading />;
  if (error || !leagueId) return <Error error={error || 'No leagueId found'} />;

  const { league } = data;
  const { teamShirts, teams } = league.registrantObj.reduce(
    (acc, { isDropin, stagedTeam, player }) => {
      if (!stagedTeam || isDropin) return acc; // ignore dropins or free agents/groups not assigned to a team yet
      if (!acc.teamShirts[stagedTeam._id]) acc.teamShirts[stagedTeam._id] = [];
      acc.teamShirts[stagedTeam._id].push(player.shirt_size);
      acc.teams[stagedTeam._id] = stagedTeam;
      return acc;
    },
    { teamShirts: {}, teams: {} }
  );

  return (
    <div className={cx('jersey-container', 'container')}>
      <div className="row no-gutters justify-content-center">
        <div className="col-2">
          <Checkbox
            value={allowXxl}
            property="allowXxl"
            onChange={() => setAllowXxl(!allowXxl)}
            label="Offer XXL"
          />
        </div>
      </div>
      <table className={cx('jersey-table')}>
        <thead className={cx('jersey-table-title')}>
          <tr>
            <th colSpan="9">{league.name}</th>
          </tr>
        </thead>
        <tbody>
          <tr className={cx('jersey-table-title')}>
            <td>Team Name</td>
            <td>Color</td>
            <td>SM</td>
            <td>MD</td>
            <td>LG</td>
            <td>XL</td>
            <td>XXL</td>
            <td>Total</td>
          </tr>
          {Object.keys(teamShirts).map(teamId => (
            <TeamJerseys
              key={teamId}
              shirts={teamShirts[teamId]}
              team={teams[teamId]}
              allowXxl={allowXxl}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

TeamJerseys.propTypes = {
  allowXxl: PropTypes.bool.isRequired,
  team: TeamProps.isRequired,
  shirts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ViewJerseyCount.propTypes = {
  leagueId: PropTypes.string.isRequired,
};

export default ViewJerseyCount;
