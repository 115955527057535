import { View, type IViewProps } from '@rivallapp/volosports-components';
import React from 'react';

/**
 * Flex values for ShiftLogsList so that the header and all the rows are
 * spaced out and aligned exactly the same.
 */
const LogListFlexValues = {
  submitter: 1.5,
  submission: 1,
  day: 0.7,
  sport: 1,
  venue: 1.5,
  rating: 1,
  icons: 2,
  chevron: 0.1,
} as const;

type ShiftLogListColumnProps = IViewProps & {
  type: keyof typeof LogListFlexValues;
};

const ShiftLogListColumn: React.FC<ShiftLogListColumnProps> = ({ type, children, ...props }) => (
  <View flex={LogListFlexValues[type]} {...props}>
    {children}
  </View>
);

export default ShiftLogListColumn;
