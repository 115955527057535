import { type TypedDocumentNode, gql } from '@apollo/client';

type UserMembershipStatusResponse = {
  userIsActiveVoloPassMember: boolean;
  userHasPastVoloPassMembership: boolean;
  userIsActiveVoloFitnessMember: boolean;
  userHasPastVoloFitnessMembership: boolean;
};

const GET_USER_MEMBERSHIP_STATUS: TypedDocumentNode<UserMembershipStatusResponse> = gql`
  query getUserMembershipStatus {
    userIsActiveVoloPassMember
    userHasPastVoloPassMembership
    userIsActiveVoloFitnessMember
    userHasPastVoloFitnessMembership
  }
`;

export default GET_USER_MEMBERSHIP_STATUS;
