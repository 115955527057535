import * as _ from 'lodash-es';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle, withProps, withStateHandlers } from 'recompose';
import moment from 'moment-timezone';
import withCurrentUser from '../../hocs/withCurrentUser';
import { filterJSON } from '../../utils';
import { addSearchParams, getPropFromUrl } from '../../utils/url-search-helpers';
import { LeagueStageEnum } from '../../shared/league-status-enum';

export const NUM_PER_FETCH = 15;

export const formatTime = value => {
  let hour = Math.floor(value / 60);
  const a = hour >= 12 ? 'pm' : 'am';
  const minutes = value - hour * 60;
  if (hour > 12) hour -= 12;
  if (hour === 0 && minutes === 0) return 'Midnight';
  if (hour === 0) hour = 12;
  return `${hour}:${minutes.toString(10).padStart(2, '0')}${a}`;
};

export const getParamsFromUrl = (
  {
    cityName,
    programTypes,
    sportNames,
    seasonNames,
    venueIds,
    daysOfWeek,
    neighborhoodIds,
    dateLow,
    dateHigh,
    skillLevels,
    perks,
    programStatus,
    genders,
    timeLow,
    timeHigh,
    selectedPrograms,
    programTagIds,
    membershipPerks,
    pageNum = 1,
    view,
  },
  isHost = false
) => ({
  input: {
    ...(cityName ? { cityName } : {}),
    ...(programTypes ? { programTypes } : {}),
    ...(sportNames ? { sportNames } : {}),
    ...(venueIds ? { venueIds } : {}),
    ...(daysOfWeek ? { daysOfWeek } : {}),
    ...(neighborhoodIds ? { neighborhoodIds } : {}),
    ...(dateLow ? { dateLow: moment(dateLow, 'YY/MM/DD') } : {}),
    ...(dateHigh ? { dateHigh: moment(dateHigh, 'YY/MM/DD') } : {}),
    ...(seasonNames ? { seasonNames } : {}),
    ...(programStatus ? { programStatus } : {}),
    ...(selectedPrograms ? { leagueIds: selectedPrograms } : {}),
    ...(genders ? { genders } : {}),
    ...(skillLevels ? { skillLevels } : {}),
    ...(perks ? { perks } : {}),
    ...(timeLow ? { timeLow } : {}),
    ...(timeHigh ? { timeHigh } : {}),
    ...(programTagIds?.length ? { programTagIds } : {}),
    ...(membershipPerks?.length ? { membershipPerks } : {}),
    pagination: {
      pageNum,
      numPerPage: NUM_PER_FETCH,
    },
    isHost,
    view,
  },
});

export const filterProps = [
  'cityName',
  'programTypes',
  'sportNames',
  'venueIds',
  'daysOfWeek',
  'neighborhoodIds',
  'dateLow',
  'dateHigh',
  'timeLow',
  'timeHigh',
  'seasonNames',
  'programStatus',
  'genders',
  'skillLevels',
  'perks',
  'registrantTypes',
  'teamNames',
  'programTagIds',
  'pageNum',
  'selectedPrograms',
  'view',
  'membershipPerks',
];

const getLeagueFilterProps = props => ({
  cityName: getPropFromUrl({ props, propName: 'cityName', defaultVal: props.cityName }),
  programTypes: _.map(getPropFromUrl({ props, propName: 'programTypes' }), p => _.toUpper(p)),
  sportNames: getPropFromUrl({ props, propName: 'sportNames' }),
  venueIds: getPropFromUrl({ props, propName: 'venueIds' }),
  daysOfWeek: getPropFromUrl({ props, propName: 'daysOfWeek' }),
  neighborhoodIds: getPropFromUrl({ props, propName: 'neighborhoodIds' }),
  dateLow: getPropFromUrl({ props, propName: 'dateLow' }),
  dateHigh: getPropFromUrl({ props, propName: 'dateHigh' }),
  timeLow: getPropFromUrl({ props, propName: 'timeLow' }),
  timeHigh: getPropFromUrl({ props, propName: 'timeHigh' }),
  seasonNames: getPropFromUrl({ props, propName: 'seasonNames' }),
  programStatus: getPropFromUrl({ props, propName: 'programStatus' }),
  genders: getPropFromUrl({ props, propName: 'genders' }),
  skillLevels: getPropFromUrl({ props, propName: 'skillLevels' }),
  perks: getPropFromUrl({ props, propName: 'perks' }),
  registrantTypes: getPropFromUrl({ props, propName: 'registrantTypes' }),
  teamNames: getPropFromUrl({ props, propName: 'teamNames' }),
  selectedPrograms: getPropFromUrl({ props, propName: 'selectedPrograms' }),
  programTagIds: getPropFromUrl({ props, propName: 'programTagIds' }),
  view: getPropFromUrl({ props, propName: 'view' }),
  membershipPerks: getPropFromUrl({ props, propName: 'membershipPerks' }),
});

export const withPropsFromUrl =
  ({ isHost = false } = {}) =>
  WrappedComponent =>
    compose(
      withCurrentUser(),
      withRouter,
      withStateHandlers(getLeagueFilterProps, {
        updateSearchUrls:
          (state, { history }) =>
          params => {
            addSearchParams({ history, params });
            return { ...state, ...params };
          },
      }),
      withProps(props => {
        const { currentUser, isHost: newIsHost, cityName: newCityName, history } = props;
        const { organizationId, organizations } = currentUser || {};
        const cityName = _.get(
          _.find(organizations, ({ _id }) => _id === organizationId),
          'name'
        );
        let finalCityName = newCityName !== undefined ? newCityName : cityName;

        // remove default cityName if players view
        const cityNameFromURL = getPropFromUrl({ props, propName: 'cityName' });
        if (
          _.get(history, 'location.pathname') === '/rfo/players' &&
          !cityNameFromURL &&
          finalCityName
        ) {
          finalCityName = undefined;
        }

        // get isHost from props of whatever component called the hoc
        return {
          cityName: finalCityName,
          isHost: newIsHost !== undefined ? newIsHost : isHost,
        };
      }),
      lifecycle({
        componentDidMount() {
          // default programStatus if host view and remove if discover
          const programStatus = getPropFromUrl({ props: this.props, propName: 'programStatus' });
          const { history } = this.props;
          const url = _.get(history, 'location.pathname');
          if (!programStatus && url === '/rfo/leagues') {
            addSearchParams({ history, params: { programStatus: LeagueStageEnum.UPCOMING } });
          }
        },
        componentDidUpdate(props) {
          const oldProps = { ..._.pick(props, filterProps) };
          const newProps = { ..._.pick(getLeagueFilterProps(props), filterProps) };

          const diff = filterJSON(oldProps, newProps);
          if (!_.isEmpty(diff)) {
            const { updateSearchUrls } = this.props;
            updateSearchUrls(diff);
          }
        },
      })
    )(WrappedComponent);
