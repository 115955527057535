/* eslint-disable global-require */
import classNames from 'classnames/bind';
import * as _ from 'lodash-es';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { DateRange } from 'react-date-range';
import styles from '../permit-styles.module.scss';
import ViewPermit from '../PermitDetails/ViewPermit';
import { Button } from '../../../components';
import { PageLoader } from '../../../components/Loading';

if (typeof window !== 'undefined') {
  require('react-date-range/dist/styles.css');
  require('react-date-range/dist/theme/default.css');
}

const cx = classNames.bind(styles);

const SearchPermitsPresenter = props => {
  const {
    permits,
    timezone,
    updatePermitOptions,
    pageNum,
    count,
    endCursor,
    start,
    end,
    loading,
    ViewPermitComponent,
  } = props;

  return (
    <div className={cx('permits')}>
      <h1>Search Venue Permits</h1>
      <div className="row pt-4">
        <div className="col-12 col-md-6">
          <DateRange
            maxDate={new Date(new Date().getFullYear(), 12, 31)}
            minDate={new Date('2020-01-01')}
            ranges={[
              {
                startDate: start ? moment(start, 'YYYY/MM/DD').toDate() : new Date(),
                endDate: end ? moment(end, 'YYYY/MM/DD').toDate() : new Date(),
                key: 'dates',
              },
            ]}
            onChange={({ dates }) =>
              updatePermitOptions({
                start: moment(dates.startDate).format('YYYY/MM/DD'),
                end: moment(dates.endDate).format('YYYY/MM/DD'),
              })
            }
          />
        </div>
        <div className="col-12 col-md-6">
          {loading ? (
            <PageLoader />
          ) : (
            <div className={cx('display')}>
              {_.map(permits, (permit, idx) =>
                ViewPermitComponent ? (
                  ViewPermitComponent(permit, idx)
                ) : (
                  <ViewPermit key={idx} permit={permit} timezone={timezone} />
                )
              )}
              {count === 0 ? (
                <div>No permits to display</div>
              ) : (
                <div className="row mt-3 no-gutters">
                  <div className="col-5 text-left">
                    <Button
                      onClick={() => updatePermitOptions({ pageNum: pageNum - 1 })}
                      disabled={pageNum < 2}
                    >
                      Previous Page
                    </Button>
                  </div>
                  <div className="col-2 text-center my-auto">
                    <p>{count} Total</p>
                  </div>
                  <div className="col-5 text-right">
                    <Button
                      onClick={() => updatePermitOptions({ pageNum: pageNum + 1 })}
                      disabled={endCursor === count}
                    >
                      Next Page
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SearchPermitsPresenter.propTypes = {
  permits: PropTypes.arrayOf(
    PropTypes.shape({
      sublocations: PropTypes.arrayOf(PropTypes.string),
      centsDaily: PropTypes.number,
      centsHourly: PropTypes.number,
      note: PropTypes.string,
      pdf: PropTypes.shape({ name: PropTypes.string, url: PropTypes.string }),
      start: PropTypes.string,
      end: PropTypes.string,
    })
  ),
  timezone: PropTypes.string.isRequired,
  updatePermitOptions: PropTypes.func.isRequired,
  ViewPermitComponent: PropTypes.func,
  pageNum: PropTypes.number.isRequired,
  count: PropTypes.number,
  endCursor: PropTypes.number,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

SearchPermitsPresenter.defaultProps = {
  permits: [],
  endCursor: 0,
  count: 0,
  loading: true,
  ViewPermitComponent: null,
};

export default SearchPermitsPresenter;
