import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from '@apollo/client';
import { DISCOVER_NEIGHBORHOODS, DISCOVER_TOP_NEIGHBORHOODS } from './FILTER_QUERIES';
import { getParamsFromUrl, withPropsFromUrl } from './utils';
import { FilterSelect, Error } from '../../components';

const NeighborhoodFilter = props => {
  const {
    neighborhoodIds,
    disabled,
    onSelect,
    isMulti,
    cityName,
    className,
    updateSearchUrls,
    skip,
    isHost,
  } = props;

  const {
    data: neighborhoodsData = {},
    loading: neighborhoodsLoading,
    error: neighborhoodsError,
  } = useQuery(DISCOVER_NEIGHBORHOODS, {
    skip: skip || !cityName,
    fetchPolicy: 'cache-and-network',
    variables: getParamsFromUrl(props, isHost),
  });

  const {
    data: topNeighborhoodsData = {},
    loading: topNeighborhoodsLoading,
    error: topNeighborhoodsError,
  } = useQuery(DISCOVER_TOP_NEIGHBORHOODS, {
    skip: skip || !cityName,
    fetchPolicy: 'cache-and-network',
    variables: getParamsFromUrl(props, isHost),
  });

  const { discoverNeighborhoods = {} } = neighborhoodsData;
  const { discoverTopNeighborhoods = {} } = topNeighborhoodsData;

  const { neighborhoods = [] } = discoverNeighborhoods;
  const { neighborhoods: topNeighborhoods = [] } = discoverTopNeighborhoods;

  const neighborhoodsOptions = [
    ...(topNeighborhoods.length > 0
      ? [
          {
            _id: '',
            name: 'Popular Neighborhoods',
            isDisabled: true,
            labelTitle: true,
          },
          ...topNeighborhoods,
          {
            _id: '',
            name: 'All Neighborhoods',
            isDisabled: true,
            labelTitle: true,
          },
        ]
      : []),
    ...neighborhoods,
  ].map(({ _id, name, labelTitle, isDisabled = false }) => ({
    label: name,
    value: _id,
    labelTitle,
    isDisabled,
  }));

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : (v = []) => updateSearchUrls({ neighborhoodIds: v.map(({ value }) => value) });

  const loading = neighborhoodsLoading || topNeighborhoodsLoading;
  const error = neighborhoodsError || topNeighborhoodsError;

  if (error) return <Error error={error} />;

  return (
    <FilterSelect
      short
      shadow
      labelSingular={cityName === 'Virtual' ? 'Variation/Theme' : 'Neighborhood'}
      labelPlural={cityName === 'Virtual' ? 'Variations/Themes' : 'Neighborhoods'}
      options={neighborhoodsOptions}
      value={neighborhoodsOptions.filter(({ value }) => neighborhoodIds.includes(value)) || null}
      onChange={onChange}
      disabled={disabled || loading}
      multi={isMulti}
      className={className}
      // Styling top neighborhoods labels
      styles={{
        option: (base, { data }) => {
          const { isDisabled } = data;
          return {
            ...base,
            ...(isDisabled
              ? {
                  color: '#e9ff70',
                  backgroundColor: '#183252',
                }
              : {}),
          };
        },
      }}
    />
  );
};

NeighborhoodFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  neighborhoodIds: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  cityName: PropTypes.string,
  className: PropTypes.string,
  isHost: PropTypes.bool,
  skip: PropTypes.bool,
};

NeighborhoodFilter.defaultProps = {
  onSelect: false,
  disabled: false,
  isMulti: false,
  cityName: 'Baltimore',
  neighborhoodIds: [],
  className: '',
  isHost: false,
  skip: false,
};

export default withPropsFromUrl()(NeighborhoodFilter);
