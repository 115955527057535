import { gql } from '@apollo/client';
import {
  IncidentFlag,
  IncidentType,
  ResolutionStatus,
} from '../../../shared/incident-report-enums';

export type UserIncidentReportsResult = {
  incidentReports: {
    _id: string;
    incident_date: Date;
    incident_flag: IncidentFlag;
    incident_type: IncidentType;
    resolution_status: ResolutionStatus;
  }[];
};

export type UserIncidentReportsVariables = {
  input: {
    partiesInvolved: string[];
    pagination?: {
      pageNum?: number;
      numPerPage?: number;
    };
  };
};

export const PLAYER_INCIDENT_REPORT_QUERY = gql`
  query IncidentReports($input: IncidentReportsQueryInput!) {
    incidentReports(input: $input) {
      _id
      incident_date
      incident_flag
      incident_type
      resolution_status
    }
  }
`;
