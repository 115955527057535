import { gql } from '@apollo/client';
import { PaymentSourceFragment } from './PLAYER_PAYMENT_QUERY';

export const ADD_PAYMENT_SOURCE = gql`
  mutation currentUserAddPaymentSource($input: CurrentUserAddPaymentSourceInput!) {
    currentUserAddPaymentSource(input: $input) {
      currentUser {
        _id
        hasStripeId
        paymentSources {
          ...paymentSourceFragment
        }
      }
    }
  }
  ${PaymentSourceFragment}
`;

export const SET_PAYMENT_SOURCE_DEFAULT = gql`
  mutation currentUserSetPaymentSourceDefault($input: CurrentUserSetPaymentSourceDefaultInput!) {
    currentUserSetPaymentSourceDefault(input: $input) {
      currentUser {
        _id
        paymentSources {
          ...paymentSourceFragment
        }
      }
    }
  }
  ${PaymentSourceFragment}
`;

export const DELETE_PAYMENT_SOURCE = gql`
  mutation currentUserDeletePaymentSource($input: CurrentUserDeletePaymentSourceInput!) {
    currentUserDeletePaymentSource(input: $input) {
      currentUser {
        _id
        paymentSources {
          ...paymentSourceFragment
        }
      }
    }
  }
  ${PaymentSourceFragment}
`;
