import { useMutation } from '@apollo/client';
import classNames from 'classnames/bind';
import uniqBy from 'lodash-es/uniqBy';
import PropTypes from 'prop-types';
import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import styles from '../player-details.module.scss';
import {
  ADD_CREDITS,
  ISSUE_REFUND,
  REMOVE_WITHOUT_REFUND,
} from './PLAYER_PAYMENT_MUTATION_AND_QUERIES';
import { ConfirmButton, FilterSelect, PageLoader } from '../../../components';
import { Checkbox, FancyField } from '../../../components/fancyFieldsV2';
import withActionStates from '../../../hocs/withActionStates';
import { useCurrentUser } from '../../../hooks';
import { UseActionStatesProps } from '../../../types';
import { dollarsToCents, formatCents, getLabelOptions } from '../../../utils';

const cx = classNames.bind(styles);

const clearPaymentObject = Object.freeze({
  creditAmount: '0',
  creditNote: '',
  sendEmail: true,
  refundAmount: '0',
  moneyType: 'credit',
  actionType: 'keep',
  amountType: 'partial',
  isGiftCard: true,
});

const PaymentAction = props => {
  const { userId, payments, refetch, setUpdating, setSuccess, setError, updating } = props;

  const [curPaymentDetails, setCurPaymentDetails] = useState(clearPaymentObject);
  const [curPayment, setCurPayment] = useState({});
  const updatePaymentDetails = update => setCurPaymentDetails({ ...curPaymentDetails, ...update });

  const [registrantIssueRefund] = useMutation(ISSUE_REFUND);
  const [registrantRemoveWithoutRefund] = useMutation(REMOVE_WITHOUT_REFUND);
  const [registrantAddCredits] = useMutation(ADD_CREDITS);

  const { loading, error, currentUser } = useCurrentUser();
  if (loading) return <PageLoader />;
  if (error) return <div>Error! {JSON.stringify(error)}</div>;

  const {
    creditAmount,
    creditNote,
    sendEmail,
    refundAmount,
    moneyType,
    actionType,
    amountType,
    isGiftCard,
  } = curPaymentDetails;
  const {
    paymentId,
    maxPartialRefund,
    disableRefunds,
    disablePartialRefunds,
    fullRefund,
    chargeId,
    organizationId,
    league,
  } = curPayment;

  const refundCents = dollarsToCents(refundAmount);

  const amountOption = getLabelOptions(['full', 'partial', 'no']);
  const moneyOption = getLabelOptions(['refund', 'credit']);
  const actionOption = getLabelOptions(['keep', 'remove']);
  const leagueOption = uniqBy(
    payments
      .filter(({ status }) => status !== 'Credit')
      .map(({ reason: label, paymentId: value }) => ({ label, value })),
    'value'
  );
  leagueOption.splice(0, 0, { label: 'Gift Card', value: 'gift' });

  const clearPaymentState = async () => {
    setCurPaymentDetails(clearPaymentObject);
    setCurPayment({});
    await refetch();
  };

  const updateFields = async (type, currentPayment) => {
    if (!currentPayment) {
      // gift card was selected
      updatePaymentDetails({
        isGiftCard: true,
        moneyType: 'credit',
        actionType: 'keep',
        amountType: 'partial',
        creditAmount: '0',
      });
      setCurPayment({ paymentId: 'gift' });
    } else {
      // a program was selected
      const updateAmount = String(
        (type === 'partial' ? currentPayment.maxPartialRefund : currentPayment.fullRefund) / 100
      );
      updatePaymentDetails({
        amountType: type,
        actionType: type === 'no' ? 'remove' : actionType,
        isGiftCard: false,
        creditAmount: updateAmount,
        refundAmount: updateAmount,
      });
      setCurPayment(currentPayment);
    }
  };

  const isDisabled = () => {
    if (isGiftCard) {
      return !creditAmount || !creditNote;
    }
    if (amountType === 'no') {
      return false;
    }
    if (moneyType === 'credit') {
      return !creditAmount || !creditNote || (amountType === 'partial' && +creditAmount > 5000);
    }
    if (moneyType === 'refund') {
      return (
        !refundAmount ||
        disableRefunds ||
        (amountType === 'partial' && refundCents > maxPartialRefund && disablePartialRefunds)
      );
    }
    return !!paymentId || chargeId.includes('FAKE');
  };

  const onRemove = async () => {
    setUpdating(true);
    try {
      await registrantRemoveWithoutRefund({
        variables: {
          input: {
            registrantId: paymentId,
            sendEmail,
          },
        },
      });
      await clearPaymentState();
      setSuccess('Player removed from program.');
    } catch (e) {
      setError(e);
    } finally {
      setUpdating(false);
    }
  };

  const onRefund = async () => {
    setUpdating(true);
    try {
      await registrantIssueRefund({
        variables: {
          input: {
            organizationId,
            registrantId: paymentId,
            isPartialRefund: amountType !== 'full',
            ...(amountType !== 'full' ? { refundCents } : {}),
            sendEmail,
            keepInProgram: actionType !== 'remove',
          },
        },
      });
      setSuccess(
        `Player refunded and ${actionType === 'remove' ? 'removed from' : 'kept in'} program.`
      );
      await clearPaymentState();
    } catch (e) {
      setError(e);
    } finally {
      setUpdating(false);
    }
  };

  const onCredits = async () => {
    setUpdating(true);
    try {
      let successMsg = 'Player credits added and kept in program.';
      await registrantAddCredits({
        variables: {
          input: {
            userId,
            creditAmount: amountType === 'full' ? fullRefund : dollarsToCents(creditAmount),
            creditNote,
            sendEmail,
            reasonType: isGiftCard ? 'gift' : league,
            // pass currentUser.organization if gift card
            organizationId: organizationId || currentUser.organizationId,
          },
        },
      });

      if (actionType === 'remove') {
        await registrantRemoveWithoutRefund({
          variables: {
            input: {
              registrantId: paymentId,
            },
          },
        });
        successMsg = 'Player credits added and removed from program.';
      } else if (isGiftCard) successMsg = 'Gift card added.';

      setSuccess(successMsg);
      await clearPaymentState();
    } catch (e) {
      setError(e);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className={cx('selectContainer', 'mt-4', 'card')}>
      <div className="row mx-0">
        <div className={cx('selectRow', 'my-auto col-12 col-md-1')}>Issue</div>
        <div className={cx('selectRow', 'my-auto col-12 col-md-4')}>
          <FilterSelect
            skinny
            shadow
            onChange={({ value }) =>
              updateFields(
                amountType,
                payments.find(p => p.paymentId === value)
              )
            }
            labelSingular="Select League"
            options={leagueOption}
            value={leagueOption.find(({ value }) => String(value) === paymentId) || null}
          />
        </div>
        {!isGiftCard && (
          <>
            <div className={cx('selectRow', 'my-auto col-12 col-md-2')}>
              <FilterSelect
                skinny
                shadow
                onChange={({ value }) => updateFields(value, curPayment)}
                labelSingular="Select Amount"
                options={amountOption}
                value={amountOption.find(({ value }) => value === amountType) || null}
              />
            </div>
            <div className={cx('selectRow', 'my-auto col-12 col-md-2')}>
              <FilterSelect
                skinny
                shadow
                onChange={({ value }) => updatePaymentDetails({ moneyType: value })}
                labelSingular="Select Type"
                options={moneyOption}
                value={moneyOption.find(({ value }) => value === moneyType) || null}
                disabled={amountType === 'no'}
              />
            </div>
            <div className={cx('selectRow', 'my-auto col-12 col-md-1')}>and</div>
            <div className={cx('selectRow', 'my-auto col-12 col-md-2')}>
              <FilterSelect
                skinny
                shadow
                onChange={({ value }) => updatePaymentDetails({ actionType: value })}
                labelSingular="Select Action"
                options={actionOption}
                value={actionOption.find(({ value }) => value === actionType) || null}
                disabled={(disableRefunds && moneyType !== 'credit') || amountType === 'no'}
              />
            </div>
          </>
        )}
      </div>
      {paymentId && (
        <div className="row mt-2 justify-content-center">
          <div className="col-6 col-md-2 my-auto">
            <Checkbox
              className="my-3 mb-2"
              value={sendEmail}
              onChange={val => updatePaymentDetails({ sendEmail: val })}
              label="Send Email"
              style={{ fontWeight: 400, lineHeight: 1.4, fontSize: '14px' }}
            />
          </div>
          {moneyType === 'refund' && amountType !== 'no' && (
            <div className="col-6 col-md-3 my-3">
              <CurrencyInput
                placeholder="Enter refund amount"
                prefix="$"
                decimalScale={2}
                allowNegativeValue={false}
                value={refundAmount}
                onValueChange={value => updatePaymentDetails({ refundAmount: value || '0' })}
                disabled={amountType !== 'partial'}
                className={cx('currencyInput')}
              />
              {amountType === 'partial' && refundCents > maxPartialRefund && (
                <div className="error">
                  Partial refund cannot exceed {formatCents(maxPartialRefund)}.
                </div>
              )}
            </div>
          )}
          {moneyType === 'credit' && amountType !== 'no' && (
            <>
              <div className="col-6 col-md-3 my-3">
                <CurrencyInput
                  placeholder="Enter credit amount"
                  prefix="$"
                  decimalScale={2}
                  allowNegativeValue={false}
                  value={creditAmount}
                  onValueChange={value => updatePaymentDetails({ creditAmount: value || '0' })}
                  disabled={amountType !== 'partial'}
                  className={cx('currencyInput')}
                />
                {amountType === 'partial' && +creditAmount > 5000 && (
                  <div className="error">Cannot exceed $5,000 in credits.</div>
                )}
              </div>
              <div className="col-6 col-md-7">
                <FancyField
                  grey
                  noLabel
                  className="my-3"
                  value={creditNote}
                  onChange={value => updatePaymentDetails({ creditNote: value })}
                  label="Credit Note"
                  placeholder="Enter credit note"
                />
              </div>
            </>
          )}
          <div className="col-12">
            <ConfirmButton
              onClick={() => {
                if (amountType === 'no') return onRemove();
                if (moneyType === 'credit') return onCredits();
                return onRefund();
              }}
              disabled={updating || isDisabled()}
              confirmingProps={{ danger: true }}
              confirmText={updating ? 'Hang tight...' : 'Confirm'}
              className="mt-2"
            >
              {(() => {
                if (updating) return 'Hang tight...';
                if (amountType === 'no') return 'Remove';
                if (isGiftCard) return `Issue $${creditAmount} gift card`;
                const amountText = `${amountType} $${
                  moneyType === 'credit' ? creditAmount : refundAmount
                }`;
                return `Issue ${amountText} ${moneyType} & ${actionType} ${
                  actionType === 'remove' ? 'from' : 'in'
                } program`;
              })()}
            </ConfirmButton>
            {actionType === 'remove' && (
              <small className="text-danger mt-2">
                This will delete transaction and registration history from Volo. Contact{' '}
                <a href="mailto:info@volosports.com">info@volosports.com</a> for an invoice if
                needed.
              </small>
            )}
            {(chargeId || '').includes('FAKE') && (
              <small className="mt-2 text-danger">
                Cannot refund charge with fake data. You need to register through the app to test
                refunds.
              </small>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

PaymentAction.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      paymentId: PropTypes.string,
      leagueId: PropTypes.string,
      date: PropTypes.string,
      net: PropTypes.number,
      credits: PropTypes.number,
      revenue: PropTypes.number,
      maxPartialRefund: PropTypes.number,
      disableRefunds: PropTypes.bool,
      disablePartialRefunds: PropTypes.bool,
      fullRefund: PropTypes.number,
      chargeId: PropTypes.string,
      status: PropTypes.string,
      type: PropTypes.string,
      reason: PropTypes.string,
      manuallyAddedBy: PropTypes.string,
      platform: PropTypes.string,
    })
  ).isRequired,
  refetch: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  ...UseActionStatesProps,
};

export default withActionStates({ withAlerts: true })(PaymentAction);
