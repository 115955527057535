import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { compose, withProps, branch } from 'recompose';
import { Activities } from '../../shared/activity-name-enum';
import { DISCOVER_SPORTS, DISCOVER_TOP_SPORTS } from './FILTER_QUERIES';
import { DISCOVER_TOP_SPORTS_LIMIT } from '../../apps/Discover/hooks/useDiscoverTopSports';
import { getParamsFromUrl, withPropsFromUrl } from './utils';
import { FilterSelect, Loading } from '../../components';

const SportFilter = props => {
  const {
    cityName,
    sportNames,
    sportNamesOptions,
    disabled,
    onSelect,
    isMulti,
    loading,
    className,
    updateSearchUrls,
    miscStyles,
    hideIcon,
    hideIndicator,
    showValues,
  } = props;

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : (v = []) => {
          updateSearchUrls({ sportNames: v.map(({ value }) => value) });
        };

  return (
    <FilterSelect
      short
      shadow
      hideIcon={hideIcon}
      hideIndicator={hideIndicator}
      labelSingular={cityName === 'Virtual' ? 'Game' : 'Sport'}
      labelPlural={cityName === 'Virtual' ? 'Games' : 'Sports'}
      options={sportNamesOptions}
      value={sportNames.map(name => ({ label: name, value: name }))}
      onChange={onChange}
      disabled={disabled || loading}
      multi={isMulti}
      className={className}
      miscStyles={miscStyles}
      showValues={showValues}
      styles={{
        option: (base, { data }) => {
          const { isDisabled } = data;
          return {
            ...base,
            ...(miscStyles?.option ? { ...miscStyles?.option } : {}),
            ...(isDisabled
              ? {
                  color: '#e9ff70',
                  backgroundColor: '#183252',
                }
              : {}),
          };
        },
      }}
    />
  );
};

SportFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  cityName: PropTypes.string,
  sportNames: PropTypes.arrayOf(PropTypes.string),
  sportNamesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  miscStyles: PropTypes.shape({}),
  hideIcon: PropTypes.bool,
  hideIndicator: PropTypes.bool,
  showValues: PropTypes.bool,
};

SportFilter.defaultProps = {
  onSelect: false,
  loading: false,
  cityName: 'Baltimore',
  disabled: false,
  isMulti: false,
  sportNamesOptions: [],
  sportNames: [],
  className: '',
  miscStyles: {},
  hideIcon: false,
  hideIndicator: false,
  showValues: false,
};

export default compose(
  withPropsFromUrl(),
  graphql(DISCOVER_SPORTS, {
    skip: ({ skip }) => skip,
    options: props => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        ...getParamsFromUrl(props, props.isHost),
      },
    }),
    props: ({ data: { loading = true, discoverSports } }) => ({
      loading,
      allSportNames: discoverSports?.sportNames || [],
    }),
  }),
  graphql(DISCOVER_TOP_SPORTS, {
    skip: ({ skip }) => skip,
    options: props => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        ...getParamsFromUrl(props, props.isHost),
        limit: DISCOVER_TOP_SPORTS_LIMIT,
      },
    }),
    props: ({ data: { loading = true, discoverTopSports } }) => ({
      topSportsLoading: loading,
      topSports: discoverTopSports?.sportNames || [],
    }),
  }),
  branch(
    ({ loading, topSportsLoading }) => loading || topSportsLoading,
    () => Loading
  ),
  withProps(({ dropInSports = [], allSportNames, topSports = [], showAllOptions }) => {
    const availableSports = showAllOptions
      ? Object.values(Activities).map(({ name }) => name)
      : allSportNames;

    const combinedAvailableSports = [
      ...new Set([...availableSports, ...(dropInSports ? [...dropInSports] : [])]),
    ].sort();
    return {
      sportNamesOptions: [
        ...(topSports.length > 0
          ? [
              {
                sportNames: 'Popular Sports',
                _id: '',
                isDisabled: true,
              },
              ...topSports,
              {
                sportNames: 'All Sports',
                _id: '',
                isDisabled: true,
              },
            ]
          : []),
        ...combinedAvailableSports,
      ].map(value => {
        if (typeof value === 'string') {
          return {
            label: value,
            value,
          };
        }
        return {
          label: value.sportNames,
          value: '',
          isDisabled: true,
        };
      }),
    };
  })
)(SportFilter);
