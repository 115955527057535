import { useQuery } from '@apollo/client';
import GET_USER_MEMBERSHIP_STATUS from './gql/GET_USER_MEMBERSHIP_STATUS';

/** This hook returns boolean membership status for all membership types for the current user */
const useUserMembershipStatus = () => {
  const {
    data: membershipData,
    loading: membershipLoading,
    error: membershipError,
  } = useQuery(GET_USER_MEMBERSHIP_STATUS, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    userIsActiveVoloPassMember: isVoloPassMember = false,
    userHasPastVoloPassMembership: isPastVoloPassMember = false,
    userIsActiveVoloFitnessMember: isVoloFitnessMember = false,
    userHasPastVoloFitnessMembership: isPastVoloFitnessMember = false,
  } = membershipData || {};

  return {
    membershipLoading,
    membershipError,
    isVoloPassMember,
    isPastVoloPassMember,
    isVoloFitnessMember,
    isPastVoloFitnessMember,
  };
};

export default useUserMembershipStatus;
