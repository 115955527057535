import * as _ from 'lodash-es';

export const DayToNum = {
  Su: 0,
  M: 1,
  T: 2,
  W: 3,
  Th: 4,
  F: 5,
  S: 6,
};

export const NumToDay = {
  0: 'Su',
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'Th',
  5: 'F',
  6: 'S',
};

export const FullDayToNum = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export const FullNumToDay = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export default (daysOfWeek = []) =>
  _.map(_.sortBy(_.map(daysOfWeek, dow => DayToNum[dow])), num => NumToDay[num]);

export const sortByFullName = (daysOfWeek = []) =>
  _.map(_.sortBy(_.map(daysOfWeek, dow => FullDayToNum[dow])), num => FullNumToDay[num]);
