import { gql } from '@apollo/client';

export const GET_GAME_QUERY = gql`
  query game($gameId: ID!) {
    game(_id: $gameId) {
      _id
      startTimeStr
      endTimeStr
      date
      dayId
      weekId
      location {
        _id
        name
        timezone
      }
      field_name
      teams {
        _id
        name
      }
      is_tournament
      _creator
      _updated
    }
  }
`;

export const LEAGUE_DETAILS_QUERY = gql`
  query league($leagueId: ID!, $input: ScheduleFilterInput) {
    league(_id: $leagueId) {
      _id
      start_time_estimate
      end_time_estimate
      days {
        _id
        date
        weekId
      }
      weeks {
        _id
        week_num
      }
      venue {
        _id
        name
        formatted_address
        neighborhood
        city
        zip
        timezone
      }
      schedules(input: $input) {
        _id
        startTimeStr
        endTimeStr
        days {
          _id
          date
          weekId
        }
        weeks {
          _id
          week_num
        }
      }
      teams {
        _id
        name
      }
      organization {
        _id
        approvedVenues {
          _id
          name
          formatted_address
          neighborhood
          city
          zip
          timezone
        }
      }
    }
  }
`;

export const UPDATE_GAME_MUTATION = gql`
  mutation updateGame($input: EditGameInput!) {
    updateGame(input: $input) {
      league {
        start_date
      }
    }
  }
`;

export const CREATE_GAME_MUTATION = gql`
  mutation createGame($input: CreateGameInput!) {
    createGame(input: $input) {
      league {
        start_date
      }
    }
  }
`;
