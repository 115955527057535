import { gql } from '@apollo/client';

export const ACTIVE_VOLO_PASS_MEMBERSHIP = gql`
  query activeVoloPassMembership {
    activeVoloPassMembership {
      status
    }
  }
`;

export const MEMBERSHIP_GET_PROMO = gql`
  mutation membershipGetPromo($input: StripePromoInput!) {
    membershipGetPromo(input: $input) {
      id
      valid
      amount_off
      percent_off
      duration
      duration_in_months
    }
  }
`;

export const ACTIVATE_MEMBERSHIP = gql`
  mutation startMembership($input: StartMembershipInput!) {
    startMembership(input: $input) {
      status
    }
  }
`;

export const START_TRIAL_MEMBERSHIP = gql`
  mutation startTrialMembership($input: StartTrialMembershipInput!) {
    startTrialMembership(input: $input) {
      status
      planStartsAt
      planExpiresAt
      isStripeAutoRenew
    }
  }
`;

export const RESUME_MEMBERSHIP = gql`
  mutation reinstateMembership {
    reinstateMembership {
      status
    }
  }
`;

export const CANCEL_MEMBERSHIP = gql`
  mutation cancelMembership {
    cancelMembership {
      membership {
        status
      }
    }
  }
`;

export const UPDATE_USER_PLAN = gql`
  mutation ($input: CurrentUserInput) {
    updateCurrentUser(input: $input) {
      currentUser {
        _id
      }
    }
  }
`;

export const USER_QUERY = gql`
  query currentUser {
    currentUser {
      _id
      membershipExpires
      homeOrganization
      paymentSources {
        isDefault
        isExpired
        last4
        brand
      }
    }
  }
`;

export const VP_ORG_TIERS_QUERY = gql`
  query organizationList {
    organizationList {
      _id
      name
      voloPassTiers {
        isActive
        monthlyPlanId
        monthlyDollarPrice
        monthlyFee
        annualPlanId
        annualDollarPrice
      }
    }
  }
`;

export const CHECK_MEMBERSHIP = gql`
  query pastMembership {
    pastMembership
  }
`;
