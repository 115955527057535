import React from 'react';
import { ActivityIndicator } from 'react-native-web';
import { Button } from '@rivallapp/volosports-components';
import { useQuery, NetworkStatus } from '@apollo/client';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';

import { USER_MEMBERSHIP_HISTORY_QUERY } from './PLAYER_PAYMENT_MUTATION_AND_QUERIES';
import { formatCents } from '../../../utils';
import { StripeInvoiceStatus } from '../../../shared/stripe-enums';
import styles from '../player-details.module.scss';
import { Error } from '../../../components';

const cx = classNames.bind(styles);

type MembershipPaymentsProps = {
  userId: string;
};

type StripeInvoiceStatusObject = typeof StripeInvoiceStatus;
type StripeInvoiceStatusKeys = keyof StripeInvoiceStatusObject;
type StripeInvoiceStatusType = StripeInvoiceStatusObject[StripeInvoiceStatusKeys];

type MembershipInvoice = {
  stripeInvoiceId: string;
  status: StripeInvoiceStatusType;
  total: number;
  createdAt: string;
};

type MembershipInvoicesResult = {
  membershipInvoicesForUser: {
    membershipInvoices: MembershipInvoice[];
    hasMore: boolean;
  };
};

type MembershipInvoicesInput = {
  userId: string;
  pagination?: {
    limit?: number;
    cursor: string;
  };
};

const MembershipPayments: React.FC<MembershipPaymentsProps> = ({ userId }) => {
  const { data, error, fetchMore, networkStatus } = useQuery<
    MembershipInvoicesResult,
    MembershipInvoicesInput
  >(USER_MEMBERSHIP_HISTORY_QUERY, {
    skip: !userId,
    notifyOnNetworkStatusChange: true,
    variables: { userId },
  });

  if (networkStatus === NetworkStatus.loading) return <ActivityIndicator />;
  if (error || !userId) {
    return <Error error={error || 'Missing userId'} />;
  }

  const { membershipInvoices = [], hasMore } = data?.membershipInvoicesForUser ?? {};

  if (!membershipInvoices.length) return null;

  const handleLoadMoreInvoices = async () => {
    await fetchMore({
      variables: {
        userId,
        pagination: {
          cursor: membershipInvoices.at(-1)?.stripeInvoiceId,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const prevInvoices = previousResult?.membershipInvoicesForUser.membershipInvoices ?? [];
        const newInvoices = fetchMoreResult?.membershipInvoicesForUser.membershipInvoices ?? [];

        return {
          membershipInvoicesForUser: {
            membershipInvoices: [...prevInvoices, ...newInvoices],
            hasMore: fetchMoreResult?.membershipInvoicesForUser?.hasMore ?? false,
          },
        };
      },
    });
  };

  return (
    <div>
      <h3>Volo Pass Payments</h3>
      <div className={cx('title', 'row text-left')}>
        <div className="col-4">Date</div>
        <div className="col-4">Paid</div>
        <div className="col-4">Status</div>
      </div>
      {membershipInvoices.map(({ createdAt, status, total, stripeInvoiceId }) => (
        <div className={cx('invoiceRow', 'row')} key={stripeInvoiceId}>
          <div className="col-4">{moment(createdAt).format('MMM Do YYYY')}</div>
          <div className="col-4">{formatCents(total)}</div>
          <div className="col-4">{status.toUpperCase()}</div>
        </div>
      ))}
      {hasMore && (
        <Button
          variant="solid"
          colorScheme="brandPrimary"
          onPress={handleLoadMoreInvoices}
          isLoading={networkStatus === NetworkStatus.fetchMore}
        >
          Load More
        </Button>
      )}
    </div>
  );
};

export default MembershipPayments;
