import { graphql } from '@apollo/client/react/hoc';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compose, setDisplayName, withHandlers, withProps } from 'recompose';
import { FancyBoolean } from '../../components';
import withActionStates from '../../hocs/withActionStates';
import withCurrentUser from '../../hocs/withCurrentUser';
import { UPDATE_CURRENT_USER } from './ACCOUNT_FORMS_MUTATIONS_AND_QUERIES';
import DeactivateProfile from './DeactivateProfile';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const PrivacySettings = props => {
  const {
    cancel,
    currentUser,
    emailHidden,
    phoneHidden,
    setError,
    setSuccess,
    setUpdating,
    updateUser,
    updating,
  } = props;

  const [showDeactivate, setShowDeactivate] = useState(false);

  return (
    <div className={cx('account-form', 'privacy-form')}>
      {showDeactivate ? (
        <DeactivateProfile
          {...{
            currentUser,
            setError,
            setSuccess,
            updating,
            setUpdating,
            setShowDeactivate,
          }}
        />
      ) : (
        <>
          <div className={cx('fields-wrap')}>
            <p>Share the following contact information with my teammates:</p>
            <br />
            <div className="text-center">
              <FancyBoolean
                toggle
                onChange={() => updateUser({ updatedEmailHidden: !emailHidden })}
                value={!emailHidden}
                label="Email"
              />
              <FancyBoolean
                toggle
                onChange={() => updateUser({ updatedPhoneHidden: !phoneHidden })}
                value={!phoneHidden}
                label="Phone"
              />
            </div>
          </div>
          <div className={cx('fields-wrap', 'mt-4', 'font-italic', 'text-center')}>
            <a role="link" tabIndex={0} onClick={() => setShowDeactivate(true)}>
              <small className="text-volo-danger">Delete Account</small>
            </a>
          </div>
          <a className={cx('cancel-button')} onClick={cancel} role="link" tabIndex={0}>
            Back
          </a>
        </>
      )}
    </div>
  );
};

PrivacySettings.propTypes = {
  cancel: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({ _id: PropTypes.string }).isRequired,
  emailHidden: PropTypes.bool.isRequired,
  phoneHidden: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setUpdating: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  updating: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('AccountFormsProfile'),
  withActionStates({ withAlerts: true }),
  withCurrentUser(),
  // could just as easily unpack currentUser within the component itself
  withProps(({ currentUser: { emailHidden, phoneHidden } }) => ({
    emailHidden,
    phoneHidden,
  })),
  graphql(UPDATE_CURRENT_USER, { name: 'updateUserMutation' }),
  withHandlers({
    updateUser:
      props =>
      async ({ updatedEmailHidden, updatedPhoneHidden }) => {
        const {
          updateUserMutation,
          setUpdating,
          setError,
          setSuccess,
          currentUser,
          emailHidden,
          phoneHidden,
        } = props;

        setUpdating(true);
        try {
          await updateUserMutation({
            variables: {
              input: {
                _id: currentUser._id,
                ...(updatedEmailHidden !== emailHidden ? { emailHidden: updatedEmailHidden } : {}),
                ...(updatedPhoneHidden !== phoneHidden ? { phoneHidden: updatedPhoneHidden } : {}),
              },
            },
          });
          setSuccess('Successfully updated privacy settings.');
        } catch (e) {
          setError(e);
        } finally {
          setUpdating(false);
        }
      },
  })
)(PrivacySettings);
