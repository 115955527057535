import styled from 'styled-components';
import { Button } from '../components';

export const fonts = {
  OPEN_SANS: '"Open Sans"',
  MONTSERRAT: '"Montserrat"',
};

export const colors = {
  mediumBlue: '#0D90DB',
  darkBlue: '#0A3355',
  white: '#ffffff',
  grey: '#E6E6E6',
  black: '#2b2b2b',
};

const screenSize = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(max-width: ${screenSize.mobileS})`,
  mobileM: `(max-width: ${screenSize.mobileM})`,
  mobileL: `(max-width: ${screenSize.mobileL})`,
  tablet: `(max-width: ${screenSize.tablet})`,
  laptop: `(max-width: ${screenSize.laptop})`,
  laptopL: `(max-width: ${screenSize.laptopL})`,
  desktop: `(max-width: ${screenSize.desktop})`,
  desktopL: `(max-width: ${screenSize.desktop})`,
};

export const fontWeights = {
  light: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '800',
  superExtraBold: '900',
};

export const fontSizes = {
  xxsmall: '8px',
  xsmall: '12px',
  small: '17px',
  medium: '28px',
  large: '44px',
  xlarge: '75px',
};

/* Text */
export const TitleText = styled.h1`
  && {
    color: ${props => props.fontColor || colors.white};
    font-weight: ${props => props.fontWeight || fontWeights.bold};
    font-size: clamp(${fontSizes.xsmall}, 2vw + 2rem, ${fontSizes.xlarge});
    font-family: ${props => props.fontFamily || fonts.OPEN_SANS};
    line-height: 1;
    margin-bottom: 25px;
    letter-spacing: ${props => props.letterSpacing || '0px'};
    text-align: ${props => props.textAlign || 'left'} @media ${device.mobileL} {
      max-width: 300px;
      text-align: left;
      margin-bottom: 8px;
    }
  }
`;

export const SubTitleText = styled.h1`
  && {
    color: ${props => props.fontColor || colors.white};
    font-weight: ${props => props.fontWeight || fontWeights.bold};
    font-size: ${props => props.fontSize || fontSizes.large};
    text-align: ${props => props.textAlign || 'left'};
    padding-top: ${props => props.paddingTop || '0px'};
    margin-top: ${props => props.marginTop || '0px'};
    margin-bottom: ${props => props.marginBottom || '0px'};
    margin-left: ${props => props.marginLeft || '0px'};
    margin-right: ${props => props.marginRight || '0px'};
    letter-spacing: ${props => props.letterSpacing || '0px'};
    font-family: ${props => props.fontFamily || fonts.OPEN_SANS};
    font-style: ${props => props.fontStyle || 'normal'};
    line-height: ${props => props.lineHeight || '1'};
    @media ${device.laptop} {
      font-size: ${props => props.fontSizeMediumScreen || fontSizes.large};
    }
    @media ${device.mobileL} {
      left: 0;
    }
  }
`;

export const ParagraphText = styled.p`
  && {
    color: ${props => props.fontColor || colors.white};
    font-family: ${props => props.fontFamily || fonts.MONTSERRAT};
    font-size: ${props => props.fontSize || fontSizes.small};
    line-height: ${props => props.lineHeight || props.fontSize || fontSizes.small};
    margin-bottom: ${props => props.marginBottom || '0px'};
    margin-top: ${props => props.marginTop || '0px'};
    padding-top: ${props => props.paddingTop || '0px'};
    padding-right: ${props => props.paddingRight || '0px'};
    text-transform: ${props => props.textTransform || 'none'};
    font-weight: ${props => props.fontWeight || fontWeights.regular};
    text-align: ${props => props.textAlign || 'left'};
    @media ${device.mobileL} {
      max-width: 300px;
      font-size: ${props => props.fontSizeMobile || fontSizes.xsmall};
    }
  }
`;

export const SpanText = styled.span`
  && {
    font-family: inherit;
    color: ${props => props.fontColor || colors.white};
    ${props => (props.fontWeight ? `font-weight: ${props.fontWeight};` : '')}
    font-style: ${props => props.fontStyle || 'normal'};
  }
`;

export const TextSpacer = styled.span`
  && {
    display: inline-block;
    width: ${props => props.width ?? '8px'};
  }
`;

/* Buttons */
export const StyledButton = styled(Button)`
  && {
    background-color: ${props => props.backgroundColor || colors.darkBlue};
    color: ${props => props.textColor || colors.white};
    text-transform: ${props => props.textTransform || 'none'};
    border-radius: 37px;
    margin-top: ${props => props.marginVertical || '10px'};
    margin-bottom: ${props => props.marginVertical || '10px'};
    font-weight: ${props => props.fontWeight || fontWeights.regular};
    font-family: ${props => props.fontFamily || fonts.OPEN_SANS};
    font-size: ${props => props.fontSize || '16px'};
    padding: ${props => props.padding || '10px 30px'};
    ${props => (props.width ? `width: ${props.width};` : '')}
  }
`;

/* Images */
export const StyledImage = styled.img`
  && {
    border-radius: ${props => props.borderRadius || '0px'};
    margin-top: ${props => props.marginTop || '0px'};
    margin-bottom: ${props => props.marginBottom || '0px'};
    margin-left: ${props => props.marginLeft || '0px'};
    margin-right: ${props => props.marginRight || '0px'};
    box-shadow: ${props => props.boxShadow || '0px'};
    object-fit: contain;
  }
`;

export const StyledFixedHeightImage = styled.img`
  && {
    border-radius: ${props => props.borderRadius || '0px'};
    margin-top: ${props => props.marginTop || '0px'};
    margin-bottom: ${props => props.marginBottom || '0px'};
    margin-left: ${props => props.marginLeft || '0px'};
    margin-right: ${props => props.marginRight || '0px'};
    box-shadow: ${props => props.boxShadow || '0px'};
    height: ${props => props.height || '10px'};
  }
`;

export const OverlappedImage = styled.div`
  && {
    height: 77px;
    left: 25%;
    position: absolute;
    top: 118px;
    width: 800px;
  }
`;

/* Containers */
export const CenteredDiv = styled.div`
  && {
    display: flex;
    justify-content: center;
    margin-top: ${props => props.marginTop || '0px'};
    margin-bottom: ${props => props.marginBottom || '0px'};
    margin-left: ${props => props.marginLeft || '0px'};
    margin-right: ${props => props.marginRight || '0px'};
  }
`;

export const OverlapContainer = styled.div`
  && {
    align-self: center;
    height: 432px;
    margin-right: 118.09px;
    position: relative;
    width: 1166px;
  }
`;

export const Section = styled.div`
  margin-top: ${props => props.marginTop || '0px'};
  margin-bottom: ${props => props.marginBottom || '0px'};
  margin-left: ${props => props.marginLeft || '0px'};
  margin-right: ${props => props.marginRight || '0px'};
  background-color: ${props => props.backgroundColor || colors.darkBlue};
`;

export const GridContainer = styled.div`
  && {
    display: grid;
    grid-template-columns: ${props => props.gridTemplateColumns || 'unset'};
    grid-template-rows: ${props => props.gridTemplateRows || 'unset'};
    grid-template-areas: ${props => props.gridTemplateAreas || 'unset'};
    align-items: ${props => props.alignItems || 'normal'};
    justify-content: ${props => props.justifyContent || 'normal'};
    gap: ${props => props.gap || '0'};

    ${props => (props.backgroundColor ? `background-color: ${props.backgroundColor};` : '')}

    ${props => (props.padding ? `padding: ${props.padding};` : '')}
        ${props => (props.paddingTop ? `padding-top: ${props.paddingTop};` : '')}
        ${props => (props.paddingRight ? `padding-right: ${props.paddingRight};` : '')}
        ${props => (props.paddingBottom ? `padding-bottom: ${props.paddingBottom};` : '')}
        ${props => (props.paddingLeft ? `padding-left: ${props.paddingLeft};` : '')}

        ${props => (props.margin ? `margin: ${props.margin};` : '')}
        ${props => (props.marginTop ? `margin-top: ${props.marginTop};` : '')}
        ${props => (props.marginRight ? `margin-right: ${props.marginRight};` : '')}
        ${props => (props.marginBottom ? `margin-bottom: ${props.marginBottom};` : '')}
        ${props => (props.marginLeft ? `margin-left: ${props.marginLeft};` : '')}
        
        @media ${device.mobileL} {
      grid-template-columns: ${props => props.gridTemplateColumnsMobile || 'unset'};
      ${props =>
        props.gridTemplateColumnsMobile
          ? `grid-template-columns: ${props.gridTemplateColumnsMobile};`
          : ''}
    }
  }
`;

export const StyledCard = styled.div`
  && {
    padding: 10px;
    height: 50%;
    width: 100%;
    background-color: ${props => props.backgroundColor || colors.darkBlue};
    border-radius: ${props => props.borderRadius || '0px'};
    text-align: left;
    min-height: 250px;
  }
`;

export const GreyFadedCard = styled.div`
  && {
    padding: 20px;
    height: 100%;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.51), rgba(255, 255, 255, 0));
    border-radius: 10px;
    text-align: left;
  }
`;

/* Shapes */
export const Divider = styled.div`
  && {
    margin: 10px 0;
    height: 1px;
    width: 90%;
    background-color: ${props => props.backgroundColor || colors.mediumBlue};
  }
`;

/* Misc */
export const PricingSectionCard = styled.div`
  && {
    padding: 10px;
    background-color: ${props => props.backgroundColor || '#0664A5'};
    color: ${colors.white};
    border-radius: 10px;
    text-align: left;
  }
`;

const vpBackground = 'https://rivall-public.s3.us-west-1.amazonaws.com/photos/vp_background.png';

export const VPBackgroundSection = styled.div`
  background-image: url(${vpBackground});
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 16px;
  padding-right: 16px;
  background-size: cover;
  background-repeat: no-repeat;
`;
