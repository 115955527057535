import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import withActionStates from '../../../hocs/withActionStates';
import classNames from 'classnames/bind';
import HeroSlidesReducer, { initialState, HeroSlidesActions } from './HeroSlidesReducer';
import { AreYouSure, Button, ConfirmButton, TextEditor } from '../../../components';
import { checkValidAndUpdate, addSlideImage } from './helpers';
import trash from '../../../images/icons/trash.svg';
import up from '../../../images/v2icons/upChevron@3x.png';
import down from '../../../images/v2icons/downChevron@3x.png';
import { EditableText, FancyField, Toggle } from '../../../components/fancyFieldsV2';
import styles from './hero-slides.module.scss';

const { SET_SLIDES_FROM_PROPS, UPDATE_SLIDES, CREATE_NEW_SLIDE, DELETE_SLIDE, REARRANGE_SLIDES } =
  HeroSlidesActions;

const cx = classNames.bind(styles);

const HeroSlides = props => {
  const { heroSlides, slideDuration, updateHeroes, updateHeroDuration, title, setError } = props;

  const [showConfirm, setShowConfirm] = useState(false);

  const [heroState, heroDispatch] = useReducer(HeroSlidesReducer, initialState);

  const { slides = [] } = heroState;

  useEffect(() => {
    if (heroSlides?.length > 0) {
      heroDispatch({
        update: { slides: heroSlides },
        type: SET_SLIDES_FROM_PROPS,
      });
    }
  }, [heroSlides]);

  const updateSlides = (update, idx) => {
    heroDispatch({
      update: { ...update },
      index: idx,
      type: UPDATE_SLIDES,
    });
  };

  return (
    <div className={cx('heroSlides', 'container', 'my-5')}>
      <div className="float-right">
        <span className={cx('slide-duration')}>
          <EditableText
            value={slideDuration}
            onSave={({ value }) => updateHeroDuration(+value)}
            inputStyle={{ fontSize: '16px', minWidth: '40px' }}
          />
          ms Slide Duration
        </span>
        <Button large square onClick={() => heroDispatch({ type: CREATE_NEW_SLIDE })}>
          + Add New Slide
        </Button>
        <Button large square className="mx-2" primary onClick={() => setShowConfirm(true)}>
          Update {title} Slides
        </Button>
      </div>
      <h1>{title} Hero Slides</h1>
      <p className="mt-2 text-muted">
        Add and edit the hero slides for the {title?.toLowerCase()} homepage.
      </p>
      <div className="my-3">
        {slides?.map(
          (
            {
              slideImage,
              slideText,
              slideSubText,
              slideLink,
              slideButtonText,
              newTab,
              secondarySlideButtonText,
              secondarySlideLink,
              secondaryNewTab,
            },
            i
          ) => (
            <div className={cx('card')} key={i}>
              <div className="d-flex align-items-center mb-2">
                <h2 className="mr-3">Slide #{i + 1}</h2>
                {i !== 0 && (
                  <div
                    className={cx('plain-btn')}
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      heroDispatch({
                        type: REARRANGE_SLIDES,
                        direction: -1,
                        index: i,
                      })
                    }
                  >
                    <img src={up} alt="up" height="24" />
                  </div>
                )}
                {i < slides.length - 1 && (
                  <div
                    className={cx('plain-btn')}
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      heroDispatch({
                        type: REARRANGE_SLIDES,
                        direction: 1,
                        index: i,
                      })
                    }
                  >
                    <img src={down} alt="down" height="24" />
                  </div>
                )}
              </div>
              {!slideImage ? (
                <div>
                  <label>
                    Hero Image
                    <span className="text-muted mx-2">
                      {' '}
                      (1440&nbsp;x&nbsp;454 recommended - Max&nbsp;1MB)
                    </span>
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={e =>
                      addSlideImage({
                        setError,
                        updateSlides,
                        event: e,
                        index: i,
                      })
                    }
                  />
                  <div className="text-muted">
                    <small>NOTE: only use images that are 2mb or less.</small>
                  </div>
                </div>
              ) : (
                <div className={cx('hero-container')}>
                  <div className={cx('hero')} style={{ backgroundImage: `url(${slideImage})` }}>
                    <div className={cx('jumbotron')}>
                      <h1 className={cx('title')}>{slideText}</h1>
                      <div
                        className={cx('subtitle')}
                        dangerouslySetInnerHTML={{ __html: slideSubText }}
                      />
                      <Button className={cx('ctaButton')}>{slideButtonText}</Button>
                      {secondarySlideButtonText && (
                        <Button className={cx('ctaButton', 'ml-2')}>
                          {secondarySlideButtonText}
                        </Button>
                      )}
                    </div>
                  </div>
                  <img
                    className={cx('delete-hero-image')}
                    src={trash}
                    alt="delete"
                    onClick={() => updateSlides({ slideImage: '' }, i)}
                  />
                </div>
              )}
              {slideImage ? (
                <span className="text-muted mx-2">
                  {' '}
                  (1440&nbsp;x&nbsp;454 recommended - Max&nbsp;1MB)
                </span>
              ) : (
                <div />
              )}
              <FancyField
                placeholder="Slide Text"
                blockStyle
                label="Text"
                value={slideText || ''}
                onChange={val => updateSlides({ slideText: val }, i)}
              />
              <label>Slide Sub Text</label>
              <TextEditor
                placeholder="Slide Sub Text"
                disableMedia
                html={slideSubText || ''}
                onChange={val => updateSlides({ slideSubText: val }, i)}
              />
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-6">
                      <FancyField
                        placeholder="CTA Button Text"
                        blockStyle
                        label="CTA Text"
                        type="url"
                        value={slideButtonText || ''}
                        onChange={val => updateSlides({ slideButtonText: val }, i)}
                      />
                    </div>
                    <div className="col-6 my-auto pt-3">
                      <Toggle
                        value={!!newTab}
                        onChange={val => updateSlides({ newTab: val }, i)}
                        label="Open in new tab?"
                      />
                    </div>
                  </div>
                  <FancyField
                    placeholder="CTA Button Link"
                    blockStyle
                    label="CTA Link"
                    type="url"
                    value={slideLink || ''}
                    onChange={val => updateSlides({ slideLink: val }, i)}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <div className="row">
                    <div className="col-6">
                      <FancyField
                        placeholder="Second CTA Button Text"
                        blockStyle
                        label="Second CTA Text"
                        type="url"
                        value={secondarySlideButtonText || ''}
                        onChange={val => updateSlides({ secondarySlideButtonText: val }, i)}
                      />
                    </div>
                    <div className="col-6 my-auto pt-3">
                      <Toggle
                        value={!!secondaryNewTab}
                        onChange={val => updateSlides({ secondaryNewTab: val }, i)}
                        label="Open in new tab?"
                        labelStyle={{ paddingTop: '5px' }}
                      />
                    </div>
                  </div>
                  <FancyField
                    placeholder="Second CTA Button Link"
                    blockStyle
                    label="Second CTA Link"
                    type="url"
                    value={secondarySlideLink || ''}
                    onChange={val => updateSlides({ secondarySlideLink: val }, i)}
                  />
                </div>
              </div>
              <ConfirmButton
                onClick={() =>
                  heroDispatch({
                    type: DELETE_SLIDE,
                    index: i,
                  })
                }
                className={cx('delete-slide')}
              >
                Delete Slide #{i + 1}
              </ConfirmButton>
            </div>
          )
        )}
      </div>
      <AreYouSure
        body="Are you sure you want to save? Once saved, updates become LIVE IMMEDIATELY."
        hidden={!showConfirm}
        yesAction={() => {
          checkValidAndUpdate({
            setError,
            slides,
            updateHeroes,
          });
          setShowConfirm(false);
        }}
        close={() => setShowConfirm(false)}
      />
    </div>
  );
};

HeroSlides.propTypes = {
  heroSlides: PropTypes.arrayOf(
    PropTypes.shape({
      slideImage: PropTypes.string,
      slideText: PropTypes.string,
      slideSubText: PropTypes.string,
      slideLink: PropTypes.string,
      slideButtonText: PropTypes.string,
      newTab: PropTypes.bool,
      secondarySlideButtonText: PropTypes.string,
      secondarySlideLink: PropTypes.string,
      secondaryNewTab: PropTypes.bool,
    })
  ),
  slideDuration: PropTypes.number,
  updateHeroes: PropTypes.func.isRequired,
  updateHeroDuration: PropTypes.func.isRequired,
  title: PropTypes.string,
  setError: PropTypes.func.isRequired,
};

HeroSlides.defaultProps = {
  heroSlides: [],
  slideDuration: 5000,
  title: 'Edit Heroes',
};

export default withActionStates({ withAlerts: true })(HeroSlides);
