import PropTypes from 'prop-types';
import React from 'react';
import { compose, withProps, branch } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import { withPropsFromUrl } from './utils';
import { FilterSelect, Loading } from '../../components';
import { ORGANIZATION_LIST_QUERY } from './FILTER_QUERIES';
import { orderBy } from '../../shared/enum-helpers';

const CityFilter = props => {
  const {
    overrideValue,
    cityName,
    disabled,
    onSelect,
    isMulti,
    className,
    cityOptions,
    updateSearchUrls,
    useIdValue,
    clearable,
    miscStyles,
    hideIcon,
    hideIndicator,
  } = props;

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : v => updateSearchUrls({ cityName: v?.[useIdValue ? 'value' : 'label'] || null });

  const value = cityOptions.find(({ label }) => label === cityName) || overrideValue;

  // TODO: add options to get ID instead of name
  return (
    <FilterSelect
      short
      shadow
      icon="pin"
      onChange={onChange}
      labelSingular="City"
      placeholder="Select Location"
      options={cityOptions}
      value={value}
      disabled={disabled}
      multi={isMulti}
      className={className}
      clearable={clearable}
      hideIndicator={hideIndicator}
      hideIcon={hideIcon}
      miscStyles={miscStyles}
    />
  );
};

const optionPropType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

CityFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  cityName: PropTypes.string,
  cityOptions: PropTypes.arrayOf(optionPropType).isRequired,
  overrideValue: optionPropType,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  useIdValue: PropTypes.bool,
  className: PropTypes.string,
  updateSearchUrls: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  miscStyles: PropTypes.shape({}),
  hideIcon: PropTypes.bool,
  hideIndicator: PropTypes.bool,
};

CityFilter.defaultProps = {
  onSelect: false,
  disabled: false,
  isMulti: false,
  useIdValue: false,
  className: '',
  overrideValue: undefined,
  clearable: false,
  cityName: '',
  miscStyles: {},
  hideIcon: false,
  hideIndicator: false,
};

export default compose(
  withPropsFromUrl(),
  graphql(ORGANIZATION_LIST_QUERY, {
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { loading, organizationList } }) => ({
      heroLoading: loading,
      organizationListLoading: loading,
      organizationList,
    }),
  }),
  branch(
    ({ loading }) => loading,
    () => Loading
  ),
  withProps(props => {
    const { organizationList = [], selectedCityId } = props;
    let { cityName } = props;

    const cityOptions = organizationList.map(({ name, _id }) => ({ value: _id, label: name }));
    // override cityName if selectedCityId is present
    if (selectedCityId) {
      const foundCity = cityOptions.find(({ value }) => value === selectedCityId);
      cityName = foundCity ? foundCity.label : '';
    }

    return {
      cityName,
      cityOptions: orderBy({ collection: cityOptions, key: 'label' }),
    };
  })
)(CityFilter);
