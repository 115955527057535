import { createContext, useReducer, useMemo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  MembershipEnum,
  usePaymentSummary,
  useProgramPricing,
} from '@rivallapp/volosports-components';
import { StripeSubscriptionStatus } from '../../shared/stripe-enums';
import { RegisterTypes } from '../../apps/APP/Register/tools/constants';
import { useSearchQuery } from '../../hooks';
import { getDisallowedTypesMessage } from '../../apps/APP/Register/tools/helpers';
import RegistrationReducer, { initialState, RegistrationActions } from './RegistrationReducer';
import { ProgramGenders } from '../../shared/gender-enum';
import { RegistrantTypes } from '../../shared/registrant-type-enum';
import {
  LEAGUE_QUERY,
  PROGRAM_PRICING,
  DROPIN_PRICING,
  CURRENT_USER_DATA,
  ACTIVE_VOLO_PASS_MEMBERSHIP,
  GROUP_QUERY,
  GET_GAME_DETAILS,
  GET_DROP_IN_SLOT,
  ACTIVE_RECURRING_DONATION,
} from './RegistrationQueries';

const { UPDATE_REGISTRATION_STATE } = RegistrationActions;

const RegistrationContext = createContext(null);

export const RegistrationProvider = ({ children }) => {
  const { params } = useRouteMatch();
  const { step } = params || {};
  const { dropInId } = useParams();

  const [registerState, registerDispatch] = useReducer(RegistrationReducer, initialState);

  const query = useSearchQuery();
  const invite = query?.get('invite')?.toString() || '';
  const type = query?.get('type')?.toString() || '';

  const {
    donationAmount,
    appliedPromo,
    registrantType,
    creditAppliedAmount,
    selectedGroupId,
    addVpTrial,
    addVpMembership,
  } = registerState;

  const {
    data: currentUserData = {},
    loading: currentUserLoading,
    error: currentUserError,
  } = useQuery(CURRENT_USER_DATA, {
    fetchPolicy: 'network-only',
  });

  const { currentUser } = currentUserData || {};

  const {
    data: groupData = {},
    loading: groupLoading,
    error: groupError,
  } = useQuery(GROUP_QUERY, {
    fetchPolicy: 'network-only',
    skip: !invite && !selectedGroupId,
    variables: {
      groupId: invite || selectedGroupId,
    },
  });

  const {
    data: dropInData,
    loading: dropInLoading,
    error: dropInError,
  } = useQuery(GET_DROP_IN_SLOT, {
    skip: !dropInId,
    variables: {
      id: dropInId,
    },
  });

  const {
    data: gameData,
    loading: gameLoading,
    error: gameError,
  } = useQuery(GET_GAME_DETAILS, {
    skip: !(params?.gameId || dropInData?.dropInSlot?.gameId),
    variables: {
      gameId: params?.gameId || dropInData?.dropInSlot?.gameId,
    },
  });

  const { game } = gameData || {};

  const {
    data: leagueData = {},
    loading: leagueLoading,
    error: leagueError,
  } = useQuery(LEAGUE_QUERY, {
    skip: !(params?.leagueId || game?.league?._id) || !currentUser,
    fetchPolicy: 'network-only',
    variables: {
      leagueId: params?.leagueId || game?.league?._id,
    },
  });

  const isPrepaidTeam = registrantType === RegisterTypes.PREPAID_TEAM_CAPTAIN;

  const usePaymentSummaryProps = usePaymentSummary();

  const {
    data: pricingData = {},
    loading: pricingLoading,
    error: pricingError,
  } = useQuery(PROGRAM_PRICING, {
    skip: !(params?.leagueId || game?.league?._id),
    fetchPolicy: 'network-only',
    variables: {
      input: {
        leagueId: params?.leagueId || game?.league?._id,
        isPrepaidTeam,
        promoCodeStr: appliedPromo,
        donationAmount: donationAmount || 0,
        creditAmount: creditAppliedAmount || 0,
        // Show member pricing if trial or membership is in 'cart'
        ...(addVpMembership || addVpTrial ? { membershipName: MembershipEnum.VOLO_PASS } : {}),
      },
    },
  });

  const {
    data: dropInPriceData,
    error: dropInPriceError,
    loading: dropInPriceLoading,
  } = useQuery(DROPIN_PRICING, {
    skip: !game?.league?._id,
    variables: {
      input: {
        leagueId: game?.league?._id,
        ...(appliedPromo ? { promoCodeStr: appliedPromo } : {}),
        donationCents: donationAmount ?? 0,
        creditCents: creditAppliedAmount ?? 0,
      },
    },
  });

  const {
    data: activeData = {},
    loading: activeLoading,
    error: activeError,
  } = useQuery(ACTIVE_VOLO_PASS_MEMBERSHIP, {
    fetchPolicy: 'network-only',
  });

  const { data: activeDonationData, loading: activeDonationLoading } = useQuery(
    ACTIVE_RECURRING_DONATION,
    { fetchPolicy: 'network-only' }
  );

  // User is prevented from starting a recurring donation if they are already an active donor
  const { userIsActiveDonor = false } = activeDonationData || {};

  const { group } = groupData || {};
  const { prepaid, registrants, isLocked } = group || {};
  const { league = {} } = leagueData;
  const { organization = {}, registration, start_date, programType } = league;

  const {
    block_group_member_registrations,
    block_group_captain_registrations,
    block_prepaid_team_captain_registrations,
    block_all_registrations,
    block_individual_registrations,
    limit_individual_registrations,
    malesAllowed,
    femalesAllowed,
    max_team_size,
  } = registration || {};

  const numMembers = registrants?.length || 0;

  const [registrationClosed, setRegistrationClosed] = useState(false);
  useEffect(() => {
    const registrationOpenDate = league?.registration?.registration_open;
    const registrationCloseDate = league?.registration?.registration_close;
    // close registration if league date is before reg_open & after reg_close
    if (
      new Date().toISOString() < registrationOpenDate ||
      new Date().toISOString() > registrationCloseDate
    ) {
      setRegistrationClosed(true);
    }
    // close registration if current date is after drop-in date
    if (game?._id && new Date().toISOString() >= game?.start_time) {
      setRegistrationClosed(true);
    }
    // allow for additional registrations with a group link before start date
    if (invite && new Date().toISOString() < start_date) setRegistrationClosed(false);
  }, [
    league?.registration?.registration_close,
    league?.registration?.registration_open,
    game?._id,
    game?.start_time,
    invite,
    start_date,
  ]);

  useEffect(() => {
    if (numMembers >= max_team_size) {
      registerDispatch({
        update: {
          fullTeam: true,
          joiningGroupId: null,
          selectedGroupId: null,
        },
        type: UPDATE_REGISTRATION_STATE,
      });
    }

    if (invite) {
      registerDispatch({
        update: {
          joiningGroupId: invite,
          selectedGroupId: invite,
          fullTeam: numMembers >= max_team_size,
          isLocked,
        },
        type: UPDATE_REGISTRATION_STATE,
      });
    }

    if (type) {
      registerDispatch({
        update: { registrantType: prepaid ? RegisterTypes.PREPAID_TEAM_MEMBER : type },
        type: UPDATE_REGISTRATION_STATE,
      });
    }

    // reset joiningGroupId & selectedGroupId if group from invite is locked
    if (isLocked) {
      registerDispatch({
        update: {
          joiningGroupId: null,
          selectedGroupId: null,
        },
        type: UPDATE_REGISTRATION_STATE,
      });
    }

    if (prepaid) {
      registerDispatch({
        update: { passwordRequired: prepaid },
        type: UPDATE_REGISTRATION_STATE,
      });
    }

    if (block_individual_registrations && type === 'fa') {
      registerDispatch({
        update: { registrantType: RegisterTypes.WAITLIST },
        type: UPDATE_REGISTRATION_STATE,
      });
    }
  }, [
    registerDispatch,
    invite,
    isLocked,
    type,
    prepaid,
    block_individual_registrations,
    numMembers,
    max_team_size,
  ]);

  const value = useMemo(() => {
    const { activeVoloPassMembership = {} } = activeData;

    const useProgramPricingWrapped = () =>
      useProgramPricing({
        usePaymentSummaryProps,
        promoCodeStr: appliedPromo,
        donationsAppliedInCents: donationAmount || 0,
        isPrepaidTeam,
        creditsAppliedInCents: creditAppliedAmount || 0,
        leagueId: params?.leagueId || game?.league?._id,
        isDropIn: Boolean(game?.league?._id),
      });

    // saves the valid RegistrantTypes abbreviations to block invalid strings in the url
    const rTypes = [];

    Object.values(RegistrantTypes).forEach(rType => {
      if (rType.abbrev) rTypes.push(rType.abbrev);
    });

    const {
      availableCredits = [],
      gender,
      hasStripeId,
      paymentSources,
      membershipExpires,
      isVoloPassMember,
    } = currentUser || {};
    const { status } = activeVoloPassMembership ?? {};

    const { isVoloPassTrialActive = false } = organization;

    const availableCreditsAmount = availableCredits?.reduce((acc, { amount }) => acc + amount, 0);

    const { validGenders } = ProgramGenders?.[league?.gender] || {};

    const { blockGender, blockedMessage, blockTypes } =
      getDisallowedTypesMessage({
        ...league.registration,
        metGenderReq: validGenders?.includes(gender),
        gender,
        block_individual_registrations,
      }) || {};

    return [
      {
        ...registerState,
        league,
        programType,
        isDropIn: Boolean(game?._id),
        loading: leagueLoading,
        currentUser,
        currentUserLoading,
        pricingLoading,
        dropInPriceLoading,
        dropInLoading,
        activeLoading,
        activeDonationLoading,
        groupLoading,
        gameLoading,
        group: groupData?.group,
        error:
          leagueError ||
          pricingError ||
          currentUserError ||
          activeError ||
          groupError ||
          gameError ||
          dropInPriceError ||
          dropInError,
        availableCreditsAmount,
        pricingBreakdown: pricingData?.programPricingForRegistration?.pricingBreakdown || {},
        dropInPriceData,
        promoCodeMessage: pricingData?.programPricingForRegistration?.promoCodeMessage || '',
        shouldGrantTrial:
          status !== StripeSubscriptionStatus.TRIALING &&
          status !== StripeSubscriptionStatus.ACTIVE &&
          status !== StripeSubscriptionStatus.CANCELED,
        blockRegistrations:
          block_group_member_registrations &&
          block_group_captain_registrations &&
          block_prepaid_team_captain_registrations &&
          block_all_registrations &&
          !selectedGroupId,
        block_individual_registrations,
        isVoloPassTrialActive,
        userIsActiveDonor,
        paymentSources,
        membershipExpires,
        gender,
        hasStripeId,
        step,
        status,
        blockGender,
        blockedMessage,
        blockTypes,
        rTypes,
        validGenders,
        limit_individual_registrations,
        malesAllowed,
        femalesAllowed,
        max_team_size,
        registrationClosed,
        isPrepaidTeam,
        usePaymentSummaryProps,
        // Overwrite the original field with the new one for backwards compatibility
        termsAgreed: usePaymentSummaryProps.areMemberTermsAgreed,
        addVpMembership:
          !isVoloPassMember && usePaymentSummaryProps.pricingSelected === MembershipEnum.VOLO_PASS,
        useProgramPricingWrapped,
      },
      registerDispatch,
    ];
  }, [
    activeData,
    currentUser,
    organization,
    league,
    block_individual_registrations,
    registerState,
    programType,
    game?._id,
    game?.league?._id,
    leagueLoading,
    currentUserLoading,
    pricingLoading,
    dropInPriceLoading,
    dropInLoading,
    activeLoading,
    activeDonationLoading,
    groupLoading,
    gameLoading,
    groupData?.group,
    leagueError,
    pricingError,
    currentUserError,
    activeError,
    groupError,
    gameError,
    dropInPriceError,
    dropInError,
    pricingData?.programPricingForRegistration?.pricingBreakdown,
    pricingData?.programPricingForRegistration?.promoCodeMessage,
    dropInPriceData,
    block_group_member_registrations,
    block_group_captain_registrations,
    block_prepaid_team_captain_registrations,
    block_all_registrations,
    selectedGroupId,
    userIsActiveDonor,
    step,
    limit_individual_registrations,
    malesAllowed,
    femalesAllowed,
    max_team_size,
    registrationClosed,
    isPrepaidTeam,
    usePaymentSummaryProps,
    appliedPromo,
    donationAmount,
    creditAppliedAmount,
    params?.leagueId,
  ]);

  return <RegistrationContext.Provider value={value}>{children}</RegistrationContext.Provider>;
};

RegistrationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RegistrationContext;
