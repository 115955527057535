import * as _ from 'lodash-es';
import moment from 'moment-timezone';
import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { branch, compose, setDisplayName, withStateHandlers } from 'recompose';
import { QUERY_PERMITS_BY_RANGE, QUERY_VENUE } from '../PERMIT_QUERIES_AND_MUTATIONS';
import SearchPermitsPresenter from './SearchPermitsPresenter';
import withActionStates from '../../../hocs/withActionStates';
import withCurrentUser from '../../../hocs/withCurrentUser';
import { PageLoader } from '../../../components/Loading';

export default compose(
  setDisplayName('SearchPermitsContainer'),
  withActionStates({ withAlerts: true }),
  withCurrentUser(),
  branch(
    ({ venueId }) => !venueId,
    () => () => <div>No venue id found!</div>
  ),
  graphql(QUERY_VENUE, {
    skip: ({ venueId }) => !venueId,
    options: ({ venueId }) => ({
      variables: {
        venueId,
      },
    }),
    props: ({ data: { venue, loading, error } }) => ({
      venueLoading: loading,
      error,
      ...venue,
    }),
  }),
  withStateHandlers(
    ({ initialStart, initialEnd }) => ({
      pageNum: 1,
      start: initialStart || moment().format('YYYY/MM/DD'),
      end: initialEnd || moment().add(1, 'Y').format('YYYY/MM/DD'),
    }),
    {
      updatePermitOptions: state => update => {
        // if switching dates, reset pageNum to 1
        if (update.start || update.end) {
          return { pageNum: 1, ...update };
        }
        return { ...state, ...update };
      },
    }
  ),
  graphql(QUERY_PERMITS_BY_RANGE, {
    skip: ({ venueId }) => !venueId,
    options: ({ venueId, pageNum, start, end }) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          venueId,
          pagination: {
            pageNum,
            numPerPage: 10,
          },
          start,
          end,
        },
      },
    }),
    props: ({ data: { permitsByDateRange, ...rest } }) => ({
      ..._.pick(rest, ['loading', 'error', 'refetch']),
      ...permitsByDateRange,
    }),
  }),
  branch(
    ({ venueLoading }) => venueLoading,
    () => PageLoader
  ),
  branch(
    ({ error }) => error,
    () => () => <div>Error!</div>
  )
)(SearchPermitsPresenter);
