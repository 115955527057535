import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { DayProps, GameProps, ChildrenNodeProps } from '../../../../types';

const cx = classNames.bind(styles);

const DayItem = ({ day, games = [], children }) => {
  const { date, is_tournament } = day;

  return (
    <div className={cx('day-item')}>
      <h3 className={cx('date')}>{moment(date).format('ddd M/D/YY')}</h3>
      {is_tournament && !games.length && (
        <p style={{ fontSize: '12px' }}>
          Click <i>Edit Schedule</i> to add playoff games.
        </p>
      )}
      {children}
    </div>
  );
};

export default DayItem;

DayItem.propTypes = {
  children: ChildrenNodeProps.isRequired,
  day: DayProps.isRequired,
  games: PropTypes.arrayOf(GameProps).isRequired,
};
