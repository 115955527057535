import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isEmail, isInt } from 'validator';
import {
  GET_CURRENT_USER,
  UPDATE_CURRENT_USER,
  UPLOAD_PROFILE_PICTURE,
} from './ACCOUNT_FORMS_MUTATIONS_AND_QUERIES';
import styles from './styles.module.scss';
import { Button, Error, FilterSelect, Loading, PhotoUploader } from '../../components';
import { FancyField } from '../../components/fancyFieldsV2';
import withActionStates from '../../hocs/withActionStates';
import { UseActionStatesProps } from '../../types';
import { GenderOptions } from '../../shared/gender-enum';

const cx = classNames.bind(styles);

const Profile = props => {
  const { cancel, setUpdating, setSuccess, setError, updating } = props;

  const [userDetails, setUserDetails] = useState({});
  const updateUserDetails = update => setUserDetails({ ...userDetails, ...update });

  const [updateUserMutation] = useMutation(UPDATE_CURRENT_USER);
  const [uploadProfilePictureMutation] = useMutation(UPLOAD_PROFILE_PICTURE);

  const { data, error, loading, refetch } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
  });
  const { currentUser } = data ?? {};

  useEffect(() => {
    if (!loading && currentUser) {
      setUserDetails({
        gender: currentUser.gender,
        shirt_size: currentUser.shirt_size,
        zip: currentUser.zip,
        email: currentUser.email,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        username: currentUser.username,
        picture: currentUser.picture,
      });
    }
  }, [currentUser, loading]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  const updateUser = async () => {
    setUpdating(true);
    // eslint-disable-next-line no-unused-vars
    const { picture, ...rest } = userDetails;
    try {
      await updateUserMutation({
        variables: {
          input: {
            _id: currentUser._id,
            ...rest,
          },
        },
      });
      setSuccess(`Successfully updated profile for ${userDetails.username} 👍`);
      if (currentUser.email.toLowerCase() !== userDetails.email.toLowerCase()) {
        setSuccess(`Please check your new email (${userDetails.email}) for a verification code.`);
      }
      await refetch();
    } catch (e) {
      setError(e);
    } finally {
      setUpdating(false);
    }
  };

  const onPhotoSave = async ([{ file, type: fileType }]) => {
    setUpdating(true);
    try {
      await uploadProfilePictureMutation({
        variables: { input: { profilePicture: file, fileType } },
      });
      await refetch();
      setSuccess('Profile picture uploaded successfully!');
    } catch (err) {
      setError(err);
    } finally {
      setUpdating(false);
    }
  };

  const valid = () =>
    !!userDetails.gender &&
    !!userDetails.shirt_size &&
    userDetails.zip &&
    userDetails.zip.length >= 4 &&
    isInt(userDetails.zip) &&
    isEmail(userDetails.email);

  return (
    <div className={cx('account-form', 'profile-form')}>
      <div className={cx('profile-picture-header')}>
        <PhotoUploader
          onSave={onPhotoSave}
          text="Drop file or click to upload profile"
          initialPhoto={userDetails.picture}
          key={userDetails.picture}
          height={50}
          hideSave
          isCircle
        />
      </div>
      <div className={cx('fields-wrap')}>
        <label>Username</label>
        <FancyField
          noLabel
          grey
          initFocus
          value={userDetails.username}
          placeholder="Username"
          onChange={value => updateUserDetails({ username: value.toLowerCase() })}
        />
        <label className="mt-3">Email</label>
        <FancyField
          noLabel
          grey
          value={userDetails.email}
          placeholder="Email"
          onChange={value => updateUserDetails({ email: value.toLowerCase() })}
        />
        <label className="mt-3">First Name</label>
        <FancyField
          noLabel
          grey
          value={userDetails.firstName}
          placeholder="First Name"
          onChange={value => updateUserDetails({ firstName: value })}
        />
        <label className="mt-3">Last Name</label>
        <FancyField
          noLabel
          grey
          value={userDetails.lastName}
          placeholder="Last Name"
          onChange={value => updateUserDetails({ lastName: value })}
        />
        <label className="mt-3">Gender Identity</label>
        <FilterSelect
          skinny
          rounded
          className="mb-3"
          onChange={({ value }) => updateUserDetails({ gender: value })}
          placeholder="Gender Identity"
          options={GenderOptions}
          value={GenderOptions.find(({ value }) => value === userDetails.gender)}
        />
        <label className="mt-3">Zip Code</label>
        <FancyField
          noLabel
          grey
          value={userDetails.zip}
          placeholder="Zip Code"
          onChange={value => updateUserDetails({ zip: value })}
        />
        <label className="mt-3">T-Shirt Size</label>
        <div className={cx('text-center')}>
          {['s', 'm', 'l', 'xl', 'xxl'].map(size => (
            <a
              className={cx('choice-button', { active: userDetails.shirt_size === size })}
              onClick={() => updateUserDetails({ shirt_size: size })}
              role="button"
              tabIndex={0}
              key={size}
            >
              {size}
            </a>
          ))}
        </div>
      </div>
      <Button onClick={updateUser} action disabled={!valid() || updating} wide>
        {updating ? 'Saving...' : 'Save'}
      </Button>
      <br />
      <a className={cx('cancel-button')} onClick={cancel} role="link" tabIndex={0}>
        Back
      </a>
    </div>
  );
};

Profile.propTypes = {
  ...UseActionStatesProps,
  cancel: PropTypes.func.isRequired,
};

export default withActionStates({ withAlerts: true })(Profile);
