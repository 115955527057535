import type { StripeElementsOptions, StripeCardElementOptions } from '@stripe/stripe-js';
import { Colors } from '@rivallapp/volosports-components';

export const ElementsOptions: StripeElementsOptions = {
  locale: 'en',
};

export const CardElementOptions: StripeCardElementOptions = {
  style: {
    base: {
      color: Colors.black as string,
      fontFamily: '"Apercu", "Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontWeight: '400',
      fontSize: '14px',
      '::placeholder': {
        color: Colors.greys.medium as string,
      },
    },
    invalid: {
      color: Colors.error as string,
      iconColor: Colors.error as string,
    },
  },
};
