import { gql } from '@apollo/client';

export const ORGANIZATION_LIST_QUERY = gql`
  query organizationList {
    organizationList {
      _id
      name
    }
  }
`;

export const DISCOVER_PROGRAM_TYPES = gql`
  query discoverProgramTypes($input: DiscoverInput!) {
    discoverProgramTypes(input: $input) {
      programTypes
    }
  }
`;

export const DISCOVER_SKILL_LEVELS = gql`
  query discoverSkillLevels($input: DiscoverInput!) {
    discoverSkillLevels(input: $input) {
      skillLevels
    }
  }
`;

export const DISCOVER_PERKS = gql`
  query discoverPerks($input: DiscoverInput!) {
    discoverPerks(input: $input) {
      perks
    }
  }
`;

export const DISCOVER_SPORTS = gql`
  query discoverSports($input: DiscoverInput!) {
    discoverSports(input: $input) {
      sportNames
    }
  }
`;

export const DISCOVER_VENUES = gql`
  query discoverVenues($input: DiscoverInput!) {
    discoverVenues(input: $input) {
      venues {
        _id
        shorthandName
      }
      allVenues {
        _id
        shorthandName
      }
    }
  }
`;

export const DISCOVER_TOP_VENUES = gql`
  query discoverTopVenues($input: DiscoverInput!) {
    discoverTopVenues(input: $input) {
      venues {
        _id
        shorthandName
      }
    }
  }
`;

export const DISCOVER_TOP_SPORTS = gql`
  query discoverTopSports($input: DiscoverInput!) {
    discoverTopSports(input: $input) {
      sportNames
    }
  }
`;

export const DISCOVER_TOP_NEIGHBORHOODS = gql`
  query discoverTopNeighborhoods($input: DiscoverInput!) {
    discoverTopNeighborhoods(input: $input) {
      neighborhoods {
        _id
        name
      }
    }
  }
`;

export const DISCOVER_NEIGHBORHOODS = gql`
  query discoverNeighborhoods($input: DiscoverInput!) {
    discoverNeighborhoods(input: $input) {
      neighborhoods {
        _id
        name
      }
      allNeighborhoods {
        _id
        name
      }
    }
  }
`;

export const DISCOVER_DAYS_OF_WEEK = gql`
  query discoverDaysOfWeek($input: DiscoverInput!) {
    discoverDaysOfWeek(input: $input) {
      daysOfWeek
    }
  }
`;

export const DISCOVER_SEASONS = gql`
  query discoverSeasons($input: DiscoverInput!) {
    discoverSeasons(input: $input) {
      seasons
    }
  }
`;

export const DISCOVER_TEAMS = gql`
  query discoverTeams($input: DiscoverInput!) {
    discoverTeams(input: $input) {
      teams {
        _id
        name
      }
    }
  }
`;

export const DISCOVER_PROGRAM_TAGS = gql`
  query discoverProgramTags($input: DiscoverInput!) {
    discoverProgramTags(input: $input) {
      programTags {
        _id
        name
      }
    }
  }
`;
