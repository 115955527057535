import { useMutation } from '@apollo/client';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useState } from 'react';
import EditGame from '../EditGame';
import EditScores from '../LeagueManagerSchedule/EditGameScores';
import { REMOVE_TOURNAMENT_MUTATION } from './LEAGUE_TOURNAMENT_QUERY';
import styles from './styles.module.scss';
import TournamentRound from './TournamentRound';
import { Button, CalendarPicker, Popup } from '../../../../components';

const cx = classNames.bind(styles);

const ManageTournamentPresenter = props => {
  const {
    tournament,
    edit,
    rounds,
    teams,
    editingTournamentScore,
    editTournamentScore,
    editingTournamentGame,
    editTournamentGame,
    refetch,
    timezone,
    league,
    updateDate,
    setSuccess,
    setError,
  } = props;

  const [editDate, setEditDate] = useState(null);
  const [removeTournamentMutation] = useMutation(REMOVE_TOURNAMENT_MUTATION);

  const removeTournament = async () => {
    try {
      await removeTournamentMutation({
        variables: {
          input: {
            subScheduleId: tournament?.subSchedule?._id,
          },
        },
      });
      setSuccess('Removed tournament');
      await refetch();
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className={cx('tournament-view', { editing: edit })}>
      <h2 className={cx('section-header')}>Tournament</h2>
      {edit ? (
        <button onClick={removeTournament} className={cx('remove-tournament-btn')} type="button">
          Remove Tournament
        </button>
      ) : (
        <div style={{ width: '100 %', overflow: 'auto' }}>
          <iframe
            src={`https://challonge.com/${tournament._id}/module`}
            width="100%"
            height="500"
            frameBorder="0"
            scrolling="auto"
            title="bracket"
          />
        </div>
      )}
      <div className={cx('tournament-schedule')}>
        {rounds.map(round => (
          <TournamentRound
            key={round.name}
            round={round}
            updateGame={editTournamentGame}
            editScore={editTournamentScore}
            edit={edit}
            teams={teams}
            timezone={timezone || moment.tz.guess()}
            setEditDate={setEditDate}
          />
        ))}
        <Popup close={editTournamentGame} className="fixed" hidden={!editingTournamentGame}>
          <EditGame
            leagueId={league._id}
            gameId={editingTournamentGame?.game?._id}
            afterSave={() => {
              editTournamentGame();
              refetch();
            }}
          />
        </Popup>
        <Popup close={editTournamentScore} className="fixed" hidden={!editingTournamentScore}>
          <EditScores
            {...editingTournamentScore}
            closePopup={editTournamentScore}
            refetch={refetch}
            isTournament
          />
        </Popup>
        <Popup close={() => setEditDate(null)} className="fixed" hidden={!editDate}>
          <div className={cx('select-new-date', 'container')}>
            <h2>Change Date</h2>
            <br />
            <CalendarPicker
              moment={editDate?.date ? moment(editDate?.date) : moment()}
              onChange={value =>
                setEditDate({
                  ...editDate,
                  date: moment.utc(value || new Date()).format(),
                })
              }
              calendarOnly
              hideSave
              className="mx-auto pb-2"
            />
            <Button
              onClick={() => {
                updateDate(editDate);
                setEditDate(null);
              }}
              disabled={!editDate}
            >
              Save New Date
            </Button>
          </div>
        </Popup>
      </div>
    </div>
  );
};

ManageTournamentPresenter.propTypes = {
  tournament: PropTypes.shape({
    _id: PropTypes.string,
    games: PropTypes.arrayOf(PropTypes.shape({})),
    subSchedule: PropTypes.shape({
      _id: PropTypes.string,
    }),
  }).isRequired,
  edit: PropTypes.bool,
  rounds: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      games: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editingTournamentScore: PropTypes.shape({}),
  editTournamentScore: PropTypes.func.isRequired,
  editingTournamentGame: PropTypes.shape({
    game: PropTypes.shape({
      _id: PropTypes.string,
    }),
  }),
  editTournamentGame: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  updateDate: PropTypes.func.isRequired,
  timezone: PropTypes.string,
  league: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  setSuccess: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

ManageTournamentPresenter.defaultProps = {
  edit: false,
  editingTournamentScore: null,
  editingTournamentGame: null,
  timezone: '',
};

export default ManageTournamentPresenter;
