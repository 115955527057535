import { gql, type TypedDocumentNode } from '@apollo/client';
import type { RentalRegistrantTypes, TRentalActivityName } from '@rivallapp/volosports-components';
import type RegistrantTypeEnum from '../../../shared/registrant-type-enum';

export type RegistrantWaiver = {
  waiverId: string;
  signed: boolean;
  date_signed?: Date;
  reminders_sent: {
    date_sent: Date;
    sent_by: string;
  }[];
};

export type RegistrantItem = {
  __typename: 'Registrant';
  _id: string;
  registrantKind: (typeof RegistrantTypeEnum)[keyof typeof RegistrantTypeEnum];
  isDropin?: boolean;
  stagedTeam?: {
    _id: string;
    name: string;
  };
  group?: {
    _id: string;
    name: string;
  };
  waiver: RegistrantWaiver;
  league: {
    _id: string;
    name: string;
    seasonName?: string;
    activityName?: string;
    organizationId: string;
  };
  dropInSlot?: {
    _id: string;
    team: {
      _id: string;
      name: string;
    };
  };
};

export type RentalRegistrantItem = {
  __typename: 'RentalRegistrant';
  _id: string;
  rental: {
    _id: string;
    activity_name: TRentalActivityName;
    name: string;
  };
  rentalRegistrantKind: RentalRegistrantTypes;
  waiver: RegistrantWaiver;
};

export type UserRegistrantsData = {
  userRegistrants: {
    items: RegistrantItem[] | RentalRegistrantItem[];
  };
};

export type UserRegistrantsInput = {
  input: {
    userId: string;
    pageNum?: number;
    numPerPage?: number;
  };
};

const USER_REGISTRANTS: TypedDocumentNode<UserRegistrantsData, UserRegistrantsInput> = gql`
  query UserRegistrants($input: UserDetailListInput!) {
    userRegistrants(input: $input) {
      items {
        __typename
        ... on Registrant {
          _id
          registrantKind: kind
          isDropin
          stagedTeam {
            _id
            name
          }
          group {
            _id
            name
          }
          waiver {
            waiverId
            signed
            date_signed
            reminders_sent {
              date_sent
              sent_by
            }
          }
          league {
            _id
            name
            seasonName
            activityName
            organizationId
          }
          dropInSlot {
            _id
            team {
              _id
              name
            }
          }
        }
        ... on RentalRegistrant {
          _id
          rentalRegistrantKind: kind
          rental {
            _id
            activity_name
            name
          }
          waiver {
            waiverId
            signed
            date_signed
            reminders_sent {
              date_sent
              sent_by
            }
          }
        }
      }
    }
  }
`;

export default USER_REGISTRANTS;
