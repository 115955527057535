import { useState } from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button } from '@rivallapp/volosports-components';
import LEAGUE_BASICS_QUERY from './LEAGUE_BASICS_QUERY';
import { Error, Image, PageLoader, Popup, Tooltip } from '../../../components';
import {
  alarm,
  cup,
  date,
  marker,
  refShirt,
  users,
  users_team,
  user_single,
} from '../../../images/icons';
import styles from '../../../styles/main.module.scss';
import { pluralize } from '../../../utils/index';
import { ProgramGenders } from '../../../shared/gender-enum';

const cx = classNames.bind(styles);

const LeagueBasicsPresenter = props => {
  const { leagueId, style, hideWaysToRegister, hideDeepLink } = props;

  const [didCopy, onCopy] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const { data, loading, error } = useQuery(LEAGUE_BASICS_QUERY, {
    skip: !leagueId,
    variables: { leagueId },
  });
  if (loading) return <PageLoader />;
  if (error || !leagueId) return <Error error={error} />;

  // hide copied text after 3 sec
  const showCopied = () => {
    onCopy(true);
    setTimeout(() => onCopy(false), 1000);
  };

  const {
    registration,
    activityName,
    formatType,
    gender,
    deepLink,
    venue: { maps_url_query, formatted_address, name } = {},
    details: { rules },
    registration: { allowPrepaidTeams, max_team_size, min_team_size },
    timezone,
    start_date,
    start_time_estimate,
    end_time_estimate,
    days_of_week_estimate,
    num_weeks_estimate,
    num_playoff_weeks_estimate,
  } = data?.league || {};

  // If the league doesn't have prepaid teams but does permit groups,
  //  we show groups as being 2-(max) players. Otherwise, 2-(min-1).
  const maxGroupPlayersStr = !allowPrepaidTeams
    ? `${max_team_size <= 2 ? '' : ` - ${max_team_size}`}`
    : `${min_team_size <= 3 ? '' : ` - ${min_team_size - 1}`}`;

  const genderString =
    gender?.toLowerCase() === 'n/a' ? 'Open' : `${ProgramGenders[gender]?.name}'s`;
  const startMoment = moment.tz(start_date, timezone);
  const beginStr =
    days_of_week_estimate?.length > 1
      ? startMoment.format('dddd M/D')
      : `${moment().isAfter(startMoment) ? 'Began' : 'Begins'} ${startMoment.format('M/D')}`;

  const numWeekString = `${num_weeks_estimate - (num_playoff_weeks_estimate || 0)} ${
    num_playoff_weeks_estimate ? `+ ${num_playoff_weeks_estimate} playoff ` : ' '
  }`;

  return (
    <div className={cx('basic-container')}>
      <section className={cx('league-basics')}>
        <div className={cx('row')} style={style}>
          <div className={cx('col-12', 'col-sm-4', styles.item)}>
            <div>
              <div style={{ minWidth: '32px' }}>
                <Image src={cup} />
              </div>
              <p>
                {genderString} {activityName}{' '}
                {formatType && formatType !== 'N/A' && `(${formatType})`}
              </p>
            </div>
            <div>
              <div style={{ minWidth: '32px' }}>
                <Image src={marker} />
              </div>
              <p>
                <a
                  href={
                    `https://www.google.com/maps/dir//${
                      maps_url_query || formatted_address
                        ? formatted_address.replace(/,/g, '').replace(/ /g, '+')
                        : name.replace(/ /g, '+')
                    }` || '#'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {name || formatted_address}
                </a>
              </p>
            </div>
            {!!rules && (
              <div>
                <div style={{ minWidth: '32px' }}>
                  <Image src={refShirt} />
                </div>
                <Button
                  onPress={() => setShowRules(true)}
                  variant="link"
                  padding={0}
                  margin={0}
                  height={0}
                  _text={{
                    fontSize: 14,
                    color: '#1e62b4', // matches <a> color above
                    padding: 0,
                    margin: 0,
                  }}
                  _hover={{
                    _text: { textDecoration: 'none' },
                  }}
                >
                  League Rules
                </Button>
              </div>
            )}
          </div>
          <div className={cx('col-12', 'col-sm-4', styles.item)}>
            <div>
              <div style={{ minWidth: '32px' }}>
                <Image src={date} />
              </div>
              <p>{beginStr}</p>
            </div>
            {num_weeks_estimate && (
              <div>
                <div style={{ minWidth: '32px' }}>
                  <Image src={date} />
                </div>
                <p>
                  {numWeekString}
                  {pluralize('week', num_weeks_estimate + num_playoff_weeks_estimate)}
                </p>
              </div>
            )}
            <div>
              <div style={{ minWidth: '32px' }}>
                <Image src={alarm} />
              </div>
              <p>
                {moment.tz(start_time_estimate, 'HH:mm', timezone).format('h:mma')} -{' '}
                {moment.tz(end_time_estimate, 'HH:mm', timezone).format('h:mma z')}
              </p>
            </div>
          </div>
          {max_team_size > 1 && !hideWaysToRegister && (
            <div className={cx('col-12', 'col-sm-4', styles.item)}>
              <div>
                <div style={{ minWidth: '32px' }}>
                  <Image src={user_single} />
                </div>
                <p>Individual: 1 player</p>
              </div>
              <div>
                <div style={{ minWidth: '32px' }}>
                  <Image src={users} />
                </div>
                <p>Group: 2{maxGroupPlayersStr} players</p>
              </div>
              {allowPrepaidTeams && (
                <div>
                  <div style={{ minWidth: '32px' }}>
                    <Image src={users_team} />
                  </div>
                  <p>Team: {registration.min_team_size}+ players</p>
                </div>
              )}
            </div>
          )}
        </div>
        <Popup className="wider" hidden={!showRules} close={() => setShowRules(false)}>
          <div className={cx('mb-4', styles.rules)}>
            <Image style={{ maxWidth: '36px' }} className="mb-3" src={refShirt} />
            <h4 className="mb-3">League Rules</h4>
            {/* eslint-disable-next-line react/no-danger */}
            <p className="d-inline-block text-left" dangerouslySetInnerHTML={{ __html: rules }} />
          </div>
          <button onClick={() => setShowRules(false)} type="button" tabIndex={0}>
            Close
          </button>
        </Popup>
      </section>
      {!hideDeepLink && (
        <CopyToClipboard className={cx('deep-link')} text={deepLink} onCopy={showCopied}>
          <span
            className="d-inline-flex align-items-center"
            data-for="copy-link"
            data-tip={didCopy ? 'Deep link copied!' : 'Click to copy'}
          >
            <p>{didCopy ? 'Link copied!' : 'Deep Link'}</p>
            <Tooltip id="copy-link" />
          </span>
        </CopyToClipboard>
      )}
    </div>
  );
};

LeagueBasicsPresenter.propTypes = {
  leagueId: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  hideWaysToRegister: PropTypes.bool,
  hideDeepLink: PropTypes.bool,
};

LeagueBasicsPresenter.defaultProps = {
  style: {},
  hideWaysToRegister: false,
  hideDeepLink: false,
};

export default LeagueBasicsPresenter;
