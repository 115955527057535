import { useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import LeagueTeamInfoDetail from './LeagueTeamInfoDetail';
import LEAGUE_STANDINGS_QUERY from './LEAGUE_ROSTER_QUERY';
import styles from './styles.module.scss';
import LeagueTeamPrintView from './LeagueTeamPrintView';
import { Button, PageLoader, FilterSelect } from '../../../components';
import { LeagueProps } from '../../../types';

const cx = classNames.bind(styles);

const LeagueTeamInfo = props => {
  const { league, leagueId } = props;
  const {
    schedule_approved,
    teams_approved,
    availableDivisions = [],
    divisionsActivated,
  } = league ?? {};

  // Used for print view
  const ref = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const [showAllPlayers, setShowAllPlayers] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState(undefined);

  const { data, loading, error, refetch } = useQuery(LEAGUE_STANDINGS_QUERY, {
    skip: !leagueId || !teams_approved,
    fetchPolicy: 'network-only',
    variables: { input: { leagueId } },
  });

  if (loading) return <PageLoader />;
  if (error) return <div>Error! {JSON.stringify(error)}</div>;
  if (!teams_approved)
    return <p className="my-2">This league does not have finalized teams yet.</p>;

  const divisionsOptions = [
    {
      _id: undefined,
      name: 'No Division',
    },
    ...availableDivisions,
  ]?.map(division => ({
    value: division?._id,
    label: division?.name,
  }));

  const { leagueStandings = [] } = data || {};
  const filteredStandings = selectedDivision
    ? leagueStandings?.filter(({ team }) => selectedDivision === team?.division)
    : leagueStandings;

  return (
    <div className={cx('league-roster-detail', 'mt-3')}>
      <div className="d-flex align-items-center mb-3">
        <Button small link onClick={() => setShowAllPlayers(!showAllPlayers)}>
          {showAllPlayers ? 'Hide Player Lists' : 'Show Player Lists'}
        </Button>
        <div>
          <Button small onClick={handlePrint} className="m-2">
            Print View
          </Button>
          <div style={{ display: 'none' }}>
            <LeagueTeamPrintView ref={ref} {...{ league }} />
          </div>
        </div>
        {divisionsActivated && (
          <div>
            <FilterSelect
              options={divisionsOptions}
              onChange={({ value }) => {
                setSelectedDivision(value);
              }}
              value={divisionsOptions.filter(({ value }) => value === selectedDivision)}
              placeholder="Select Division"
              styles={{
                control: base => ({
                  ...base,
                  width: '200px',
                  paddingLeft: '.5rem',
                }),
              }}
            />
          </div>
        )}
      </div>
      <div className={cx('roster-item-wrap', 'header-row')}>
        <div className={cx('main-row')}>
          <div className={cx('left')}>
            <strong>Team Name</strong>
          </div>
          {schedule_approved && (
            <div className={cx('right')}>
              <span className={cx('record')}>Wins</span>
              <span className={cx('record')}>Losses</span>
              <span className={cx('record')}>Ties</span>
              <span className={cx('record')}>Forfeits</span>
              <span className={cx('record')}>Points For</span>
              <span className={cx('record')}>Points Against</span>
              <span className={cx('record')}>Points Differential</span>
            </div>
          )}
        </div>
      </div>
      {filteredStandings.map((teamRank, idx) => (
        <LeagueTeamInfoDetail
          key={teamRank.team._id}
          rank={teamRank}
          showAllPlayers={showAllPlayers}
          seed={idx + 1}
          schedule_approved={schedule_approved}
          refetch={refetch}
        />
      ))}
      {schedule_approved && (
        <div className={cx('points-formula')}>
          <p className={cx('points-formula-header')}>
            <strong>How Teams are ranked:</strong> Win = 3pts, Loss = 0pts, Tie = 1pt, Forfeit =
            -1pt
          </p>
          <p>
            Ties are broken by comparing the <i>Points differential</i>. If the result is a tie the
            next tiebreaker is
            <i> Points For</i> and then, if necessary, by
            <i> Points Against</i> until there is a clear winner.
          </p>
        </div>
      )}
      <div className={cx('key')}>
        <p>* = team captain</p>
      </div>
    </div>
  );
};

LeagueTeamInfo.propTypes = {
  league: LeagueProps.isRequired,
  leagueId: PropTypes.string.isRequired,
};

export default LeagueTeamInfo;
