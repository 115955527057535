import { gql } from '@apollo/client';

export const USER_BY_INFO_QUERY = gql`
  query getUserByInfo($input: UserInfoInput!) {
    getUserByInfo(input: $input) {
      _id
      isVoloPassMember
      membershipExpires
      firstName
      lastName
      gender
      email
      phone_number
      countryCode
      picture
      _created
      birthday
      status
      username
      deactivated
      is_host
    }
  }
`;

export const USER_AVERAGE_RATING_QUERY = gql`
  query user($userId: BsonId!) {
    user(userId: $userId) {
      userId
      averageRatings {
        ability
        friendliness
        punctuality
      }
    }
  }
`;

export const PLAYER_WAIVERS_QUERY = gql`
  query getUserWaivers($userId: ID!) {
    getUserWaivers(userId: $userId) {
      organizationId
      registrant_id
      waiver_name
      waiver_id
      waiver_type
      program_name
      program_id
      season
      signed
      date_signed
      reminders_sent {
        date_sent
        sent_by
      }
    }
  }
`;

export const WAIVER_QUERY = gql`
  query waiver($_id: ID!) {
    waiver(_id: $_id) {
      _id
      name
    }
  }
`;
