import cx from 'classnames';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import tinycolor from 'tinycolor2';
import styles from './styles.module.scss';
import { LeagueProps } from '../../../types';
import colors from '../../../utils/colors';

const LeagueHeader = ({ league, className, style }) => {
  const hostColor = _.get(league, 'organization.brand.primaryColor');
  const primary = hostColor || colors.primary;
  const pBrightness = tinycolor(primary).getBrightness();
  const textColor = pBrightness < 130 ? 'white' : 'black';
  const orgIcon = _.get(league, 'organization.icon');

  return (
    <React.Fragment>
      <div
        style={{
          ...(style || {}),
          ...(league.photoUrl
            ? {
                backgroundImage: `url(${league.photoUrl})`,
              }
            : {
                backgroundColor: primary,
                color: textColor,
              }),
        }}
        className={cx(styles.lgHeader, className)}
      >
        <div className={cx('container')}>
          <div
            className={cx(styles.content, styles.content, {
              [styles.hasPhoto]: !!league.photoUrl,
            })}
          >
            <div className={cx(styles.orgItem, 'd-flex', 'align-items-center')}>
              {orgIcon && (
                <div className={cx(styles.orgIcon)}>
                  <div style={{ backgroundImage: `url(${league.programTag?.icon || orgIcon})` }} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={cx('container', styles.content)}>
        <h1
          className={cx('mb-2', {
            'mt-4': true,
          })}
        >
          {league.name}
        </h1>
        <p>
          Hosted by <b>{league.organization.name}</b>
        </p>
      </div>
    </React.Fragment>
  );
};

LeagueHeader.propTypes = {
  league: LeagueProps.isRequired,
  className: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.shape({}),
};

LeagueHeader.defaultProps = {
  className: [],
  style: {},
};

export default LeagueHeader;
