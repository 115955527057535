import React from 'react';
import PropTypes from 'prop-types';
import withCurrentUser from '../../hocs/withCurrentUser';
import { compose, withStateHandlers, withHandlers, setDisplayName } from 'recompose';
import { graphql } from '@apollo/client/react/hoc';
import withActionStates from '../../hocs/withActionStates';
import { Button } from '../../components';
import { FancyField } from '../../components/fancyFieldsV2';
import classNames from 'classnames/bind';
import { UPDATE_PASSWORD_MUTATION } from './ACCOUNT_FORMS_MUTATIONS_AND_QUERIES';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

const PasswordForm = props => {
  const { current_password, new_password, update, updatePassword, cancel } = props;

  const valid = () => current_password.length > 3 && new_password.length > 3;

  return (
    <div className={cx('account-form', 'password-form')}>
      <div className={cx('fields-wrap')}>
        <label>Current Password</label>
        <FancyField
          noLabel
          grey
          initFocus
          value={current_password}
          type="password"
          placeholder="Current Password"
          onChange={value => update({ current_password: value })}
        />
        <label className="mt-3">New Password</label>
        <FancyField
          noLabel
          grey
          value={new_password}
          type="password"
          placeholder="New Password"
          onChange={value => update({ new_password: value })}
        />
      </div>
      <Button onClick={updatePassword} action disabled={!valid()} wide>
        Save
      </Button>
      <br />
      <a className={cx('cancel-button')} onClick={cancel} role="link" tabIndex={0}>
        Back
      </a>
    </div>
  );
};

PasswordForm.propTypes = {
  current_password: PropTypes.string,
  new_password: PropTypes.string,
  update: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

PasswordForm.defaultProps = {
  current_password: '',
  new_password: '',
};

export default compose(
  setDisplayName('AccountFormsPassword'),
  withCurrentUser(),
  withActionStates({ withAlerts: true }),
  withStateHandlers(
    () => ({
      current_password: '',
      new_password: '',
    }),
    {
      update: state => update => ({
        ...state,
        ...update,
      }),
    }
  ),
  graphql(UPDATE_PASSWORD_MUTATION, { name: 'updatePasswordMutation' }),
  withHandlers({
    updatePassword: props => async () => {
      const {
        updatePasswordMutation,
        setUpdating,
        setError,
        setSuccess,
        currentUser,
        current_password,
        new_password,
      } = props;
      setUpdating(true);
      try {
        await updatePasswordMutation({
          variables: {
            input: {
              _id: currentUser._id,
              currentPassword: current_password,
              newPassword: new_password,
            },
          },
        });
        setSuccess('Successfully updated password.');
      } catch (e) {
        setError(e);
      } finally {
        setUpdating(false);
      }
    },
  })
)(PasswordForm);
