import { gql, type TypedDocumentNode } from '@apollo/client';

type GetDivisionsListData = {
  getDivisionsList: {
    _id: string;
    name: string;
    permanent?: boolean;
  }[];
};

type GetDivisionsListInput = {
  organizationId: string;
};

export const GET_DIVISIONS_LIST: TypedDocumentNode<GetDivisionsListData, GetDivisionsListInput> =
  gql`
    query getDivisionsList($organizationId: ID!) {
      getDivisionsList(organizationId: $organizationId) {
        _id
        name
        permanent
      }
    }
  `;

export type LeagueRosterQueryData = {
  leagueStandings: {
    rank: number;
    WIN: number;
    LOSE: number;
    TIE: number;
    FORFEIT: number;
    points_for: number;
    points_against: number;
    points_differential: number;
    team: {
      _id: string;
      name: string;
      division?: string;
      color: {
        hex?: string;
        name?: string;
      };
      captain?: {
        _id: string;
      };
      coCaptainIds: string[];
      isVoloPassTeam?: boolean;
      registrants: {
        _id: string;
        player: {
          _id: string;
          email: string;
          firstName?: string;
          lastName?: string;
        };
      };
    };
  };
};

type LeagueRosterQueryInput = {
  input: {
    leagueId: string;
    divisionId?: string;
  };
};

const LEAGUE_ROSTER_QUERY: TypedDocumentNode<LeagueRosterQueryData, LeagueRosterQueryInput> = gql`
  query leagueStandings($input: LeagueStandingsInput!) {
    leagueStandings(input: $input) {
      rank
      WIN
      LOSE
      TIE
      FORFEIT
      points_for
      points_against
      points_differential
      team {
        _id
        name
        division
        color {
          hex
          name
        }
        captain {
          _id
        }
        coCaptainIds
        isVoloPassTeam
        registrants {
          _id
          player {
            _id
            email
            firstName
            lastName
            gender
            shirt_size
            zip
            birthday
            phone_number
          }
          waiver {
            waiverId
            signed
            date_signed
            reminders_sent {
              date_sent
              sent_by
            }
          }
        }
      }
    }
  }
`;

export default LEAGUE_ROSTER_QUERY;
