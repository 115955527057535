import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { Tooltip } from '../../../../components';
import tag from '../../../../images/icons/tag-solid.svg';
import { TeamProps, WeekProps, ChildrenNodeProps } from '../../../../types';

const cx = classNames.bind(styles);

const WeekItem = props => {
  const { week, teams, weekTags, weekDescriptions, children } = props;
  const { week_num, notes, byes = [], weekTag } = week || {};

  if (!week) return <span />;

  const weekTagIdx = weekTags.findIndex(t => t === weekTag);
  const weekDescription = weekDescriptions[weekTagIdx];

  return (
    <div className={cx('week-item')}>
      <div className={cx('week-label')}>
        <h2 className={cx('week-num')}>{`Week ${week_num}`}</h2>
        {weekTag && (
          <span
            className={cx('week-tag')}
            {...(weekDescription
              ? {
                  'data-tip': weekDescription,
                  'data-for': weekTag,
                }
              : {})}
          >
            <Tooltip id={weekTag} effect="float" />
            <img src={tag} alt="tag" />
            {weekTag}
          </span>
        )}
        {notes && <span className={cx('notes')}>{notes}</span>}
      </div>
      <div className={cx('week-events')}>
        {children}
        {!!byes?.length && (
          <div className={cx('bye-wrap')}>
            <span>
              <h3 className={cx('byes')}>Byes</h3>
              {byes.map(team => (
                <p key={team}>{teams.find(({ _id }) => _id === team)?.name}</p>
              ))}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

WeekItem.propTypes = {
  children: ChildrenNodeProps,
  week: WeekProps.isRequired,
  teams: PropTypes.arrayOf(TeamProps).isRequired,
  weekTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  weekDescriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

WeekItem.defaultProps = {
  children: null,
};

export default WeekItem;
