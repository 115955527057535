import { gql } from '@apollo/client';

export default gql`
  query leagueBasics($leagueId: ID!) {
    league(_id: $leagueId) {
      _id
      featured_order
      banner_text
      deepLink
      details {
        description
        perks
        features
        week_descriptions
        week_tags
        rules
      }
      organization {
        _id
        name
        logo
        icon
      }
      gender
      activityName
      registration {
        _id
        min_team_size
        max_team_size
        allowPrepaidTeams
      }
      venue {
        _id
        name
        formatted_address
        city
        neighborhood
      }
      neighborhood {
        _id
        name
      }
      timezone
      start_date
      start_time_estimate
      end_time_estimate
      days_of_week_estimate
      num_weeks_estimate
      num_playoff_weeks_estimate
      formatType
      divisionDescriptions
      hasDivisions
      sportFormatDetails {
        competitionLevels {
          level
          description
        }
      }
    }
  }
`;
