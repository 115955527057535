/* eslint-disable no-console */
/* eslint-disable global-require */
const defaultIcon = require('../images/perks/New_perk.svg');

const perkIcon = perkName => {
  try {
    switch (perkName) {
      case 'referees':
      case 'Referees':
        return require('../images/perks/Referee.svg');
      case 't_shirt':
      case 'T-Shirt':
        return require('../images/perks/Tshirt.svg');
      case 'dj_on_site':
      case 'DJ on Site':
        return require('../images/perks/DJ_on_Site.svg');
      case 'bar_on_site':
      case 'Bar On Site':
        return require('../images/perks/Bar_on_Site.svg');
      case 'free_street_parking': // will update with new league landing page
      case 'Free Street Parking': // will update with new league landing page
      case 'street_parking':
      case 'Street Parking':
        return require('../images/perks/Street_Parking.svg');
      case 'parking_on_site':
      case 'Parking on Site':
        return require('../images/perks/Parking_on_Site.svg');
      case 'indoor_space':
      case 'Indoor Space':
        return require('../images/perks/Indoor_Space.svg');
      case 'outdoor_space':
      case 'Outdoor Space':
        return require('../images/perks/Outdoor_Space.svg');
      case 'turf':
      case 'Turf':
        return require('../images/perks/Turf.svg');
      case 'lights':
      case 'Lights':
        return require('../images/perks/Lights.svg');
      case 'city_wide_tournament_feeder':
      case 'City Wide Tournament Feeder':
        return require('../images/perks/City_wide_tournament_feeder.svg');
      case 'bogo_pitchers':
      case 'BOGO Pitchers':
        return require('../images/perks/BOGO_Pitchers.svg');
      case 'sponsor_bar':
      case 'Sponsor Bar':
        return require('../images/perks/Sponsor_Bar.svg');
      case 'dogs_welcome':
      case 'Dogs Welcome':
        return require('../images/perks/Pets_Welcome.svg');
      case 'bring_your_own_case':
      case 'Bring Your Own Case (no glass)':
        return require('../images/perks/Bring_your_own_case_(no_glass).svg');
      case 'reserved_tables':
      case 'Reserved Tables':
        return require('../images/perks/Researved_tables.svg');
      case 'dedicated_social_host':
      case 'Dedicated Social Host':
        return require('../images/perks/Dedicated_social_hosts.svg');
      case 'volo_exclusive_bar_deals':
      case 'Volo Exclusive Bar Deals':
        return require('../images/perks/Volo_exclusive_bar_deals.svg');
      case 'all_teams_make_playoffs':
      case 'All Teams Make Playoffs':
        return require('../images/perks/all_teams_make_playoffs.svg');
      case 'flip_cup_count_in_standings':
      case 'Flip Cup Count in Standings':
        return require('../images/perks/flip_cup_count_in_standings.svg');
      case 'near_public_transportation':
      case 'Near Public Transportation':
        return require('../images/perks/Near_public_transportation.svg');
      case 'new_facility':
      case 'New Facility':
        return require('../images/perks/New_facility.svg');
      case 'popular_venue':
      case 'Popular Venue':
        return require('../images/perks/Popular_Venue.svg');
      case 'licensed_referee':
      case 'Licensed Referee':
        return require('../images/perks/Licensed_Referee.svg');
      case 'sponsor_perks':
      case 'Sponsor Perks':
        return require('../images/perks/Sponsored_perks.svg');
      case 'waterfront_view':
      case 'Waterfront View':
        return require('../images/perks/Waterfront_View_to_venue.svg');
      case 'discounted_volo_events':
      case 'Discounted Volo Events':
        return require('../images/perks/Discounted_volo_events.svg');
      case 'new_perk':
      case 'New Perk':
        return require('../images/perks/New_perk.svg');
      case 'walking_distance_to_bar':
      case 'Walking Distance to Bar':
        return require('../images/perks/Walking_Distance_to_Bar.svg');
      default:
        return defaultIcon;
    }
  } catch (err) {
    console.error(err);
    return defaultIcon;
  }
};

const getPerkIcon = perkName => {
  try {
    const img = perkIcon(perkName);
    if (typeof img === 'object') {
      // WEIRD FIX FOR WEIRD ISSUE WTF
      return img.default;
    }
    return img;
  } catch (e) {
    console.error(e);
    return defaultIcon;
  }
};

export default getPerkIcon;
