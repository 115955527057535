import { gql } from '@apollo/client';

export default gql`
  query league($leagueId: ID!) {
    league(_id: $leagueId) {
      _id
      schedule_approved
      days_of_week_estimate
      tournament {
        _id
        subSchedule {
          _id
        }
        league_id
        url
        challonge_id
        tournament_type
        name
        start_at
        games {
          _id
          start_time
          end_time
          is_tournament
          scores {
            team_id
            forfeit
            score
            _id
          }
          location {
            _id
            name
            timezone
          }
          field_name
          winner
          forfeitter
          tournament_round
          teamIds
          date
        }
      }
      teams {
        _id
        name
        color {
          hex
          name
        }
      }
    }
  }
`;

export const UPDATE_TOURNAMENT_DATE = gql`
  mutation updateTournament($input: UpdateTournamentInput!) {
    updateTournament(input: $input) {
      tournament {
        _id
      }
    }
  }
`;

export const REMOVE_TOURNAMENT_MUTATION = gql`
  mutation deleteSubSchedule($input: DeleteSubScheduleInput!) {
    deleteSubSchedule(input: $input) {
      league {
        _id
      }
    }
  }
`;
