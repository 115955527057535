import React from 'react';
import styled from 'styled-components';
import { pureWhiteVoloPassLogo, lightBlueCheckmark } from '../../../../../images/VoloPass';
import {
  StyledCard,
  CenteredDiv,
  StyledImage,
  StyledFixedHeightImage,
  ParagraphText,
  fontWeights,
  colors,
} from '../../../../../components/styledComponents';

const perks = {
  Unlimited: 'pickups and drop-ins',
  Discounts: 'on leagues ($20+off)',
  Access: 'to exclusive events',
};

const VoloPassPerksLogoDiv = styled(CenteredDiv)`
  && {
    height: 70px;
    margin: 12px;
  }
`;
const VoloPassPerksCard = styled(StyledCard)`
  && {
    padding: 10px;
    height: 300px;
  }
`;

const VoloPassPerksChecklist = () => (
  <VoloPassPerksCard backgroundColor={colors.darkBlue} borderRadius="10px">
    <VoloPassPerksLogoDiv>
      <StyledFixedHeightImage src={pureWhiteVoloPassLogo} alt="volo pass logo" height="100%" />
    </VoloPassPerksLogoDiv>
    <div className="col">
      {Object.entries(perks).map(([key, val]) => (
        <div className="row" key={key}>
          <StyledImage
            src={lightBlueCheckmark}
            alt="light-blue-check-mark"
            marginRight="15px"
            marginBottom="5px"
          />
          <ParagraphText
            paddingTop="7px"
            marginTop="7px"
            marginBottom="20px"
            textTransform="uppercase"
            fontSize="15px"
            paddingRight="2px"
            fontColor={colors.white}
            fontWeight={fontWeights.bold}
          >
            {key}
          </ParagraphText>
          <ParagraphText
            paddingTop="7px"
            marginTop="7px"
            marginBottom="25px"
            textTransform="uppercase"
            fontColor={colors.white}
            fontSize="15px"
          >
            {val}
          </ParagraphText>
        </div>
      ))}
    </div>
  </VoloPassPerksCard>
);

export default VoloPassPerksChecklist;
