import { Colors, Skeleton, Stack } from '@rivallapp/volosports-components';

const skeletonProps = {
  borderRadius: 'md',
  size: '12',
  w: '100%',
  startColor: Colors.greys.medium,
};

const ShiftLogsListSkeleton = () => {
  return (
    <Stack space={2} mx={3} my={1}>
      <Skeleton {...skeletonProps} />
      <Skeleton {...skeletonProps} />
      <Skeleton {...skeletonProps} />
      <Skeleton {...skeletonProps} />
      <Skeleton {...skeletonProps} />
      <Skeleton {...skeletonProps} />
      <Skeleton {...skeletonProps} />
      <Skeleton {...skeletonProps} />
      <Skeleton {...skeletonProps} />
      <Skeleton {...skeletonProps} />
    </Stack>
  );
};
export default ShiftLogsListSkeleton;
