import { gql } from '@apollo/client';

const LEAGUE_SHIRT_QUERY = gql`
  query league($leagueId: ID!) {
    league(_id: $leagueId) {
      _id
      name
      registrantObj {
        _id
        isDropin
        stagedTeam {
          _id
          name
          color {
            name
            hex
          }
        }
        player {
          _id
          shirt_size
        }
      }
    }
  }
`;

export default LEAGUE_SHIRT_QUERY;
