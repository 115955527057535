import { faWhistle } from '@fortawesome/pro-light-svg-icons/faWhistle';
import { faUserGroup } from '@fortawesome/pro-light-svg-icons/faUserGroup';
import { faWalking } from '@fortawesome/pro-light-svg-icons/faWalking';
import { faShirt } from '@fortawesome/pro-light-svg-icons/faShirt';
import { faBaseballBatBall } from '@fortawesome/pro-light-svg-icons/faBaseballBatBall';
import { faCourtSport } from '@fortawesome/pro-light-svg-icons/faCourtSport';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons/faCalendarDays';
import { faComputer } from '@fortawesome/pro-light-svg-icons/faComputer';
import { faCameraRetro } from '@fortawesome/pro-light-svg-icons/faCameraRetro';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { ShiftLogSection } from '../../../../../shared/shift-log-enums';

const useIconFromShiftLogSection = (section: ShiftLogSection) => {
  switch (section) {
    case ShiftLogSection.REFEREES:
      return faWhistle;
    case ShiftLogSection.HOSTS:
      return faUserGroup;
    case ShiftLogSection.PLAYERS:
      return faWalking;
    case ShiftLogSection.SHIRTS:
      return faShirt;
    case ShiftLogSection.EQUIPMENT:
      return faBaseballBatBall;
    case ShiftLogSection.VENUE:
      return faCourtSport;
    case ShiftLogSection.SPONSOR_BAR:
      return faBuilding;
    case ShiftLogSection.DAILY_PROGRAMMING:
      return faCalendarDays;
    case ShiftLogSection.TECHNOLOGY:
      return faComputer;
    case ShiftLogSection.CONTENT_CAPTURE:
      return faCameraRetro;
    case ShiftLogSection.MISCELLANEOUS:
    default:
      return faQuestion;
  }
};

export default useIconFromShiftLogSection;
