import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment-timezone';

import { formatCents } from '../../utils';
import {
  ACTIVATE_MEMBERSHIP,
  ACTIVE_VOLO_PASS_MEMBERSHIP,
  CANCEL_MEMBERSHIP,
  MEMBERSHIP_GET_PROMO,
  RESUME_MEMBERSHIP,
  START_TRIAL_MEMBERSHIP,
  UPDATE_USER_PLAN,
  USER_QUERY,
  VP_ORG_TIERS_QUERY,
} from './QUERIES';
import { UPDATE_USER_HOME_ORG } from '../../features/PlayerDetails/PLAYER_DETAILS_MUTATIONS';

/** @deprecated 2024-08-01 - This hook relies a lot on `membershipExpires`. Use the newer fields instead. */
const useVoloPassDetails = props => {
  const { setUpdating, setSuccess, setError } = props;

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [planStatus, setPlanStatus] = useState(null);
  const [planDetails, setPlanDetails] = useState({
    selectedOrganizationId: null,
    planId: null,
    promo: '',
    enteringPromo: false,
    promoData: null,
  });

  const updatePlanDetails = useCallback(
    update => setPlanDetails({ ...planDetails, ...update }),
    [planDetails, setPlanDetails]
  );

  const [membershipGetPromoQuery] = useMutation(MEMBERSHIP_GET_PROMO);
  const [startMembershipMutation] = useMutation(ACTIVATE_MEMBERSHIP);
  const [startTrialMembership] = useMutation(START_TRIAL_MEMBERSHIP);
  const [cancelMembershipMutation] = useMutation(CANCEL_MEMBERSHIP);
  const [updateUserPlanMutation] = useMutation(UPDATE_USER_PLAN);
  const [reinstateMembershipMutation] = useMutation(RESUME_MEMBERSHIP);
  const [updateUserHomeOrganization] = useMutation(UPDATE_USER_HOME_ORG);

  const { data, loading, error } = useQuery(VP_ORG_TIERS_QUERY);

  const { data: userData, loading: userLoading, error: userError, refetch } = useQuery(USER_QUERY);
  useEffect(() => {
    if (!userLoading && userData && !planDetails.selectedOrganizationId) {
      updatePlanDetails({
        selectedOrganizationId: userData.currentUser?.homeOrganization,
      });
    }
    // updatePlanDetails is purposefully excluded from the deps because it causes an infinite rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userLoading, planDetails.selectedOrganizationId]);

  const {
    data: activeData,
    loading: activeLoading,
    error: activeError,
  } = useQuery(ACTIVE_VOLO_PASS_MEMBERSHIP, {
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (!activeLoading && activeData) {
      // this is null if they have never registered for VP
      setPlanStatus(activeData.activeVoloPassMembership?.status);
    }
  }, [activeData, activeLoading]);

  const { membershipExpires } = userData?.currentUser || {};

  const expiredDate = moment(+membershipExpires).format('MM/DD/YYYY');
  const afterExpireDate = moment().isAfter(moment(expiredDate).endOf('day'));

  const { organizationList = [] } = data || {};
  const cityOptions = organizationList.map(org => ({ value: org._id, label: org.name }));

  const { promo, promoData, planId, selectedOrganizationId } = planDetails;

  const selectedCity = organizationList.find(o => o._id === selectedOrganizationId);

  const checkPayment = async () => {
    // in case someone just added their card info, have to refetch
    const { data: refetchData } = await refetch();
    const paymentSources = refetchData.currentUser?.paymentSources || [];
    const defaultSource = paymentSources.find(p => p.isDefault && !p.isExpired);
    if (!defaultSource) {
      throw new Error('Please make sure you have at least one payment on file.');
    }
  };
  const validatePromo = async () => {
    try {
      if (!promo) return;
      updatePlanDetails({ promoData: null });
      const { data: resData } = await membershipGetPromoQuery({
        variables: { input: { promoId: promo } },
      });
      updatePlanDetails({ promoData: resData?.membershipGetPromo });
    } catch (e) {
      setError(e);
    }
  };
  const getPromoText = () => {
    if (!promoData) return '';
    const { amount_off, percent_off, duration, duration_in_months } = promoData;
    switch (duration) {
      case 'repeating':
        return `${amount_off ? formatCents(amount_off) : ''} ${percent_off ? `${percent_off}%` : ''} off Volo Pass for ${duration_in_months} months!`;
      case 'forever':
        return `${amount_off ? formatCents(amount_off) : ''} ${percent_off ? `${percent_off}%` : ''} off Volo Pass every month!`;
      case 'once':
      default:
        return `${amount_off ? formatCents(amount_off) : ''} ${percent_off ? `${percent_off}%` : ''} off Volo Pass for 1 month!`;
    }
  };
  const startPlan = async () => {
    try {
      setUpdating(true);
      await checkPayment();
      if (!planId) throw new Error('Please choose a plan before saving.');
      await updateUserHomeOrganization({
        variables: { input: { organizationId: selectedOrganizationId } },
      });
      const { data: activateData } = await startMembershipMutation({
        variables: {
          input: {
            planId,
            ...(promoData?.valid ? { promoCode: promo } : {}),
          },
        },
      });
      await updateUserPlanMutation({ variables: { input: { chosenPlan: 'pass' } } });
      await refetch();
      setPlanStatus(activateData?.startMembership?.status);
      setSuccess('Successfully started Volo Pass');
    } catch (e) {
      setError(e);
    } finally {
      setUpdating(false);
    }
  };
  const reinstatePlan = async () => {
    try {
      setUpdating(true);
      await checkPayment();
      const { data: reinstateData } = await reinstateMembershipMutation();
      await updateUserPlanMutation({ variables: { input: { chosenPlan: 'pass' } } });
      setPlanStatus(reinstateData?.reinstateMembership?.status);
      setSuccess('Successfully reinstated Volo Pass.');
    } catch (e) {
      setError(e);
    } finally {
      setUpdating(false);
    }
  };
  const cancelPlan = async () => {
    try {
      setUpdating(true);
      const { data: cancelData } = await cancelMembershipMutation();
      await updateUserPlanMutation({ variables: { input: { chosenPlan: 'base' } } });
      setPlanStatus(cancelData?.cancelMembership?.status);
      setSuccess('Successfully cancelled Volo Pass.');
    } catch (e) {
      setError(e);
    } finally {
      setUpdating(false);
    }
  };

  return {
    acceptedTerms,
    afterExpireDate,
    cancelPlan,
    cityOptions,
    error: error || userError || (activeError && activeError.message !== 'not_found'),
    getPromoText,
    loading: loading || activeLoading || userLoading,
    membershipExpires,
    planDetails,
    planStatus,
    reinstatePlan,
    selectedCity,
    setAcceptedTerms,
    startMembershipMutation,
    startPlan,
    startTrialMembership,
    updatePlanDetails,
    updateUserPlanMutation,
    validatePromo,
  };
};

export default useVoloPassDetails;
