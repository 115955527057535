import { useState } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import { FontAwesomeIcon, Text, Row, Button, Skeleton } from '@rivallapp/volosports-components';
import { faAdd } from '@fortawesome/pro-solid-svg-icons/faAdd';
import styles from './payment.module.scss';
import PLAYER_PAYMENT_QUERY, { type CurrentUserPaymentSourcesData } from './PLAYER_PAYMENT_QUERY';
import { Error } from '../../components';
import PaymentMethodModal from './PaymentMethodModal';
import { type PaymentSource } from './EditPaymentMethods/EditPaymentMethods';
import { formatCardBrandClassName } from './EditPaymentMethods/PaymentSourceItem';

const AddIcon = <FontAwesomeIcon icon={faAdd} />;

const PaymentForm = () => {
  const [paymentMethodModalVisible, setPaymentMethodModalVisible] = useState(false);

  const { data, loading, error } = useQuery<CurrentUserPaymentSourcesData>(PLAYER_PAYMENT_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <Skeleton h={12} />;
  if (error) return <Error error={error} />;

  const { paymentSources = [] } = data?.currentUser || {};
  const defaultSource = paymentSources.find((p: PaymentSource) => p.isDefault && !p.isExpired);

  return (
    <>
      {defaultSource ? (
        <Row
          p={2}
          rounded="md"
          borderWidth={1}
          alignItems="center"
          borderColor="gray.100"
          backgroundColor="gray.50"
          justifyContent="space-between"
        >
          <Row alignItems="center" flex={1}>
            <div
              className={cx(styles.brand, styles[formatCardBrandClassName(defaultSource.brand)])}
            />
            <Text fontSize="xs">We&apos;ll charge the card ending in {defaultSource.last4}.</Text>
          </Row>
          <Button
            ml={2}
            h={35}
            w={35}
            rounded="full"
            variant="outline"
            _text={{ fontSize: '2xs' }}
            onPress={() => setPaymentMethodModalVisible(true)}
          >
            Edit
          </Button>
        </Row>
      ) : (
        <Row
          p={2}
          minH="54px"
          rounded="md"
          borderWidth={1}
          alignItems="center"
          borderColor="gray.100"
          backgroundColor="gray.50"
          justifyContent="space-between"
        >
          <Text fontSize="xs" ml={1}>
            Add your payment method.
          </Text>
          <Button
            rounded="full"
            variant="outline"
            startIcon={AddIcon}
            _icon={{ size: 'xs' }}
            _important={{ padding: 2 }}
            onPress={() => setPaymentMethodModalVisible(true)}
          />
        </Row>
      )}
      <PaymentMethodModal
        paymentSources={paymentSources}
        isOpen={paymentMethodModalVisible}
        onClose={() => setPaymentMethodModalVisible(false)}
      />
    </>
  );
};

export default PaymentForm;
