import { useQuery, gql } from '@apollo/client';

import { useDiscoverStore } from '../../../zustand-stores';
import { shallow } from 'zustand/shallow';

export const DISCOVER_TOP_SPORTS_LIMIT = 7;

const GET_DISCOVER_TOP_SPORTS = gql`
  query DiscoverTopSports($input: DiscoverInput!) {
    discoverTopSports(input: $input) {
      sportNames
    }
  }
`;

const useDiscoverTopSports = cityName => {
  const { defaultProgramTypes, selectedProgramTypes } = useDiscoverStore(
    store => ({
      defaultProgramTypes: store.defaultProgramTypes,
      selectedProgramTypes: store.filters.selectedProgramTypes,
    }),
    shallow
  );

  const programTypes = selectedProgramTypes.length ? selectedProgramTypes : defaultProgramTypes;

  const { data, loading, error } = useQuery(GET_DISCOVER_TOP_SPORTS, {
    skip: !cityName,
    variables: {
      input: { cityName, programTypes },
    },
  });

  const { discoverTopSports } = data || {};
  const sportNames = discoverTopSports?.sportNames || [];

  return { sportNames, loading, error };
};

export default useDiscoverTopSports;
