import { type ComponentProps, type FC, useState } from 'react';
import {
  MemberExclusive,
  MembershipStatus,
  StripeSubscriptionStatusEnum,
  useUserMembershipStatus,
} from '@rivallapp/volosports-components';
import { useAuthStore } from '../../zustand-stores';
import Portal from '../Portal';
import { SignUpModal } from '../../apps/Anon/HomepageV2/VoloPassV2/modals';
import LoginModal from '../LoginModal';

type MemberExclusiveProps = ComponentProps<typeof MemberExclusive>;

const DEFAULT_BUTTON_PROPS: MemberExclusiveProps['_buttonProps'] = {
  px: 1,
  height: '20px',
  _text: { fontSize: 14 },
};

type Props = Pick<MemberExclusiveProps, '_buttonProps' | '_iconProps' | '_containerProps'>;

const MemberExclusiveButton: FC<Props> = props => {
  const { isVoloPassMember } = useUserMembershipStatus();

  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [voloPassSignupModalVisible, setVoloPassSignupModalVisible] = useState(false);

  const isAuthenticated = useAuthStore(store => store.isAuthenticated);

  const handlePressMemberExclusive = () => {
    if (!isAuthenticated) {
      setLoginModalVisible(true);
    } else if (!isVoloPassMember) {
      setVoloPassSignupModalVisible(true);
    } else {
      // This case is handled internally by `MemberExclusive`
    }
  };

  return (
    <>
      <MemberExclusive
        hasVoloPass={isVoloPassMember}
        onPress={handlePressMemberExclusive}
        _buttonProps={DEFAULT_BUTTON_PROPS}
        membershipStatus={
          // The modal will only be displayed if the user has Volo Pass.
          // Also it is expecting the display label instead of the enum value.
          isVoloPassMember ? MembershipStatus[StripeSubscriptionStatusEnum.ACTIVE].label : ''
        }
        {...props}
      />
      <Portal>
        {isAuthenticated ? (
          <SignUpModal
            initAnnual={false}
            showSignUpModal={voloPassSignupModalVisible}
            setShowSignUpModal={setVoloPassSignupModalVisible}
          />
        ) : (
          <LoginModal showLogin={loginModalVisible} setShowLogin={setLoginModalVisible} />
        )}
      </Portal>
    </>
  );
};

export default MemberExclusiveButton;
