import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TOGGLE_USER_ACCOUNT_STATUS } from '../../PLAYER_DETAILS_MUTATIONS';
import { useMutation } from '@apollo/client';
import { AreYouSure, Button } from '../../../../components';
import useActionStates from '../../../../hooks/useActionStates';

const PlayerDeactivate = ({
  _id: userId,
  deactivated,
  goBackAction,
  userListRefetch,
  clearSelectedUser,
}) => {
  const [showCheck, setShowCheck] = useState(false);

  const [toggleAccountStatusMutation, { loading }] = useMutation(TOGGLE_USER_ACCOUNT_STATUS);

  const { setError, setSuccess } = useActionStates({ withAlerts: true });

  return (
    <>
      <AreYouSure
        body={`Are you sure you want to ${
          deactivated
            ? 'reactivate this user? They will immediately be able to login.'
            : 'deactivate this user? They will no longer be able to login.'
        }`}
        hidden={!showCheck}
        yesAction={async () => {
          try {
            await toggleAccountStatusMutation({
              variables: {
                input: {
                  userId,
                  type: deactivated ? 'ACTIVATE' : 'DEACTIVATE',
                },
              },
            });
            await userListRefetch();
            setSuccess(`Player ${deactivated ? 're' : 'de'}activated!`);
          } catch (e) {
            setError(e);
            throw new Error('Unable to deactivate account for some reason');
          } finally {
            clearSelectedUser();
          }
        }}
        close={() => setShowCheck(false)}
      />
      <div className="text-center">
        <h3 className="m-2">Deactivate User</h3>
        <div className="">
          <Button
            {...{
              ...(deactivated ? { success: true } : { danger: true }),
            }}
            disabled={loading}
            square
            onClick={() => setShowCheck(true)}
            className="col my-2"
          >
            {deactivated ? 'Reactivate' : 'Deactivate'}
          </Button>
          <a
            className="text-volo-danger font-italic"
            role="button"
            tabIndex={0}
            onClick={() => goBackAction()}
          >
            Go Back
          </a>
        </div>
      </div>
    </>
  );
};

PlayerDeactivate.propTypes = {
  _id: PropTypes.string.isRequired,
  deactivated: PropTypes.bool,
  goBackAction: PropTypes.func.isRequired,
  userListRefetch: PropTypes.func.isRequired,
  clearSelectedUser: PropTypes.func.isRequired,
};

PlayerDeactivate.defaultProps = {
  deactivated: false,
};

export default PlayerDeactivate;
