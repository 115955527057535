import { useMutation } from '@apollo/client';
import classNames from 'classnames/bind';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { EDIT_GAME_SCORES } from './LEAGUE_TEAMS_QUERY';
import styles from './styles.module.scss';
import { Button, ColorIndicator } from '../../../../components';
import { Checkbox, FancyField } from '../../../../components/fancyFieldsV2';
import withActionStates from '../../../../hocs/withActionStates';

const cx = classNames.bind(styles);

const EditGameScores = props => {
  const {
    updating,
    setError,
    setSuccess,
    closePopup,
    setUpdating,
    refetch,
    game: { _id: gameId, teamIds, scores },
    teams,
  } = props;

  const initialScores = teamIds.map(teamId => {
    const savedScores = scores.find(({ team_id }) => team_id === teamId);
    return {
      // if score exists, add teamId from team_id
      ...(savedScores
        ? { ...savedScores, teamId: savedScores.team_id }
        : { score: 0, forfeit: false, teamId }),
      team: teams.find(({ _id }) => _id === teamId),
    };
  });

  const [currentScores, updateEditScores] = useState(initialScores);
  const [gameEditScore] = useMutation(EDIT_GAME_SCORES);

  const updateScores = (updatedScore, idx) => {
    const duplicateScores = [...currentScores];
    duplicateScores[idx] = {
      ...duplicateScores[idx],
      ...updatedScore,
    };
    updateEditScores(duplicateScores);
  };

  const saveScores = async () => {
    setUpdating(true);
    try {
      const [teamScore1, teamScore2] = currentScores;
      await gameEditScore({
        variables: {
          input: {
            gameId,
            teamScore1: _.pick(teamScore1, ['teamId', 'score', 'forfeit']),
            teamScore2: _.pick(teamScore2, ['teamId', 'score', 'forfeit']),
          },
        },
      });
      setSuccess('Updated score.');
      closePopup();
      if (refetch) refetch();
    } catch (err) {
      setError(err);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className={cx('edit-score-popup')}>
      <h2>Edit Score</h2>
      {currentScores.map(({ score = 0, forfeit = false, team }, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={idx} className={cx('field-wrap')}>
          <ColorIndicator team={team} style={{ marginRight: '3px' }} />
          <h3 className={cx('team-name')}>{team?.name}</h3>
          <div className="row no-gutters">
            <div className="col-6 my-auto">
              <Checkbox
                label="Forfeit?"
                className={cx('forfeit-button')}
                value={!!forfeit}
                onChange={() => updateScores({ forfeit: !forfeit, score: 0 }, idx)}
              />
            </div>
            <div className="col-6 my-auto">
              <FancyField
                blockStyle
                type="number"
                noLabel
                className={cx('score-input')}
                onChange={value => updateScores({ forfeit: false, score: +value }, idx)}
                value={score || 0}
              />
              <label>Score</label>
            </div>
          </div>
        </div>
      ))}
      <Button primary className={cx('save')} onClick={saveScores} disabled={updating}>
        {updating ? 'Saving...' : 'Save'}
      </Button>
      <small className="float-right pt-3">gameId: {gameId}</small>
    </div>
  );
};

EditGameScores.propTypes = {
  closePopup: PropTypes.func.isRequired,
  game: PropTypes.shape({
    _id: PropTypes.string,
    scores: PropTypes.arrayOf(PropTypes.shape({})),
    teamIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  refetch: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  setUpdating: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updating: PropTypes.bool.isRequired,
};

export default withActionStates({ withAlerts: true })(EditGameScores);
