import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { ColorIndicator } from '../../../../components';
import blackCup from '../../../../images/icons/cup.svg';
import cup from '../../../../images/icons/main/cup.svg';
import { GameProps, TeamProps } from '../../../../types';

import Picture from '../../../../components/Picture/Picture';

const cx = classNames.bind(styles);

const GameItem = ({ game, teams, editScore, timezone }) => {
  const { start_time, scores = [], location, field_name, teamIds } = game;

  if (!teams) {
    return <p>Unknown teams</p>;
  }

  const hasScore = !!scores.length;

  return (
    <div className={cx('game-item', hasScore ? 'score-error' : '')}>
      <p className={cx('start-time')}>{moment.tz(start_time, timezone).format('h:mma z')}</p>
      <span>
        {teamIds.map(teamId => {
          const team = teams.find(({ _id }) => _id === teamId);
          const { name: teamName, color, isVoloPassTeam } = team ?? {};
          const { score: s = 0, forfeit = false } =
            scores.find(({ team_id }) => team_id === teamId) ?? {};
          const score = String(+s);
          return (
            <p
              className={cx('team-placeholder', { forfeit, unscored: hasScore && !score })}
              key={`${teamId}tph`}
            >
              <ColorIndicator
                {...team}
                style={{ height: '8px', width: '8px', marginRight: '2px' }}
              />
              <span className={cx('team-color')}>({color?.name || 'N/A'})</span>
              <span className={cx('team-name-score')}>
                {forfeit ? <s>{teamName}</s> : <span>{teamName}</span>}
                {isVoloPassTeam && (
                  <Picture
                    src="https://rivall-public.s3-us-west-1.amazonaws.com/home-page/VoloPass.png"
                    alt="volo pass"
                  />
                )}
                {hasScore && <b> ({score || '?'})</b>}
              </span>
            </p>
          );
        })}
      </span>
      {location?.name && <p>{location.name}</p>}
      {field_name && <p className={cx('sub-location')}>{field_name}</p>}
      <div className={cx('game-tools')}>
        <img
          alt="Edit score"
          className={cx('edit-score-trigger')}
          src={hasScore ? cup : blackCup}
          onClick={() => editScore(game)}
        />
      </div>
    </div>
  );
};

export default GameItem;

GameItem.propTypes = {
  teams: PropTypes.arrayOf(TeamProps).isRequired,
  game: GameProps.isRequired,
  timezone: PropTypes.string.isRequired,
  editScore: PropTypes.func.isRequired,
};
