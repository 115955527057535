import * as _ from 'lodash-es';
import React, { forwardRef } from 'react';
import { LeagueProps } from '../../../types';
import { ColorIndicator } from '../../../components';

const LeagueTeamPrintView = forwardRef((props, ref) => {
  const { league } = props;
  const { teams } = league || {};

  const keys = ['firstName', 'lastName', 'zip', 'birthday', 'shirt_size', 'gender'];

  return (
    <div ref={ref} className="m-2">
      {teams?.map(team => (
        <div key={team._id}>
          <h2 style={{ margin: '30px 0 10px' }}>
            {team.name}
            {team.color.name ? (
              <ColorIndicator
                {...teams?.team}
                style={{ height: '15px', width: '15px', margin: '0 -2px 0 5px' }}
              />
            ) : null}
            <span className="ml-2" style={{ fontSize: '14px', color: '#b4b6b6' }}>
              ({_.get(team.color, 'name')}){' '}
            </span>
          </h2>
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Zip</th>
                <th>Birthday</th>
                <th>Shirt Size</th>
                <th>Gender</th>
              </tr>
            </thead>
            <tbody>
              {team.players
                ? _.map(team.players, (player, key) => (
                    <tr key={key}>
                      {_.map(keys, (e, idx) => (
                        <td key={idx}>{player[e] || ''}</td>
                      ))}
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
});

LeagueTeamPrintView.propTypes = {
  league: LeagueProps.isRequired,
};

export default LeagueTeamPrintView;
