import { emptyCityHero } from '../../../shared/empty-objects';
import { omitDeepTypename } from '../../../utils/pass-graphql';

export const HeroSlidesActions = {
  SET_SLIDES_FROM_PROPS: 'SET_SLIDES_FROM_PROPS',
  UPDATE_SLIDES: 'UPDATE_SLIDES',
  CREATE_NEW_SLIDE: 'CREATE_NEW_SLIDE',
  DELETE_SLIDE: 'DELETE_SLIDE',
  REARRANGE_SLIDES: 'REARRANGE_SLIDES',
};

export const initialState = {
  slides: [],
};

export default (state, action) => {
  switch (action.type) {
    case HeroSlidesActions.SET_SLIDES_FROM_PROPS:
      return { ...omitDeepTypename(action.update) };
    case HeroSlidesActions.UPDATE_SLIDES: {
      const { slides = [] } = state;
      const { index, update } = action || {};
      slides[index] = { ...slides[index], ...update };
      return { slides };
    }
    case HeroSlidesActions.CREATE_NEW_SLIDE: {
      const { slides = [] } = state;
      const newSlides = [...slides, emptyCityHero];
      return { slides: newSlides };
    }
    case HeroSlidesActions.DELETE_SLIDE: {
      const { slides = [] } = state;
      const { index } = action || {};
      slides.splice(index, 1);

      return { slides };
    }
    case HeroSlidesActions.REARRANGE_SLIDES: {
      const { slides = [] } = state;
      const { direction, index } = action;
      const storedSlide = slides.splice(index, 1);
      slides.splice(index + direction, 0, storedSlide[0]);
      return { slides };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
