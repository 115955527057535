import PropTypes from 'prop-types';
import React from 'react';
import { useQuery } from '@apollo/client';
import { DISCOVER_PERKS } from './FILTER_QUERIES';
import { startCase } from '../../shared/string-manipulations';
import { getParamsFromUrl, withPropsFromUrl } from './utils';
import { FilterSelect, Error } from '../../components';

const PerkFilter = props => {
  const { disabled, onSelect, isMulti, className, updateSearchUrls, cityName, isHost, skip } =
    props;

  const {
    data: perksData = {},
    loading: perksLoading,
    error: perksError,
  } = useQuery(DISCOVER_PERKS, {
    skip: skip || !cityName,
    fetchPolicy: 'cache-and-network',
    variables: getParamsFromUrl(props, isHost),
  });

  const { discoverPerks = {} } = perksData;
  const { perks = [] } = discoverPerks;

  const perksOptions = perks.map((value = '') => ({
    label: startCase((value || '')?.replace(/_/g, ' ')),
    value,
  }));

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : (v = []) => {
          updateSearchUrls({ perks: v.map(({ value }) => value) });
        };

  if (perksError) return <Error error={perksError} />;

  return (
    <FilterSelect
      short
      shadow
      labelSingular="Perk"
      labelPlural="Perks"
      options={perksOptions}
      value={perksOptions.filter(({ value }) => perks.includes(value)) || null}
      onChange={onChange}
      disabled={disabled || perksLoading}
      multi={isMulti}
      className={className}
    />
  );
};

PerkFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  cityName: PropTypes.string,
  isHost: PropTypes.bool,
  skip: PropTypes.bool,
};

PerkFilter.defaultProps = {
  onSelect: false,
  disabled: false,
  isMulti: false,
  className: '',
  cityName: '',
  isHost: false,
  skip: false,
};

export default withPropsFromUrl()(PerkFilter);
