import React, { useCallback } from 'react';
import { Text, Spinner, View } from '@rivallapp/volosports-components';
import { useMutation, useQuery } from '@apollo/client';
import PaymentSourceItem from './PaymentSourceItem';
import StripeForm from '../StripeForm/StripeForm';
import { useAlertMessage } from '../../../hooks';
import { ADD_PAYMENT_SOURCE } from '../PLAYER_PAYMENT_MUTATIONS';
import { Error } from '../../../components';
import PLAYER_PAYMENT_QUERY from '../PLAYER_PAYMENT_QUERY';

export type PaymentSource = {
  brand: string;
  exp_month: number;
  exp_year: number;
  id: string;
  isDefault: boolean;
  isExpired: boolean;
  last4: number;
};

type Props = {
  onClose: () => void;
  paymentSources?: PaymentSource[];
};

const EditPaymentMethods: React.FC<Props> = ({ onClose, paymentSources }) => {
  const { showSuccess } = useAlertMessage();

  const { data, error, loading } = useQuery(PLAYER_PAYMENT_QUERY, {
    skip: !!paymentSources,
    fetchPolicy: 'cache-and-network',
  });

  const sources = paymentSources || data?.currentUser?.paymentSources || [];

  const [addPaymentMutation, { loading: addPaymentLoading }] = useMutation(ADD_PAYMENT_SOURCE);

  // `StripeForm` will handle any errors here
  const addPayment = useCallback(
    async (tokenId: string) => {
      await addPaymentMutation({
        variables: { input: { tokenId } },
      });
      showSuccess('Successfully added payment method.');
      if (onClose) onClose();
    },
    [addPaymentMutation, onClose, showSuccess]
  );

  if (loading || addPaymentLoading) return <Spinner my={16} />;
  if (error) return <Error error={error} />;

  return (
    <View>
      {sources.map((source: PaymentSource) => (
        <PaymentSourceItem key={source.id} source={source} onClose={onClose} />
      ))}
      <View>
        <Text>Add a new card</Text>
        <StripeForm onTokenSuccess={addPayment} />
      </View>
    </View>
  );
};

export default EditPaymentMethods;
