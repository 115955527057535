/* eslint-disable react/jsx-props-no-spreading */
import React, { type ComponentProps } from 'react';
import { Modal } from '@rivallapp/volosports-components';
import EditPaymentMethods from './EditPaymentMethods';

type Props = ComponentProps<typeof Modal> & ComponentProps<typeof EditPaymentMethods>;

const PaymentsModal: React.FC<Props> = ({ onClose, paymentSources, ...props }) => {
  return (
    <Modal useRNModal onClose={onClose} {...props}>
      <Modal.Content minW={300}>
        <Modal.Header>
          Payment Methods
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <EditPaymentMethods onClose={onClose} paymentSources={paymentSources} />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default PaymentsModal;
