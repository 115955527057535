import { gql } from '@apollo/client';

export const PaymentSourceFragment = gql`
  fragment paymentSourceFragment on PaymentSource {
    last4
    isDefault
    isExpired
    exp_month
    exp_year
    brand
    id
  }
`;

export default gql`
  query currentUserPaymentSources {
    currentUser {
      _id
      paymentSources {
        ...paymentSourceFragment
      }
    }
  }
  ${PaymentSourceFragment}
`;

export type CurrentUserPaymentSourcesData = {
  currentUser: {
    _id: string;
    paymentSources: {
      last4: number;
      isDefault: boolean;
      isExpired: boolean;
      exp_month: number;
      exp_year: number;
      brand: string;
      id: string;
    }[];
  };
};
