import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '../../../../../../components';
import classNames from 'classnames/bind';
import { FancyField } from '../../../../../../components/fancyFieldsV2';

import {
  ParagraphText,
  colors,
  fonts,
  fontWeights,
} from '../../../../../../components/styledComponents';

import styles from '../../../styles.module.scss';

const cx = classNames.bind(styles);

const PROMO_CODE = 'Promo code';
const INVALID = 'Invalid promo code. Please try again.';
const ENTER_CODE = 'Enter Code';

const PromoCodeDiv = styled.div`
  margin-bottom: 10px;
  margin-left: -5px;
`;

const PromoCode = ({ updatePlanDetails, planDetails, validatePromo, getPromoText }) => {
  const { promoData, promo, enteringPromo } = planDetails;

  return (
    <PromoCodeDiv>
      <div className="row">
        <div className="col-12">
          <ParagraphText
            fontColor={colors.darkBlue}
            fontWeight={fontWeights.bold}
            fontFamily={fonts.OPEN_SANS}
            fontSize="16px"
            textAlign="left"
            marginBottom="2px"
          >
            {PROMO_CODE}
          </ParagraphText>
          {enteringPromo ? (
            <div className="row">
              <div className="col">
                <FancyField
                  noLabel
                  blockStyle
                  value={promo}
                  onChange={p => updatePlanDetails({ promo: p })}
                  placeholder="Enter code here"
                  disabled={promoData?.valid}
                />
              </div>
              <div className="col-auto my-auto">
                <Button
                  small
                  primary
                  onClick={() =>
                    promoData?.valid
                      ? updatePlanDetails({ promoData: null, promo: '' })
                      : validatePromo()
                  }
                >
                  {promoData?.valid ? 'Remove' : 'Verify'}
                </Button>
              </div>
            </div>
          ) : (
            <div className="text-left">
              <a
                onClick={() => updatePlanDetails({ enteringPromo: true })}
                role="link"
                tabIndex={0}
              >
                {ENTER_CODE}
              </a>
            </div>
          )}
        </div>
        {promoData?.valid && <div className={cx('promoDescription')}>{getPromoText()}</div>}
        {promoData && !promoData.valid && <div className={cx('promoError')}>{INVALID}</div>}
      </div>
    </PromoCodeDiv>
  );
};

PromoCode.propTypes = {
  updatePlanDetails: PropTypes.func.isRequired,
  planDetails: PropTypes.shape({
    promoData: PropTypes.shape({
      valid: PropTypes.bool.isRequired,
    }),
    enteringPromo: PropTypes.bool.isRequired,
    promo: PropTypes.string.isRequired,
  }).isRequired,
  validatePromo: PropTypes.func.isRequired,
  getPromoText: PropTypes.func.isRequired,
};

export default PromoCode;
