import type React from 'react';
import { useState, type ComponentProps } from 'react';
import {
  Colors,
  FontAwesomeIcon,
  Row,
  StarRatingComponent,
  Text,
  View,
  type ITextProps,
} from '@rivallapp/volosports-components';
import { Link } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import ShiftLogIcon from '../../../components/ShiftLogIcon';
import {
  ShiftLogSection,
  ShiftLogSectionStatus,
} from '../../../../../../../shared/shift-log-enums';
import ShiftLogListColumn from './ShiftLogListColumn';

const RowText: React.FC<ITextProps> = props => (
  <Text fontSize="xs" numberOfLines={2} bold {...props} />
);

export type ShiftLogsListRowProps = {
  _id: string;
  submitter: string;
  submission: string;
  day: string;
  sports: string[];
  venue: string;
  rating: number;
  sections: {
    type: ShiftLogSection;
    resolved: ShiftLogSectionStatus;
  }[];
  linkProps?: Partial<ComponentProps<typeof Link>>;
};

const ShiftLogsListRow = ({
  _id,
  submitter,
  submission,
  day,
  sports,
  venue,
  rating,
  sections,
  linkProps,
}: ShiftLogsListRowProps) => {
  // This hover style is being done with JS since you cannot provide hover styles using inline styles
  // and Native Base only provides `_hover` on interactable elements, so we would have had to put a
  // button inside of a link
  const [hovered, setIsHovered] = useState(false);

  return (
    <Link
      to={`/rfo/shift-logs/${_id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...linkProps}
    >
      <View
        bgColor={Colors.white}
        borderRadius="md"
        borderWidth="3"
        borderColor={Colors.primary}
        py={1}
        px={3}
        my={1}
        mx={3}
        {...(hovered ? { shadow: 5 } : {})}
      >
        <Row alignItems="center" space={2}>
          <ShiftLogListColumn type="submitter">
            <RowText>{submitter}</RowText>
          </ShiftLogListColumn>

          <ShiftLogListColumn type="submission">
            <RowText>{submission}</RowText>
          </ShiftLogListColumn>

          <ShiftLogListColumn type="day">
            <RowText>{day}</RowText>
          </ShiftLogListColumn>

          <ShiftLogListColumn type="sport">
            <RowText>{sports.join(', ')}</RowText>
          </ShiftLogListColumn>

          <ShiftLogListColumn type="venue">
            <RowText>{venue}</RowText>
          </ShiftLogListColumn>

          <ShiftLogListColumn type="rating">
            <StarRatingComponent
              starStyle={{ margin: 0 }}
              starSize={18}
              variant="yellow"
              rating={rating}
              readOnly
              // This is not needed when `readOnly` is true.
              onChange={() => {}}
            />
          </ShiftLogListColumn>

          {/* Section Icons */}
          <ShiftLogListColumn type="icons" minHeight="10">
            <Row justifyContent="flex-end" alignItems="center" flexWrap="wrap">
              {sections.map(section => (
                <View m={0.5} key={section.type}>
                  <ShiftLogIcon section={section.type} status={section.resolved} />
                </View>
              ))}
            </Row>
          </ShiftLogListColumn>

          <ShiftLogListColumn type="chevron">
            <FontAwesomeIcon icon={faChevronRight} colorScheme="primary" size="xs" />
          </ShiftLogListColumn>
        </Row>
      </View>
    </Link>
  );
};
export default ShiftLogsListRow;
