import classNames from 'classnames/bind';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styles from './styles.module.scss';
import { ColorIndicator } from '../../../components';
import { GenderNameEnum } from '../../../shared/gender-enum';
import { RegistrantProps } from '../../../types';
import { WaiverAction, WaiverStatus, PlayerDetailsPopup } from '../..';

import Picture from '../../../components/Picture/Picture';

const cx = classNames.bind(styles);

const LeagueTeamInfoDetail = props => {
  const {
    rank: {
      team,
      team: { registrants },
      WIN = 0,
      LOSE = 0,
      TIE = 0,
      FORFEIT = 0,
      points_for = 0,
      points_against = 0,
      points_differential = 0,
    },
    showAllPlayers,
    schedule_approved,
    refetch,
  } = props;

  const [showPlayers, setShowPlayers] = useState(false);

  if (!team) return <span />;

  const { isVoloPassTeam, name } = team;

  const color = team.color?.name || team.color?.hex || 'N/A';

  const getPlayerLabel = _id => {
    if (_id === team.captain?._id) return <b>(c)</b>;
    if (team.coCaptainIds?.includes(_id)) return '(co)';

    return '';
  };

  return (
    <div className={cx('roster-item-wrap')}>
      <div className={cx('main-row')}>
        <div
          className={cx('left')}
          onClick={() => setShowPlayers(!showPlayers)}
          onKeyDown={() => setShowPlayers(!showPlayers)}
          role="button"
          tabIndex={0}
        >
          <ColorIndicator {...team} style={{ height: '10px', width: '10px', marginRight: '4px' }} />
          <span className={cx('team-color')}>({color})</span>
          <h2>
            {name}
            {isVoloPassTeam && (
              <Picture
                className={cx('vp-img')}
                src="https://rivall-public.s3-us-west-1.amazonaws.com/home-page/VoloPass.png"
                alt="volo pass"
              />
            )}
          </h2>
        </div>
        {schedule_approved && (
          <div className={cx('right')}>
            <span className={cx('record')}>{WIN}</span>
            <span className={cx('record')}>{LOSE}</span>
            <span className={cx('record')}>{TIE}</span>
            <span className={cx('record')}>{FORFEIT}</span>
            <span className={cx('record')}>{points_for}</span>
            <span className={cx('record')}>{points_against}</span>
            <span className={cx('record')}>{points_differential}</span>
          </div>
        )}
      </div>
      <div className={cx('detail', { shown: showPlayers || showAllPlayers })}>
        <p className={cx('color', { shown: showPlayers || showAllPlayers })}>
          Color: {color}
          {FORFEIT > 0 ? (
            <span className={cx('forfeits')}>
              <br />
              Forfeits: {FORFEIT}
            </span>
          ) : null}
        </p>
        <table className={cx('player-list')}>
          <tbody>
            {_.sortBy(registrants, p => p?.player?.lastName).map((registrant, index) => {
              if (!registrant) {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={`null-player-${index}`}>
                    <td className="text-right">Error loading team member.</td>
                  </tr>
                );
              }
              const {
                player: { _id, email, firstName, lastName, gender, shirt_size, phone_number },
                waiver,
                _id: registrant_id,
              } = registrant || {};
              return (
                <tr key={_id}>
                  <td className={cx('table-col')} label="Player Details">
                    <PlayerDetailsPopup userId={_id} refetch={refetch} />
                  </td>
                  <td className={cx('table-col', 'center')}>{getPlayerLabel(_id)}</td>
                  <td className={cx('table-col', 'col-link')}>{firstName}</td>
                  <td className={cx('table-col')}>{lastName}</td>
                  <td className={cx('table-col', 'center')}>
                    <span>{shirt_size.toUpperCase()}</span>
                  </td>
                  <td className={cx('table-col')}>
                    <span>{GenderNameEnum[gender]}</span>
                  </td>
                  <td className={cx('table-col')}>
                    <span>
                      <a href={`mailto:${email}`}>{email}</a>
                    </span>
                  </td>
                  <td className={cx('table-col')}>
                    <span>
                      <a href={`tel:${phone_number}`}>{phone_number}</a>
                    </span>
                  </td>
                  <td className={cx('table-col', 'd-flex', 'align-items-center')}>
                    <WaiverStatus signed={!!waiver.date_signed} />
                    <WaiverAction
                      {...{
                        registrant_id,
                        waiver_id: waiver.waiverId,
                        userDetails: {
                          firstName,
                          lastName,
                        },
                        ...waiver,
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

LeagueTeamInfoDetail.propTypes = {
  rank: PropTypes.shape({
    WIN: PropTypes.number,
    LOSE: PropTypes.number,
    TIE: PropTypes.number,
    FORFEIT: PropTypes.number,
    points_for: PropTypes.number,
    points_against: PropTypes.number,
    points_differential: PropTypes.number,
    team: PropTypes.shape({
      registrants: PropTypes.arrayOf(RegistrantProps),
      color: PropTypes.shape({
        hex: PropTypes.string,
        name: PropTypes.string,
      }),
      captain: PropTypes.shape({
        _id: PropTypes.string,
      }),
      coCaptainIds: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      isVoloPassTeam: PropTypes.bool,
    }),
  }).isRequired,
  showAllPlayers: PropTypes.bool.isRequired,
  schedule_approved: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default LeagueTeamInfoDetail;
