import { useMemo } from 'react';
import { getDayNameFromDowShort } from '../../../../../shared/days-of-week-enum';
import type { ShiftLog } from '../gql/GET_SHIFT_LOGS';
import { ShiftLogSection } from '../../../../../shared/shift-log-enums';

const formatter = new Intl.DateTimeFormat(undefined, {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
});

const useParseShiftLogData = (data: ShiftLog[]) => {
  const getSectionStatuses = (log: ShiftLog) => {
    const {
      content_capture_resolution_status,
      daily_programming_resolution_status,
      equipment_resolution_status,
      hosts_resolution_status,
      miscellaneous_resolution_status,
      players_resolution_status,
      referees_resolution_status,
      sponsor_bar_resolution_status,
      shirts_resolution_status,
      technology_resolution_status,
      venue_resolution_status,
    } = log;

    const sections = [];

    if (content_capture_resolution_status)
      sections.push({
        type: ShiftLogSection.CONTENT_CAPTURE,
        resolved: content_capture_resolution_status,
      });
    if (daily_programming_resolution_status)
      sections.push({
        type: ShiftLogSection.DAILY_PROGRAMMING,
        resolved: daily_programming_resolution_status,
      });
    if (equipment_resolution_status)
      sections.push({ type: ShiftLogSection.EQUIPMENT, resolved: equipment_resolution_status });
    if (hosts_resolution_status)
      sections.push({ type: ShiftLogSection.HOSTS, resolved: hosts_resolution_status });
    if (miscellaneous_resolution_status)
      sections.push({
        type: ShiftLogSection.MISCELLANEOUS,
        resolved: miscellaneous_resolution_status,
      });
    if (players_resolution_status)
      sections.push({ type: ShiftLogSection.PLAYERS, resolved: players_resolution_status });
    if (referees_resolution_status)
      sections.push({ type: ShiftLogSection.REFEREES, resolved: referees_resolution_status });
    if (sponsor_bar_resolution_status)
      sections.push({ type: ShiftLogSection.SPONSOR_BAR, resolved: sponsor_bar_resolution_status });
    if (shirts_resolution_status)
      sections.push({ type: ShiftLogSection.SHIRTS, resolved: shirts_resolution_status });
    if (technology_resolution_status)
      sections.push({ type: ShiftLogSection.TECHNOLOGY, resolved: technology_resolution_status });
    if (venue_resolution_status)
      sections.push({ type: ShiftLogSection.VENUE, resolved: venue_resolution_status });

    return sections;
  };

  const shiftLogs = useMemo(
    () =>
      data.map(log => ({
        _id: log._id,
        submitter: log.created_by.fullName ?? '',
        submission: formatter.format(new Date(log.created_at)),
        day: getDayNameFromDowShort(log.day_of_week),
        sports: log.sports,
        venue: log.venue.shorthandName,
        rating: log.pulse_check_rating,
        sections: getSectionStatuses(log),
      })),
    [data]
  );

  return shiftLogs;
};

export type ParsedShiftLog = ReturnType<typeof useParseShiftLogData>[number];

export default useParseShiftLogData;
