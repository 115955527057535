import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { compose, withProps } from 'recompose';
import { DISCOVER_SEASONS } from './FILTER_QUERIES';
import { getParamsFromUrl, withPropsFromUrl } from './utils';
import { FilterSelect } from '../../components';
import { orderSeasons } from '../../shared/season-enum';

const SeasonFilter = props => {
  const {
    seasonNames,
    seasonOptions,
    disabled,
    onSelect,
    isMulti,
    loading,
    className,
    updateSearchUrls,
  } = props;

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : v => updateSearchUrls({ seasonNames: _.map(v, ({ value }) => value) });

  return (
    <FilterSelect
      short
      shadow
      labelSingular="Season"
      labelPlural="Seasons"
      options={seasonOptions}
      value={_.filter(seasonOptions, ({ value }) => _.includes(seasonNames, value)) || null}
      onChange={onChange}
      disabled={disabled || loading}
      multi={isMulti}
      className={className}
    />
  );
};

SeasonFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  seasonNames: PropTypes.arrayOf(PropTypes.string),
  seasonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

SeasonFilter.defaultProps = {
  onSelect: false,
  loading: false,
  disabled: false,
  isMulti: false,
  seasonOptions: [],
  seasonNames: [],
  className: '',
};

export default compose(
  withPropsFromUrl(),
  graphql(DISCOVER_SEASONS, {
    skip: ({ skip }) => skip,
    options: props => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        ...getParamsFromUrl(props, props.isHost),
      },
    }),
    props: ({ data: { loading = true, discoverSeasons } }) => ({
      loading,
      allSeasons: _.get(discoverSeasons, 'seasons') || [],
    }),
  }),
  withProps(({ allSeasons }) => ({
    seasonOptions: orderSeasons(
      _.map(allSeasons, value => ({ label: _.capitalize(value), value }))
    ),
  }))
)(SeasonFilter);
