import classNames from 'classnames/bind';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import date from '../../../images/icons/date.svg';

const cx = classNames.bind(styles);

export const LeagueWeekDescriptions = ({ league, minify }) => {
  const { profile, details } = league;

  const schedule = league?.schedules[0];
  const { weeks = [] } = schedule ?? {};

  const { week_descriptions, week_tags } = profile || details || {};
  const { num_weeks_estimate } = league;
  const weekTags = _.orderBy(weeks, 'week_num')?.map(({ weekTag }) => weekTag);
  const weekTagOrder = weekTags.filter(val => Boolean(val))?.length ? weekTags : week_tags;

  if (!weekTagOrder?.length && !week_descriptions?.length) return <span />;

  return (
    <div className={cx('info-container')}>
      <img className={cx('icon')} src={date} alt="date" />
      {week_descriptions?.length && !week_tags?.length ? (
        <>
          <p className={cx('title')}>By Week</p>
          {_.times(num_weeks_estimate, num => {
            const description = week_descriptions?.num;
            return (
              <p key={num}>
                Week {num + 1}
                {description && `: ${description}`}
              </p>
            );
          })}
        </>
      ) : (
        <>
          <p className={cx('title')}>Notable Weeks</p>
          {_.times(num_weeks_estimate, weekNum => {
            const weekTag = weekTagOrder[weekNum];
            if (!weekTag) {
              return <span key={weekNum} />;
            }
            const weekTagIdx = week_tags.findIndex(t => t === weekTag);
            const weekDescription = week_descriptions[weekTagIdx];

            return (
              <div key={weekNum} className="py-1">
                Week {weekNum + 1}:
                <span className={cx('week-tag')}>
                  {weekTag} <i>{!minify && `- ${weekDescription}`}</i>
                </span>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

LeagueWeekDescriptions.propTypes = {
  league: PropTypes.shape({
    num_weeks_estimate: PropTypes.number,
    profile: PropTypes.shape({
      week_descriptions: PropTypes.arrayOf(PropTypes.string),
    }),
    details: PropTypes.shape({
      week_descriptions: PropTypes.arrayOf(PropTypes.string),
    }),
    schedules: PropTypes.arrayOf(
      PropTypes.shape({
        weeks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      })
    ).isRequired,
  }).isRequired,
  minify: PropTypes.bool,
};

LeagueWeekDescriptions.defaultProps = {
  minify: false,
};

export default LeagueWeekDescriptions;
