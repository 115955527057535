import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import moment from 'moment-timezone';
import styles from '../../player-details.module.scss';
import { Button } from '../../../../components';

const cx = classNames.bind(styles);

const PlayerInfoDisplay = props => {
  const {
    _created,
    birthday,
    email,
    firstName,
    gender,
    lastName,
    phone_number,
    username,
    setUpdatingInfo,
  } = props;

  return (
    <div className={cx('noEdit')}>
      <label>First Name</label>
      <div>{firstName}</div>
      <label>Last Name</label>
      <div>{lastName}</div>
      <label>Email</label>
      <div>{email}</div>
      <label>Username</label>
      <div>{username}</div>
      <label>Phone</label>
      <div>{phone_number}</div>
      <label>Birthday</label>
      <div>{birthday}</div>
      <label>Gender</label>
      <div>{gender?.toUpperCase()}</div>
      <label>Registration Date</label>
      <div>{moment(+_created).format('MM/DD/YYYY h:mm a')}</div>
      <Button primary square className={cx('actionButton')} onClick={() => setUpdatingInfo(true)}>
        Edit Player Details
      </Button>
    </div>
  );
};

PlayerInfoDisplay.propTypes = {
  _created: PropTypes.string,
  birthday: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  gender: PropTypes.string,
  lastName: PropTypes.string,
  phone_number: PropTypes.string,
  username: PropTypes.string,
  setUpdatingInfo: PropTypes.func.isRequired,
};

PlayerInfoDisplay.defaultProps = {
  _created: '',
  birthday: '',
  email: '',
  firstName: '',
  gender: '',
  lastName: '',
  phone_number: '',
  username: '',
};

export default PlayerInfoDisplay;
