import PropTypes from 'prop-types';
import { FilterSelect } from '../../components';
import { withPropsFromUrl } from './utils';

const membershipPerksOptions = [
  {
    value: 'is_volo_pass_exclusive',
    label: 'Volo Pass - Exclusive Programs',
  },
  {
    value: 'is_premier',
    label: 'Premier Programs',
  },
];

/** This filter is different from all of the other filters in this section.
 * Instead of a list of values it is actually a combination of multiple boolean selections
 */
const MembershipPerksFilter = props => {
  const { membershipPerks, disabled, onSelect, isMulti, loading, className, updateSearchUrls } =
    props;

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : (v = []) => {
          updateSearchUrls({ membershipPerks: v.map(({ value }) => value) });
        };

  return (
    <FilterSelect
      short
      shadow
      labelPlural="Volo Pass Membership Perks"
      options={membershipPerksOptions}
      value={membershipPerksOptions.filter(({ value }) => membershipPerks.includes(value)) || null}
      onChange={onChange}
      disabled={disabled || loading}
      multi={isMulti}
      className={className}
    />
  );
};

MembershipPerksFilter.propTypes = {
  membershipPerks: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

MembershipPerksFilter.defaultProps = {
  membershipPerks: [],
  onSelect: false,
  loading: false,
  disabled: false,
  isMulti: false,
  className: '',
};

export default withPropsFromUrl()(MembershipPerksFilter);
