import * as _ from 'lodash-es';

export const DowNameEnum = Object.freeze({
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
});

export const DowAbbrevEnum = Object.freeze({
  MONDAY: 'Mon',
  TUESDAY: 'Tue',
  WEDNESDAY: 'Wed',
  THURSDAY: 'Thu',
  FRIDAY: 'Fri',
  SATURDAY: 'Sat',
  SUNDAY: 'Sun',
});

// currently these are the values saved to days_of_week in the db.
export const DowShortEnum = Object.freeze({
  MONDAY: 'M',
  TUESDAY: 'T',
  WEDNESDAY: 'W',
  THURSDAY: 'Th',
  FRIDAY: 'F',
  SATURDAY: 'S',
  SUNDAY: 'Su',
});

export enum DowShortEnum_TS {
  MONDAY = 'M',
  TUESDAY = 'T',
  WEDNESDAY = 'W',
  THURSDAY = 'Th',
  FRIDAY = 'F',
  SATURDAY = 'S',
  SUNDAY = 'Su',
}

export const DayToNum = Object.freeze({
  Su: 0,
  M: 1,
  T: 2,
  W: 3,
  Th: 4,
  F: 5,
  S: 6,
});

export type DowNum = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const NumToDay = Object.freeze({
  0: DowShortEnum.SUNDAY,
  1: DowShortEnum.MONDAY,
  2: DowShortEnum.TUESDAY,
  3: DowShortEnum.WEDNESDAY,
  4: DowShortEnum.THURSDAY,
  5: DowShortEnum.FRIDAY,
  6: DowShortEnum.SATURDAY,
});

export enum NumToDayEnum {
  'Su' = 0,
  'M' = 1,
  'T' = 2,
  'W' = 3,
  'Th' = 4,
  'F' = 5,
  'S' = 6,
}

// MongoDB maps Sunday to 1, Monday to 2, etc.
export enum NumToDayMongo {
  'M' = 2, // Monday
  'T' = 3, // Tuesday
  'W' = 4, // Wednesday
  'Th' = 5, // Thursday
  'F' = 6, // Friday
  'Sa' = 7, // Saturday
  'Su' = 1, // Sunday
}

export const DowValueEnum = Object.freeze({
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
});

export const DaysOfWeek = Object.freeze({
  MONDAY: {
    name: DowNameEnum.MONDAY,
    abbrevName: DowAbbrevEnum.MONDAY,
    shortName: DowShortEnum.MONDAY,
    value: DowValueEnum.MONDAY,
  },
  TUESDAY: {
    name: DowNameEnum.TUESDAY,
    abbrevName: DowAbbrevEnum.TUESDAY,
    shortName: DowShortEnum.TUESDAY,
    value: DowValueEnum.TUESDAY,
  },
  WEDNESDAY: {
    name: DowNameEnum.WEDNESDAY,
    abbrevName: DowAbbrevEnum.WEDNESDAY,
    shortName: DowShortEnum.WEDNESDAY,
    value: DowValueEnum.WEDNESDAY,
  },
  THURSDAY: {
    name: DowNameEnum.THURSDAY,
    abbrevName: DowAbbrevEnum.THURSDAY,
    shortName: DowShortEnum.THURSDAY,
    value: DowValueEnum.THURSDAY,
  },
  FRIDAY: {
    name: DowNameEnum.FRIDAY,
    abbrevName: DowAbbrevEnum.FRIDAY,
    shortName: DowShortEnum.FRIDAY,
    value: DowValueEnum.FRIDAY,
  },
  SATURDAY: {
    name: DowNameEnum.SATURDAY,
    abbrevName: DowAbbrevEnum.SATURDAY,
    shortName: DowShortEnum.SATURDAY,
    value: DowValueEnum.SATURDAY,
  },
  SUNDAY: {
    name: DowNameEnum.SUNDAY,
    abbrevName: DowAbbrevEnum.SUNDAY,
    shortName: DowShortEnum.SUNDAY,
    value: DowValueEnum.SUNDAY,
  },
});

export const getDowValue = (integer: number) => {
  switch (integer) {
    case 0:
      return DowNameEnum.SUNDAY;
    case 1:
      return DowNameEnum.MONDAY;
    case 2:
      return DowNameEnum.TUESDAY;
    case 3:
      return DowNameEnum.WEDNESDAY;
    case 4:
      return DowNameEnum.THURSDAY;
    case 5:
      return DowNameEnum.FRIDAY;
    case 6:
      return DowNameEnum.SATURDAY;
    default:
      return null;
  }
};

export const getDowKey = ({ shortName, name }: { shortName: string; name: string }) =>
  shortName
    ? Object.values(DaysOfWeek).find(DOW => DOW.shortName === shortName)
    : Object.values(DaysOfWeek).find(DOW => DOW.name === name);

export const getDowList = (dows: DowShortEnum_TS[], nameKind = 'name') =>
  // @ts-expect-error
  dows.map(shortName => getDowKey({ shortName })[nameKind]).join(', ');

// @ts-expect-error
export const getFullDayInfo = day => {
  const foundDay = _.find(DaysOfWeek, dayItem => {
    const { name, abbrevName, shortName, value } = dayItem;
    return !_.isEmpty(_.intersection([name, abbrevName, shortName, value], [day]));
  });
  return foundDay || {};
};

/** Returns the day name from a provided short name */
export const getDayNameFromDowShort = (
  shortName: (typeof DowShortEnum)[keyof typeof DowShortEnum] | string
) => {
  const day = Object.values(DaysOfWeek).find(d => d.shortName === shortName);
  return day ? day.name : '';
};

export default DaysOfWeek;
