import { gql } from '@apollo/client';

export const GET_SCHEDULE_DETAILS = gql`
  query getScheduleDetails($leagueId: ID!) {
    getScheduleDetails(leagueId: $leagueId) {
      league {
        _id
        teams_approved
        schedule_approved
        organizationId
        start_date
        days_of_week_estimate
        has_tournament
        num_weeks_estimate
        num_playoff_weeks_estimate
        start_time_estimate
        end_time_estimate
        game_length_in_minutes_estimate
        num_games_per_team_per_week_estimate
        timezone
        registration {
          _id
          registration_open
          registration_close
        }
        tournament {
          _id
        }
        venue {
          _id
          neighborhood
          name
          formatted_address
          city
          zip
          timezone
        }
        details {
          week_tags
        }
      }
      schedules {
        _id
        start_date
        days_of_week
        has_tournament
        num_games_per_team_per_week
        num_tournament_weeks
        num_weeks
        game_length_in_minutes
        startTimeStr
        endTimeStr
        timezone
        schedule_type
      }
      weeks {
        _id
        week_num
        is_tournament
        notes
        weekTag
        byes
      }
      days {
        _id
        date
        weekId
      }
      games {
        _id
        dayId
        field_name
        start_time
        teamIds
        location {
          name
        }
        scores {
          team_id
          forfeit
          score
        }
      }
      teams {
        _id
        name
        isVoloPassTeam
        captainId
      }
    }
  }
`;

export const ORG_APPROVED_VENUES = gql`
  query organization($organizationId: ID!) {
    organization(_id: $organizationId) {
      _id
      approvedVenues {
        _id
        name
        formatted_address
        neighborhood
        city
        zip
        timezone
      }
    }
  }
`;

// NEW
export const UPSERT_SUB_SCHEDULE = gql`
  mutation UpsertSubSchedule($input: UpsertSubScheduleInput!) {
    upsertSubSchedule(input: $input) {
      subSchedule {
        _id
        start_date
      }
    }
  }
`;

export const CREATE_TIMESLOTS_FROM_ROUNDS = gql`
  mutation CreateTimeslotsFromRounds($input: TimeslotRoundInput!) {
    createTimeslotsFromRounds(input: $input) {
      subSchedule {
        _id
      }
    }
  }
`;

export const CREATE_SCHEDULE_GAMES = gql`
  mutation CreateScheduleGames($subScheduleId: ID!) {
    createScheduleGames(subScheduleId: $subScheduleId) {
      status
    }
  }
`;

export const SCHEDULE_SUBSCRIPTION = gql`
  subscription ScheduleProgress {
    scheduleProgress {
      status
      totalSchedules
      schedulesProcessed
    }
  }
`;
// ---------

// OLD
export const CREATE_CSV_SCHEDULE_MUTATION = gql`
  mutation createCSVSchedule($input: CreateCSVScheduleInput!) {
    createCSVSchedule(input: $input) {
      subSchedule {
        start_date
      }
    }
  }
`;

export const SCHEDULE_COUNTS_QUERY = gql`
  query scheduleCountsQuery($input: ScheduleCheckCountsInput!) {
    scheduleCheckCounts(input: $input) {
      opponentCounts {
        team {
          name
        }
        opponents {
          opponent {
            name
          }
          count
        }
      }
      gameTimeCounts {
        team {
          name
        }
        startTimes {
          startTime
          count
        }
      }
    }
  }
`;

export const CREATE_WEEK_MUTATION = gql`
  mutation createWeek($input: CreateWeekInput!) {
    createWeek(input: $input) {
      week {
        _id
        week_num
      }
    }
  }
`;

export const UPDATE_WEEK_MUTATION = gql`
  mutation updateWeek($input: UpdateWeekInput!) {
    updateWeek(input: $input) {
      league {
        _id
      }
    }
  }
`;

export const DELETE_WEEK_MUTATION = gql`
  mutation deleteWeek($input: DeleteWeekInput!) {
    deleteWeek(input: $input) {
      league {
        _id
      }
    }
  }
`;

export const CREATE_DAY_MUTATION = gql`
  mutation createDay($input: CreateDayInput!) {
    createDay(input: $input) {
      league {
        _id
      }
    }
  }
`;

export const DELETE_DAY_MUTATION = gql`
  mutation deleteDay($input: DeleteDayInput!) {
    deleteDay(input: $input) {
      league {
        _id
      }
    }
  }
`;

export const DELETE_GAME_MUTATION = gql`
  mutation deleteGame($input: DeleteGameInput!) {
    deleteGame(input: $input) {
      league {
        _id
      }
    }
  }
`;

export const UPDATE_DAY_MUTATION = gql`
  mutation updateDay($input: UpdateDayInput!) {
    updateDay(input: $input) {
      league {
        _id
      }
    }
  }
`;

export const APPROVE_SCHEDULE_MUTATION = gql`
  mutation releaseSchedule($input: ReleaseScheduleInput!) {
    releaseSchedule(input: $input) {
      league {
        _id
        schedules {
          _id
        }
      }
    }
  }
`;

export const UN_APPROVE_SCHEDULE_MUTATION = gql`
  mutation unReleaseSchedule($input: ReleaseScheduleInput!) {
    unReleaseSchedule(input: $input) {
      league {
        _id
      }
    }
  }
`;

export const DROPIN_GAME_QUERY = gql`
  query dropInSlotsByGameId($gameId: ID!) {
    dropInSlotsByGameId(gameId: $gameId) {
      slots {
        _id
        gameId
        teamId
        gender
        holdExpiration
        closedAt
        rsvpId
        registrantId
      }
    }
  }
`;

export const REGISTRANT_QUERY = gql`
  query registrant($registrantId: ID!) {
    registrant(_id: $registrantId) {
      _id
      player {
        _id
        firstName
        lastName
        gender
      }
    }
  }
`;

export const GAME_QUERY = gql`
  query game($gameId: ID!) {
    game(_id: $gameId) {
      _id
      rsvped {
        userId
        teamId
        fullName
        gender
        response
        isDropin
      }
    }
  }
`;

export const UPDATE_DROP_IN_SLOT_COUNT = gql`
  mutation updateDropInSlotCount($input: UpdateDropInSlotCountInput!) {
    updateDropInSlotCount(input: $input) {
      a
      f
      m
    }
  }
`;
