import classNames from 'classnames/bind';
import orderBy from 'lodash-es/orderBy';
import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { useReactToPrint } from 'react-to-print';
import EditScores from './EditGameScores';
import styles from './styles.module.scss';
import WeekItem from './WeekItem';
import DayItem from './DayItem';
import GameItem from './GameItem';
import { Button, Popup, PageLoader } from '../../../../components';
import { GET_SCHEDULE_DETAILS } from './LEAGUE_TEAMS_QUERY';

const cx = classNames.bind(styles);

const ManageSchedule = props => {
  const { leagueId } = props;

  // Used for print view
  const ref = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const [editingScore, setEditingScore] = useState(null);

  const { data, loading, error, refetch } = useQuery(GET_SCHEDULE_DETAILS, {
    skip: !leagueId,
    fetchPolicy: 'network-only',
    variables: {
      leagueId,
    },
  });

  if (loading) return <PageLoader />;
  if (error || !leagueId) return <div>Error! {JSON.stringify(error || 'No leagueId!')}</div>;

  const { getScheduleDetails } = data || {};

  if (!getScheduleDetails?.league?.schedule_approved) {
    return <p className="my-2">This program does not have a released schedule yet.</p>;
  }

  const {
    league,
    games = [],
    teams = [],
    tournament = [],
    days = [],
    weeks = [],
  } = getScheduleDetails ?? {};
  const { timezone } = league || {};

  if (!weeks?.length || !teams?.length || !days?.length || !games?.length) {
    return <div />;
  }

  const editScore = game => {
    if (game?._id) {
      setEditingScore({
        game,
        teams: (teams || []).filter(({ _id }) => (game.teamIds || []).includes(_id)),
      });
    } else setEditingScore(null);
  };

  const { week_tags, week_descriptions } = league?.details ?? {};

  return (
    <div>
      <Button small onClick={handlePrint} className="m-2">
        Print View
      </Button>
      <div className={cx('schedule-wrap')} ref={ref}>
        <div>
          {orderBy(weeks, 'week_num').map(week => {
            if (!tournament?.length || !week.is_tournament) {
              return (
                <WeekItem
                  key={week._id}
                  {...{
                    week,
                    teams,
                    timezone,
                  }}
                  weekTags={week_tags || []}
                  weekDescriptions={week_descriptions || []}
                >
                  {days
                    .filter(({ weekId }) => weekId === week._id)
                    .map(day => (
                      <DayItem
                        key={day._id}
                        games={games.filter(({ dayId }) => dayId === day._id)}
                        day={day}
                      >
                        {games
                          .filter(({ dayId }) => dayId === day._id)
                          .map(game => (
                            <GameItem
                              key={game._id}
                              {...{
                                teams,
                                editScore,
                                timezone,
                                game,
                              }}
                            />
                          ))}
                      </DayItem>
                    ))}
                </WeekItem>
              );
            }
            return '';
          })}
        </div>
        <Popup close={editScore} className="fixed" hidden={!editingScore}>
          <EditScores {...editingScore} closePopup={editScore} refetch={refetch} />
        </Popup>
      </div>
    </div>
  );
};

ManageSchedule.propTypes = {
  leagueId: PropTypes.string.isRequired,
};

export default ManageSchedule;
