import PropTypes from 'prop-types';
import React from 'react';
import { withPropsFromUrl } from './utils';
import { FilterSelect } from '../../components';
import { GenderNameEnum } from '../../shared/gender-enum';

const GenderFilter = props => {
  const { genders = [], disabled, onSelect, isMulti, className, updateSearchUrls } = props;

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : (v = []) => updateSearchUrls({ genders: v.map(({ value }) => value) });

  const genderOptions = Object.entries(GenderNameEnum).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <FilterSelect
      short
      shadow
      clearable
      labelSingular="Gender"
      labelPlural="Genders"
      options={genderOptions}
      value={genderOptions.filter(({ value }) => genders.includes(value)) || null}
      onChange={onChange}
      disabled={disabled}
      multi={isMulti}
      className={className}
    />
  );
};

GenderFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  genders: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
};

GenderFilter.defaultProps = {
  onSelect: false,
  disabled: false,
  isMulti: false,
  genders: [],
  className: '',
};

export default withPropsFromUrl()(GenderFilter);
