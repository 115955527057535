import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, withProps } from 'recompose';
import { withPropsFromUrl } from './utils';
import { FilterSelect } from '../../components';
import { getLabelOptions } from '../../utils';

const RegistrantTypeFilter = props => {
  const {
    registrantTypes,
    disabled,
    onSelect,
    isMulti,
    className,
    updateSearchUrls,
    registrantTypeOptions,
  } = props;

  const onChange =
    typeof onSelect === 'function'
      ? onSelect
      : v => updateSearchUrls({ registrantTypes: _.map(v, ({ value }) => value) });

  return (
    <FilterSelect
      short
      shadow
      clearable
      labelSingular="Registrant Type"
      labelPlural="Registrant Types"
      options={registrantTypeOptions}
      value={
        _.filter(registrantTypeOptions, ({ value }) => _.includes(registrantTypes, value)) || null
      }
      onChange={onChange}
      disabled={disabled}
      multi={isMulti}
      className={className}
    />
  );
};

RegistrantTypeFilter.propTypes = {
  onSelect: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  updateSearchUrls: PropTypes.func.isRequired,
  registrantTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  registrantTypes: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
};

RegistrantTypeFilter.defaultProps = {
  onSelect: false,
  disabled: false,
  isMulti: false,
  registrantTypes: [],
  className: '',
};

export default compose(
  withPropsFromUrl(),
  withProps(() => ({
    registrantTypeOptions: getLabelOptions([
      'captains',
      'groups',
      'prepaid teams',
      'free agents',
      'waitlist',
    ]),
  }))
)(RegistrantTypeFilter);
