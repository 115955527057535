export enum IncidentFlag {
  Green = 'green',
  Yellow = 'yellow',
  Red = 'red',
  Black = 'black',
}

export const IncidentFlagsEnum = Object.freeze({
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'red',
  BLACK: 'black',
});

export const IncidentFlagLabels = Object.freeze({
  [IncidentFlagsEnum.GREEN]: 'Green',
  [IncidentFlagsEnum.YELLOW]: 'Yellow',
  [IncidentFlagsEnum.RED]: 'Red',
  [IncidentFlagsEnum.BLACK]: 'Black',
});

const INCIDENT_REPORT_GREEN = 'rgba(36, 108, 48, 1)';
const INCIDENT_REPORT_YELLOW = 'rgba(216, 202, 80, 1)';
const INCIDENT_REPORT_RED = 'rgba(108, 36, 36, 1)';
const INCIDENT_REPORT_BLACK = '#000';

export const IncidentFlagColors = Object.freeze({
  [IncidentFlagsEnum.GREEN]: INCIDENT_REPORT_GREEN,
  [IncidentFlagsEnum.YELLOW]: INCIDENT_REPORT_YELLOW,
  [IncidentFlagsEnum.RED]: INCIDENT_REPORT_RED,
  [IncidentFlagsEnum.BLACK]: INCIDENT_REPORT_BLACK,
});

export enum IncidentType {
  Injury = 'injury',
  Altercation = 'altercation',
  InjuryAndAltercation = 'injury_and_altercation',
}

export const IncidentTypesEnum = Object.freeze({
  INJURY: 'injury',
  ALTERCATION: 'altercation',
  INJURY_AND_ALTERCATION: 'injury_and_altercation',
});

export const IncidentTypeLabels = Object.freeze({
  [IncidentTypesEnum.INJURY]: 'Injury',
  [IncidentTypesEnum.ALTERCATION]: 'Altercation',
  [IncidentTypesEnum.INJURY_AND_ALTERCATION]: 'Injury & Altercation',
});

export enum ResolutionStatus {
  New = 'new',
  InProgress = 'in_progress',
  Resolved = 'resolved',
}

export const ResolutionStatusEnum = Object.freeze({
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  RESOLVED: 'resolved',
});

export const ResolutionStatusLabels = Object.freeze({
  [ResolutionStatusEnum.NEW]: 'New',
  [ResolutionStatusEnum.IN_PROGRESS]: 'In Progress',
  [ResolutionStatusEnum.RESOLVED]: 'Resolved',
});

export const AltercationTypesEnum = Object.freeze({
  VERBAL_ALTERCATION: 'Verbal altercation',
  VERBAL_HARASSMENT: 'Verbal harassment',
  THREAT_OF_VIOLENCE: 'Threat of violence',
  PHYSICAL_ALTERCATION: 'Physical altercation',
});

export const InjuryCausesEnum = Object.freeze({
  ALTERCATION: 'Altercation',
  COLLISION: 'Collision',
  STRUCK_BY_OBJECT: 'Struck by object',
  TRIP_OR_FALL: 'Trip or fall',
  OTHER: 'Other',
});

export const InjuryDispositionsEnum = Object.freeze({
  ON_FIELD_MEDICAL: 'On-field medical attention',
  DROVE_TO_HOSPITAL: 'Hospital/Doctor in personal vehicle',
  AMBULANCE: 'Ambulance',
  REPORT_ONLY: 'Report only, no medical attention',
  REFUSAL_OF_CARE: 'Refusal of care',
  POLICE: 'Police',
  PATIENT_REQUESTS_EMS: 'Patient Requests EMS',
  OTHER: 'Other',
});

export const InjuryTypesEnum = Object.freeze({
  ABRASION: 'Abrasion',
  ALLERGY: 'Allergy',
  CONCUSSION: 'Concussion',
  DISLOCATION: 'Dislocation',
  FRACTURE: 'Fracture',
  HEAT_EXHAUSTION: 'Heat Exhaustion',
  HYPEREXTENSION: 'Hyperextension',
  LACERATION: 'Laceration',
  LOSS_OF_CONSCIOUSNESS: 'Loss of consciousness',
  OVEREXTENSION: 'Overextension',
  NAUSEA: 'Nausea',
  SEIZURE: 'Seizure',
  STRAIN_OR_SPRAIN: 'Strain or Sprain',
  OTHER: 'Other',
});

export const BodyPartsEnum = Object.freeze({
  ARM: 'Arm',
  BACK: 'Back',
  ELBOW: 'Elbow',
  EYE: 'Eye',
  FACE: 'Face',
  FINGER: 'Finger',
  FOOT: 'Foot ',
  HAND: 'Hand',
  HEAD: 'Head',
  KNEE: 'Knee',
  HIP: 'Hip',
  LEG: 'Leg',
  MOUTH: 'Mouth',
  NECK: 'Neck',
  NOSE: 'Nose',
  SHOULDER: 'Shoulder',
  TORSO: 'Torso',
  WRIST: 'Wrist',
  OTHER: 'Other',
});

export const MedicalServicesEnum = Object.freeze({
  ANTACID: 'Antacid',
  ASPIRIN: 'Aspirin',
  ASPIRIN_SUBSTITUTE: 'Aspirin Substitute',
  BANDAID: 'Band-aid',
  BANDAGED: 'Bandaged',
  CLEANSED: 'Cleansed',
  ICE_PACK: 'Cold/ice pack',
  CPR: 'CPR',
  EPIPEN: 'Epipen',
  GLUCOSE: 'Glucose',
  OTHER_MATERIAL: 'Material not in kit',
  OINTMENT: 'Ointment/antiseptic',
  SPLINTED: 'Splinted',
  WRAPPED: 'Wrapped',
  NONE: 'None',
  OTHER: 'Other',
});

// Max upload size in multer config is 50mb
export const MAX_UPLOAD_SIZE = 52428800;
