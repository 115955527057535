import classNames from 'classnames/bind';
import * as _ from 'lodash-es';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles.module.scss';
import { ColorIndicator } from '../../../../components';
import note from '../../../../images/icons/blue/note.svg';
import blackCup from '../../../../images/icons/cup.svg';
import dateImg from '../../../../images/icons/date.svg';
import cup from '../../../../images/icons/main/cup.svg';
import { GameProps, TeamProps } from '../../../../types';

const cx = classNames.bind(styles);

const TournamentRound = props => {
  const { round, updateGame, editScore, edit, timezone, teams, setEditDate } = props;

  const { games, name } = round;
  if (!games) return <span />;

  const byDay = _.groupBy(games, ({ date }) =>
    date ? moment.utc(+date).format('ddd M/D/YY') : 'TBD'
  );

  return (
    <div className={cx('tournament-round', { edit })}>
      <h2>{name}</h2>
      {Object.keys(byDay).map(key => (
        <div key={key} className={cx('tournament-day')}>
          {edit && (
            <div className={cx('target-actions')}>
              <img
                src={dateImg}
                onClick={() =>
                  setEditDate({
                    date: moment(key, 'dddd M/D').toISOString(),
                    tournament_round: byDay[key][0]?.tournament_round,
                  })
                }
                alt="day-icon"
              />
            </div>
          )}
          <h3 className={cx('game-date')}>{key}</h3>
          {byDay[key]
            .sort((a, b) => a.start_time - b.start_time)
            .map(game => (
              <TournamentItem
                key={game._id}
                teams={teams.filter(({ _id }) => (game.teamIds || []).includes(_id))}
                {...{
                  updateGame,
                  editScore,
                  edit,
                  timezone,
                  game,
                }}
              />
            ))}
        </div>
      ))}
    </div>
  );
};

TournamentRound.propTypes = {
  edit: PropTypes.bool,
  updateGame: PropTypes.func.isRequired,
  editScore: PropTypes.func.isRequired,
  setEditDate: PropTypes.func.isRequired,
  round: PropTypes.shape({
    games: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
  }).isRequired,
  teams: PropTypes.arrayOf(TeamProps).isRequired,
  timezone: PropTypes.string.isRequired,
};

TournamentRound.defaultProps = {
  edit: false,
};

const TournamentItem = ({ game, updateGame, editScore, edit, timezone, teams }) => {
  const { scores, _id, start_time, end_time, location, field_name } = game;

  const hasScore = !_.isEmpty(scores);

  const startTime = start_time ? moment.tz(start_time, timezone).format('h:mma z') : '--:--';
  const endTime = end_time ? moment.tz(end_time, timezone).format('h:mma z') : '--:--';

  return (
    <div
      className={cx('tournament-item')}
      onClick={() => edit && updateGame(game)}
      role="button"
      tabIndex={0}
    >
      <p className={cx('start-time')}>
        {startTime}
        {!edit && ` - ${endTime}`}
      </p>
      <span>
        {teams.map(team => {
          const { _id: teamId, name } = team;
          const { score: s = 0, forfeit = false } =
            scores.find(({ team_id }) => team_id === teamId) || {};
          const score = String(+s);
          return (
            <p className={cx('team-placeholder', { forfeit })} key={teamId}>
              <ColorIndicator
                {...team}
                style={{ height: '8px', width: '8px', marginRight: '2px' }}
              />
              <span className={cx('team-name-score')}>
                {forfeit ? <s>{name}</s> : <span>{name}</span>}
                {hasScore && <b> ({score || '?'})</b>}
              </span>
            </p>
          );
        })}
        {teams.length === 1 && (
          <p className={cx('team-placeholder')} key="blank_team">
            <span>TBD</span>
          </p>
        )}
        {_.isEmpty(teams) && (
          <React.Fragment>
            <p className={cx('team-placeholder')} key="blank_team1">
              <span>TBD</span>
            </p>
            <p className={cx('team-placeholder')} key="blank_team2">
              <span>TBD</span>
            </p>
          </React.Fragment>
        )}
      </span>
      {location?.name && <p>{location?.name}</p>}
      {field_name && <p className={cx('sub-location')}>{field_name}</p>}
      {edit ? (
        <img
          className={cx('edit-game')}
          src={note}
          onClick={() => updateGame(_id)}
          alt="Edit Game"
        />
      ) : (
        <img
          className={cx('edit-score-trigger')}
          src={hasScore ? cup : blackCup}
          onClick={() => editScore(game)}
          alt="Edit score"
        />
      )}
    </div>
  );
};

TournamentItem.propTypes = {
  edit: PropTypes.bool,
  updateGame: PropTypes.func.isRequired,
  editScore: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(TeamProps).isRequired,
  timezone: PropTypes.string.isRequired,
  game: GameProps.isRequired,
};

TournamentItem.defaultProps = {
  edit: false,
};

export default TournamentRound;
